import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Image,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import Add from "../forms/Add.js";
import { AppContext } from "../../AppContext";
import * as ImagePicker from "expo-image-picker";
import { SHARE_BASE_URL } from "../docs/config.js";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { Alert, Platform } from "react-native";
import { ToastContext } from "../../ToastContext";
import { Share } from "react-native";
import { Video } from 'expo-av';


const translations = {
  ESP: {
    categoria: "Categoría",
    titulo: "Título",
    descripcion: "Descripción",
    palabraClave: "Palabra clave",
    precio: "Precio",
    modificar: "Modificar",
    errorTitulo: "El título es obligatorio.",
    errorDescripcion: "La descripción es obligatoria.",
    errorPalabraClave: "Las palabras clave son obligatorias.",
    permisosRequeridos:
      "Se requieren permisos para acceder a la cámara o la galería.",
    subirFoto: "Subir foto",
    camara: "Cámara",
    galeria: "Galería",
    cancelar: "Cancelar",
    venta: "Venta",
    alquiler: "Alquiler",
    prestamo: "Préstamo",
    regalo: "Regalo",
    modificaOferta: "Modifica tu oferta",
    modificaDemanda: "Modifica tu demanda",
    miraEstaOferta: "Hola!, Te comparto mi publicación",
    compartir: "Comparte!",
    errorCompartir: "Error, no se pudo compartir. Inténtalo de nuevo.",
    tituloCompartir: "Compartir",
    pausarTexto: "Para ",
    pausarNegrita: "pausar",
    eliminarNegrita: "eliminar",
    pausarFinal: ", desliza a la izquierda sobre la publicación de la oferta / demanda",
    pausaelimina: "Pausar/Eliminar",
  },
  CAT: {
    categoria: "Categoria",
    titulo: "Títol",
    descripcion: "Descripció",
    palabraClave: "Paraula clau",
    precio: "Preu",
    modificar: "Modifica",
    errorTitulo: "El títol és obligatori.",
    errorDescripcion: "La descripció és obligatòria.",
    errorPalabraClave: "Les paraules clau són obligatòries.",
    permisosRequeridos:
      "Es requereixen permisos per accedir a la càmera o la galeria.",
    subirFoto: "Puja foto",
    camara: "Càmera",
    galeria: "Galeria",
    cancelar: "Cancel·lar",
    venta: "Venda",
    alquiler: "Lloguer",
    prestamo: "Préstec",
    regalo: "Regal",
    modificaOferta: "Modifica la teva oferta",
    modificaDemanda: "Modifica la teva demanda",
    miraEstaOferta: "Hola!, Et comparteixo la meva publicació",
    compartir: "Comparteix!",
    errorCompartir: "Error, no s'ha pogut compartir. Torna-ho a provar.",
    tituloCompartir: "Compartir",
    pausarTexto: "Per ",
    pausarNegrita: "pausar",
    eliminarNegrita: "eliminar",
    pausarFinal: " llisca cap a l'esquerra sobre la publicació",
    pausaelimina: "Pausar/Eliminar",
  },
  ENG: {
    categoria: "Category",
    titulo: "Title",
    descripcion: "Description",
    palabraClave: "Keyword",
    precio: "Price",
    modificar: "Modify",
    errorTitulo: "Title is mandatory.",
    errorDescripcion: "Description is mandatory.",
    errorPalabraClave: "Keywords are mandatory.",
    permisosRequeridos:
      "Permissions are required to access the camera or gallery.",
    subirFoto: "Upload photo",
    camara: "Camera",
    galeria: "Gallery",
    cancelar: "Cancel",
    venta: "Sale",
    alquiler: "Rent",
    prestamo: "Loan",
    regalo: "Gift",
    modificaOferta: "Modify your offer",
    modificaDemanda: "Modify your demand",
    miraEstaOferta: "Hi!, I share my post with you",
    compartir: "Share!",
    errorCompartir: "Error, could not share. Try again.",
    tituloCompartir: "Share",
    pausarTexto: "To ",
    pausarNegrita: "pause",
    eliminarNegrita: "delete it",
    pausarFinal: ", swipe left 👇",
    pausaelimina: "Pause/Delete",
  },
};

const ExpandingTextInput = ({ style, ...props }) => {
  const [height, setHeight] = useState(0);

  return (
    <TextInput
      {...props}
      multiline
      onContentSizeChange={(e) => {
        setHeight(Math.max(35, e.nativeEvent.contentSize.height));
      }}
      style={[style, { height }]}
    />
  );
};

const Picker = ({ options, onValueChange, selectedValue, language }) => {
  // Convertir el valor seleccionado (por ejemplo, "V") a la clave correspondiente
  const keys = Object.keys(options);
  const initialSelectedKey =
    keys.find((key) => key === selectedValue) || keys[0];
  const [selected, setSelected] = useState(initialSelectedKey);

  return (
    <View style={pickerStyles.container}>
      {Object.entries(options).map(([key, value]) => (
        <TouchableOpacity
          key={key}
          style={[
            pickerStyles.option,
            selected === key && pickerStyles.selectedOption,
          ]}
          onPress={() => {
            setSelected(key);
            onValueChange(key);
          }}
        >
          <Text
            style={
              selected === key
                ? pickerStyles.selectedText
                : pickerStyles.optionText
            }
          >
            {value}
          </Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};
const EditModal = ({
  selectedItem,
  closeModal,
  userData,
  updateUser,
  updateOfferOrDemandList,
}) => {
  const { id, aplica_OD } = selectedItem;
  const { serverAddress, token, miID } = useContext(AppContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const { showActionError } = useContext(ToastContext);
  const [tituloError, setTituloError] = useState(false);
  const [descripcionError, setDescripcionError] = useState(false);
  const [palabrasClaveError, setPalabrasClaveError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isObjeto = selectedItem.esencia === "O";
  const { language } = useContext(AppContext);
  const [showImage, setShowImage] = useState(false);
  const toggleImageVisibility = () => {
    setShowImage(!showImage);
  };

  const options = {
    V: translations[language].venta,
    L: translations[language].alquiler,
    P: translations[language].prestamo,
    G: translations[language].regalo,
  };

  const ofertaOdemanda =
    aplica_OD === "O"
      ? userData.data[0].mevesOfertes.find((offer) => offer.id_Oferta === id)
      : userData.data[0].mevesDemandes.find(
          (demand) => demand.id_demanda === id
        );

  const mapTipoTransacToPickerOption = {
    V: "venta",
    L: "alquiler",
    P: "prestamo",
    G: "regalo",
  };

  const mapPickerOptionToTipoTransac = {
    Venta: "V",
    Alquiler: "L",
    Préstamo: "P",
    Regalo: "G",
  };

  const requestPermissions = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        alert("Se requieren permisos para acceder a la cámara o la galería.");
      }
    }
  };

  const resizeAndCompressImage = async (uri) => {
    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 500 } }],
      {
        compress: 0.5,
        format: SaveFormat.JPEG,
      }
    );
    return manipResult.uri;
  };

  const handleImageResult = async (result) => {
    if (!result.canceled && result.assets) {
      const resizedImageUri = await resizeAndCompressImage(
        result.assets[0].uri
      );
      setSelectedImage({ uri: resizedImageUri });

      const imageData = await fetch(resizedImageUri);
      const blob = await imageData.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => setBase64Image(reader.result.split(",")[1]); // Guardamos la imagen en base64 en el estado
    }
  };

  const showImagePickerOptions = () => {
    if (Platform.OS === "web") {
      pickImage();
      // Añade aquí la función webImagePicker si quieres soporte para la web
    } else {
      Alert.alert("Subir foto", "Elige una opción", [
        { text: "Cámara", onPress: takePhoto },
        { text: "Galería", onPress: pickImage },
        { text: "Cancelar", style: "cancel" },
      ]);
    }
  };

  const takePhoto = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const pickImage = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const initialPickerOption = ofertaOdemanda
    ? ofertaOdemanda.tipoTransac || "V"
    : "V";
  const [pickerOption, setPickerOption] = useState(initialPickerOption);

  const [titulo, setTitulo] = useState(ofertaOdemanda?.titul || "");
  const [descripcion, setDescripcion] = useState(
    ofertaOdemanda?.descripcio || ""
  );
  const [palabrasClave, setPalabrasClave] = useState(
    ofertaOdemanda?.paraulesClau || ""
  );
  const [precioHora, setPrecioHora] = useState(ofertaOdemanda?.preu || "");

  // Obtener si es oferta o demanda
  const getOfferOrDemandText = () => {
    if (ofertaOdemanda) {
      if (ofertaOdemanda.id_demanda) {
        return translations[language].modificaDemanda;
      } else if (ofertaOdemanda.id_Oferta) {
        return translations[language].modificaOferta;
      }
    }
    return ""; // En caso de que no sea ni oferta ni demanda
  };

  const getCategoryDescription = () => {
    if (ofertaOdemanda) {
      if (ofertaOdemanda.categDesc && ofertaOdemanda.subcatDesc) {
        return `${ofertaOdemanda.categDesc}/${ofertaOdemanda.subcatDesc}`;
      } else if (ofertaOdemanda.subcatDesc) {
        return ofertaOdemanda.subcatDesc;
      }
    }
    return "Ninguna seleccionada";
  };

  const valuesMap = {
    titulo: titulo,
    descripcion: descripcion,
    palabrasClave: palabrasClave,
    preu: precioHora,
  };

  const validateFields = () => {
    let isValid = true;

    // Validación de titulo
    if (titulo.trim() === "") {
      setTituloError(true);
      isValid = false;
    } else {
      setTituloError(false);
    }

    // Validación de descripcion
    if (descripcion.trim() === "") {
      setDescripcionError(true);
      isValid = false;
    } else {
      setDescripcionError(false);
    }

    // Validación de palabrasClave
    if (palabrasClave.trim() === "") {
      setPalabrasClaveError(true);
      isValid = false;
    } else {
      setPalabrasClaveError(false);
    }

    return isValid;
  };

  const handleShare = async () => {
    // Verificar que tenemos todos los datos necesarios para compartir
    if (
      !ofertaOdemanda ||
      !ofertaOdemanda.titul ||
      !ofertaOdemanda.descripcio
    ) {
      Alert.alert(translations[language].errorCompartir);
      return;
    }

    // Usar la misma base de URL que en CardOfertas
    const urlToShare = `${SHARE_BASE_URL}/perfil/${miID}`;
    const shareMessage = `${translations[language].miraEstaOferta}\n${ofertaOdemanda.titul}\n${ofertaOdemanda.descripcio}\n${urlToShare}`;

    try {
      const result = await Share.share({
        message: shareMessage,
        title: "Compartir",
      });

      // Opcional: manejo del resultado del intento de compartir
      //console.log("Compartido con éxito:", result);
    } catch (error) {
      Alert.alert(translations[language].errorCompartir);
    }
  };

  const handleModifyButton = () => {
    if (isSubmitting) return; // Si ya se está enviando, no hacer nada

    if (validateFields()) {
      setIsSubmitting(true); // Indicar que la solicitud está en curso
      let palabrasClaveProcesadas = palabrasClave
        .split(",")
        .map((keyword) => {
          keyword = keyword.trim();
          if (!/^(\".*\"|\'.*\')$/.test(keyword)) {
            if (keyword.includes(" ")) {
              return `"${capitalizeFirstLetter(keyword)}"`;
            } else {
              return capitalizeFirstLetter(keyword);
            }
          }
          return capitalizeFirstLetter(keyword);
        })
        .join(", ");

      if (palabrasClaveProcesadas.endsWith(", ")) {
        palabrasClaveProcesadas = palabrasClaveProcesadas.slice(0, -2);
      }

      sendToServer(palabrasClaveProcesadas);
    }
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const sendToServer = async (palabrasClaveProcesadas) => {
    const isOffer = selectedItem?.aplica_OD === "O";
    const tituloAjustado = capitalizeFirstLetter(titulo);
    const descripcionAjustada = capitalizeFirstLetter(descripcion);

    // Mantener el tipoTransac original para los servicios y actualizar solo para los objetos
    let tipoTransac = isObjeto
      ? mapPickerOptionToTipoTransac[pickerOption]
      : selectedItem.tipoTransac;

    // Determinar si se trata de un servicio o un objeto
    const esServicio = !isObjeto;

    // Preparar el valor del precio
    let precioFinal;
    if (esServicio) {
      precioFinal = precioHora;
    } else {
      if (pickerOption === "G" || pickerOption === "P") {
        precioFinal = "null";
      } else {
        precioFinal = precioHora;
      }
    }

    const dataToSend = {
      id_oferta: isOffer ? selectedItem.id : undefined,
      id_demanda: isOffer ? undefined : selectedItem.id,
      titul: tituloAjustado,
      descripcio: descripcionAjustada,
      paraulesClau: palabrasClaveProcesadas,
      tipoTransac: pickerOption,
      ...(base64Image ? { foto: base64Image } : {}),
      preu: precioFinal,
    };

    const endpoint = isOffer
      ? `${serverAddress}/api/v1/perfils/modioferta`
      : `${serverAddress}/api/v1/perfils/modidemanda`;

    try {
      let response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Añadir el token aquí
        },
        body: JSON.stringify(dataToSend),
      });

      let responseJson = await response.json();
      if (response.ok) {
        closeModal();
        updateOfferOrDemandList();
      } else {
        //console.error("Error enviando los datos:", responseJson);
        showActionError();
      }
    } catch (error) {
      //console.error("Error en el envío:", error);
      showActionError();
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : null}
      style={{ flex: 1 }}
    >
      <View style={styles.container}>
        {showImage && (
          <TouchableWithoutFeedback onPress={toggleImageVisibility}>
 <View style={styles.imageOverlay}>
            <Image
              source={require('../img/ajuda.gif')} // Cambia aquí a la ruta correcta del GIF
              style={styles.image}
              resizeMode="contain"
            />
          </View>
          </TouchableWithoutFeedback>
        )}
  
        <TouchableWithoutFeedback onPress={closeModal}>
          <View style={styles.backdrop} />
        </TouchableWithoutFeedback>
  
        <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
          <View style={styles.card}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={closeModal}
              hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            >
              <Image
                source={require("../img/icons/block.png")}
                style={styles.closeImage}
              />
            </TouchableOpacity>
  
            <TouchableOpacity
              style={styles.shareButton}
              onPress={() => handleShare()}
            >
              <View style={styles.shareButtonContent}>
              <Image
                  source={require("../img/icons/share2.png")}
                  style={styles.shareIcon}
                />
                <Text style={styles.shareButtonText}>
                  {translations[language].compartir}
                </Text>
              </View>
            </TouchableOpacity>
  
            <View style={styles.separator2} />
            <Text
              style={[
                styles.categoryText,
                Fonts.poppinsMedium18,
                { marginBottom: 4, marginTop: 0 },
              ]}
            >
              {getOfferOrDemandText()}
            </Text>
  
            <Text style={styles.categoryText}>
              <Text style={[styles.label, Fonts.poppinsMedium16]}>
                {translations[language].categoria}:
              </Text>
              {getCategoryDescription()}
            </Text>
  
            {isObjeto && (
              <Picker
                options={options}
                onValueChange={(value) => {
                  setPickerOption(value);
                }}
                selectedValue={pickerOption}
                language={language}
              />
            )}
            <View style={styles.separator} />
  
            {/* Inputs */}
            <View style={styles.inputWrapper}>
              <View style={styles.inputContainer}>
                <Text style={styles.fieldLabel}>
                  {translations[language].titulo}
                </Text>
                <ExpandingTextInput
                  style={styles.input}
                  maxLength={34}
                  placeholder={translations[language].titulo}
                  value={titulo}
                  onChangeText={(text) => {
                    setTitulo(text);
                    if (tituloError) setTituloError(false);
                  }}
                />
              </View>
              {tituloError && (
                <Text style={{ color: "red" }}>
                  {translations[language].errorTitulo}
                </Text>
              )}
              <View style={styles.separator} />
            </View>
  
            <View style={styles.inputWrapper}>
              <View style={styles.inputContainer}>
                <Text style={styles.fieldLabel}>
                  {translations[language].descripcion}
                </Text>
                <ExpandingTextInput
                  style={styles.input}
                  maxLength={200}
                  placeholder={translations[language].descripcion}
                  value={descripcion}
                  onChangeText={(text) => {
                    setDescripcion(text);
                    if (descripcionError) setDescripcionError(false);
                  }}
                  multiline={true}
                />
              </View>
              {descripcionError && (
                <Text style={{ color: "red" }}>
                  {translations[language].errorDescripcion}
                </Text>
              )}
              <View style={styles.separator} />
            </View>
  
            <View style={styles.inputWrapper}>
              <View style={styles.inputContainer}>
                <Text style={styles.fieldLabel}>
                  {translations[language].palabraClave}
                </Text>
                <ExpandingTextInput
                  style={styles.input}
                  maxLength={44}
                  placeholder={translations[language].palabraClave}
                  value={palabrasClave}
                  onChangeText={(text) => {
                    setPalabrasClave(text);
                    if (palabrasClaveError) setPalabrasClaveError(false);
                  }}
                />
              </View>
              {palabrasClaveError && (
                <Text style={{ color: "red" }}>
                  {translations[language].errorPalabraClave}
                </Text>
              )}
              <View style={styles.separator} />
            </View>
  
            {((isObjeto && (pickerOption === "V" || pickerOption === "L")) ||
              !isObjeto) &&
            aplica_OD !== "D" ? (
              <View style={styles.inputWrapper}>
                <View style={styles.inputContainer}>
                  <Text style={styles.fieldLabel}>
                    {translations[language].precio}
                  </Text>
                  <ExpandingTextInput
                    style={styles.input}
                    maxLength={10}
                    placeholder={translations[language].precio}
                    value={precioHora}
                    onChangeText={(text) => setPrecioHora(text)}
                  />
                </View>
                <View style={styles.separator} />
              </View>
            ) : null}
  
            {isObjeto && aplica_OD === "O" && (
              <TouchableOpacity onPress={showImagePickerOptions}>
                <Image
                  style={styles.offerImage}
                  source={
                    selectedImage
                      ? selectedImage
                      : selectedItem?.fotoOferta
                      ? {
                          uri: `${serverAddress}/fotos/${selectedItem.fotoOferta}`,
                        }
                      : require("../img/profiles/defaultcam.png")
                  }
                />
              </TouchableOpacity>
            )}
  
            <TouchableOpacity
              style={[
                styles.addButton,
                isSubmitting && { backgroundColor: Colors.grayLight },
              ]}
              onPress={handleModifyButton}
              disabled={isSubmitting}
            >
              <Text style={styles.addButtonLabel}>
                {translations[language].modificar}
              </Text>
            </TouchableOpacity>
  
            {/* Frase completa y el botón "Pausar/Eliminar" justo debajo */}
            <Text style={styles.pauseText}>
              {translations[language].pausarTexto}
              {translations[language].pausarNegrita} / {translations[language].eliminarNegrita}
              {translations[language].pausarFinal}
            </Text>
  
            <TouchableOpacity
              style={styles.pauseDeleteButton}
              onPress={toggleImageVisibility} // Mostrar/ocultar el video webp
            >
              <Text style={styles.pauseDeleteText}>
                {translations[language].pausaelimina}
              </Text>
            </TouchableOpacity>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </KeyboardAvoidingView>
  );
  
  
  
  
  
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  backdrop: {
    ...StyleSheet.absoluteFill,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  card: {
    width: 340,
    backgroundColor: "#ffffff",
    borderRadius: 16,
    shadowColor: "#000000",
    shadowOpacity: 0.25,
    shadowRadius: 6,
    shadowOffset: { width: 1, height: 1 },
    padding: 20,
  },
  categoryText: {
    fontSize: 16,
    fontWeight: "400",
    color: "#000",
  },
  label: {
    fontWeight: "400",
  },
  fieldLabel: {
    color: Colors.primary,
    marginBottom: 0,
  },
  inputContainer: {
    backgroundColor: Colors.grayLighter,
    borderRadius: 6,
    padding: 0,
    marginBottom: 0,
  },
  input: {
    fontSize: 16,
    color: Colors.grayDark,
  },
  inputWrapper: {
    marginBottom: 6,
  },
  separator: {
    height: 12,
  },
  closeButton: {
    position: "absolute",
    top: -5,
    right: -5,
    padding: 10,
    zIndex: 9999,
    backgroundColor: "transparent",
  },
  closeImage: {
    width: 30,
    height: 30,
  },
  addButton: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: "white",
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    height: 35,
    justifyContent: "center",
    paddingHorizontal: 16,
    width: 194,
    marginTop: 10,
  },
  addButtonLabel: {
    fontSize: 16,
    color: Colors.primary,
  },
  offerImage: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginBottom: 20,
    borderRadius: 10,
  },
  shareButton: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: Colors.primary,
    borderRadius: 20,
    height: 35,
    justifyContent: "center",
    paddingHorizontal: 16,
    width: "100%",
    marginTop: 10,
  },
  shareButtonContent: {
    flexDirection: "row", // Alinea los elementos horizontalmente
    alignItems: "center", // Centra los elementos verticalmente
  },
  shareIcon: {
    width: 24, // Ajusta según el tamaño deseado
    height: 24, // Ajusta según el tamaño deseado
    marginRight: 8, // Espacio entre el icono y el texto
  },
  shareButtonText: {
    color: "white",
    fontSize: 16,
  },
  pauseText: {
    fontSize: 12,
    color: Colors.grayDark,
    textAlign: "center",
    marginTop: 8,
  },
  buttonInline: {
    borderColor: Colors.primary,
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 4,
    paddingVertical: 2,
    marginHorizontal: 2,
  },
  boldButtonText: {
    fontWeight: "bold",
    color: Colors.primary,
  },
  separator2: {
    height: 1, // Altura del separador
    backgroundColor: Colors.grayMedium, // Color del separador
    marginTop: 14, // Aumentar si necesario
    marginBottom: 8, // Ajustar para más espacio hacia abajo
    width: "80%", // Ancho del separador
    alignSelf: "center", // Centrar el separador en el contenedor
  },
  imageOverlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  image: {
    width: "80%",
    height: "80%",
  },
  pauseText: {
    fontSize: 12,
    borderColor: Colors.grayMedium,
    textAlign: "center",
    marginTop: 8,
  },
  pauseDeleteButton: {
    borderColor: Colors.grayMedium, // Borde negro
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 15,
    paddingVertical: 5,
    backgroundColor: "#fff", // Fondo blanco
    alignSelf: "center", // Centrar el botón
    marginTop: 10,
  },
  pauseDeleteText: {
    color: Colors.grayDark, // Añadido el color grayMedium
    fontWeight: "bold",
    fontSize: 14,
    textAlign: 'center',
}
});

const pickerStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    height: 40,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: Colors.grayMedium,
    backgroundColor: Colors.grayLighter,
    overflow: "hidden",
    marginBottom: 5,
    marginTop: 20,
  },
  option: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderColor: Colors.grayMedium,
    borderRightWidth: 1,
  },
  selectedOption: {
    backgroundColor: Colors.primary,
  },
  optionText: {
    fontSize: 12,
    color: Colors.grayDark,
  },
  selectedText: {
    fontSize: 12,
    color: Colors.white,
  },
});

export default EditModal;
