import React, { useState, useContext, useRef, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Modal,
  Animated,
  TextInput,
  Platform,
  Alert,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import { SwipeListView } from "react-native-swipe-list-view";
import EditModal from "./EditModal";
import Pro from "./Pro.js";
import cameraIcon from "../img/icons/camera.png";
import ShareModal from "./ShareModal.js";

const translate = {
  ESP: {
    notificacion: "Notificación",
    pausar: "Pausar",
    editar: "Editar",
    eliminar: "Eliminar",
    reactivar: "Reactivar",
    vendo: "Vendo",
    compro: "Compro",
    alquilo: "Alquilo",
    presto: "Presto",
    regalo: "Regalo",
    prestado: "Prestado",
    regalado: "Regalado",
    servicio: "Servicio",
    limiteAlcanzado:
      "Has alcanzado el límite de ofertas permitidas para tu tipo de cuenta. Puedes pausar, eliminar ofertas o mejorar tu perfil a PRO.",
    tituloLimiteAlcanzado: "Límite Alcanzado",
    cerrar: "Cerrar",
    verPro: "Mirar ventajas PRO",
  },
  CAT: {
    notificacion: "Notificació",
    pausar: "Pausar",
    editar: "Editar",
    eliminar: "Eliminar",
    reactivar: "Reactivar",
    vendo: "Venc",
    compro: "Compro",
    alquilo: "Llogo",
    presto: "Deixo",
    regalo: "Regalo",
    prestado: "Prestat",
    regalado: "Regalat",
    servicio: "Servei",
    limiteAlcanzado:
      "Has assolit el límit d'ofertes permeses per al teu tipus de compte. Pots pausar, eliminar ofertes o millorar el teu perfil a PRO.",
    tituloLimiteAlcanzado: "Límit Assolit",
    cerrar: "Tancar",
    verPro: "Veure avantatges PRO",
  },
  ENG: {
    notificacion: "Notification",
    pausar: "Pause",
    editar: "Edit",
    eliminar: "Delete",
    reactivar: "Reactivate",
    vendo: "Sell",
    compro: "Buy",
    alquilo: "Rent",
    presto: "Lend",
    regalo: "Gift",
    prestado: "Lent",
    regalado: "Gifted",
    servicio: "Service",
    limiteAlcanzado:
      "You have reached the limit of offers allowed for your account type. You can pause or delete offers or upgrade your profile to PRO.",
    tituloLimiteAlcanzado: "Limit Reached",
    cerrar: "Close",
    verPro: "View PRO benefits",
  },
};

const getTransactionTypeDescription = (type, aplica_OD, language) => {
  let key = "";
  if (aplica_OD === "O") {
    switch (type) {
      case "V":
        key = "vendo";
        break;
      case "L":
        key = "alquilo";
        break;
      case "P":
        key = "presto";
        break;
      case "G":
        key = "regalo";
        break;
      default:
        key = "servicio";
    }
  } else if (aplica_OD === "D") {
    switch (type) {
      case "V":
        key = "compro";
        break;
      case "L":
        key = "alquilo";
        break;
      case "P":
        key = "prestado";
        break;
      case "G":
        key = "regalado";
        break;
      default:
        key = "servicio";
    }
  }
  // Asegurar que language siempre tiene un valor válido
  language = translate[language] ? language : "ESP";
  return translate[language][key];
};

const NewOffer = ({ type, setScrollEnabled }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isProVisible, setIsProVisible] = useState(false);
  const [isWebModalVisible, setWebModalVisible] = useState(false);
  const [isNotificationSelected, setIsNotificationSelected] = useState(false);
  const {
    serverAddress,
    token,
    userData,
    language,
    updateUser,
    animationPerformed,
    setAnimationPerformed,
  } = useContext(AppContext);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isImageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImageUri, setSelectedImageUri] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);
  // Dentro del componente NewOffer
  const animation = useRef(new Animated.Value(0)).current;
  const [offersDemandsList, setOffersDemandsList] = useState([]);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [offerData, setOfferData] = useState(null);
  const previousListLength = useRef(null);

  // Actualiza offersDemandsList cuando userData cambie

  const closeShareModal = () => {
    setIsShareModalVisible(false);
  };

  useEffect(() => {
    //console.log("Iniciando useEffect para actualizar lista de ofertas o demandas");

    const fetchOffersOrDemands = () => {
      return type === "ofrece"
        ? userData?.data[0]?.mevesOfertes?.filter(
            (offer) => offer.situacioOferta !== "B"
          ) || []
        : userData?.data[0]?.mevesDemandes?.filter(
            (demand) => demand.situacioDemanda !== "B"
          ) || [];
    };

    const newOffersOrDemandsList = fetchOffersOrDemands();
    //console.log("Previous list length:", previousListLength.current);
    //console.log("Current list length:", newOffersOrDemandsList.length);

    // Mostrar modal solo si hay una nueva oferta y es la primera carga o el modal está cerrado
    if (
      previousListLength.current !== null &&
      newOffersOrDemandsList.length > previousListLength.current
    ) {
      //console.log("Nueva oferta detectada, mostrando modal de compartir");
      setOfferData(newOffersOrDemandsList[newOffersOrDemandsList.length - 1]);
      setIsShareModalVisible(true);
    }

    // Actualizar la longitud de la lista solo después de mostrar el modal
    previousListLength.current = newOffersOrDemandsList.length;
  }, [userData, type]);

  useEffect(() => {
    if (!animationPerformed && offersDemandsList.length === 1) {
      Animated.sequence([
        Animated.timing(animation, {
          toValue: -250,
          duration: 1200,
          useNativeDriver: true,
        }),
        Animated.timing(animation, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: true,
        }),
      ]).start(() => {
        setAnimationPerformed(true); // Actualizar el estado global
      });
    }
  }, [offersDemandsList.length, animationPerformed]);

  const openPro = () => {
    setWebModalVisible(false); // Primero cerramos el WebModal
    setIsProVisible(true); // Después abrimos Pro
  };

  const closeModals = () => {
    setIsProVisible(false);
    setWebModalVisible(false);
  };
  const filteredOffers =
    userData?.data[0]?.mevesOfertes?.filter(
      (offer) => offer.situacioOferta !== "B"
    ) || [];
  const filteredDemands =
    userData?.data[0]?.mevesDemandes?.filter(
      (demand) => demand.situacioDemanda !== "B"
    ) || [];

  const openModalWithId = (id, type, aplica_OD, fotoOferta, esencia) => {
    setSelectedItem({ id, aplica_OD, fotoOferta, esencia });
    setIsModalVisible(true);
  };
  const checkOfferLimit = (isOffer) => {
    if (!isOffer) {
      // Si no es una oferta, siempre permitir la acción
      return true;
    }
    const activeOffers = userData?.data[0]?.mevesOfertes?.filter(
      (offer) => offer.situacioOferta === "A"
    ).length;
    const maxAllowedOffers = userData?.data[0]?.maxOfertes_Permesses;

    if (activeOffers >= maxAllowedOffers) {
      Platform.OS === "web" ? setWebModalVisible(true) : showLimitAlert();
      return false;
    }
    return true;
  };

  const showLimitAlert = () => {
    Alert.alert(
      translate[language].tituloLimiteAlcanzado,
      translate[language].limiteAlcanzado,
      [
        { text: translate[language].verPro, onPress: openPro },
        { text: translate[language].cerrar, onPress: closeModals },
      ]
    );
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const dataArray =
    type === "ofrece"
      ? filteredOffers.length > 0
        ? filteredOffers
        : []
      : filteredDemands.length > 0
      ? filteredDemands
      : [];
  const listViewRef = useRef(null);
  const handleDelete = (data, rowMap) => {
    if (listViewRef.current) {
      listViewRef.current.safeCloseOpenRow();
    }
    modifyOfferOrDemand(
      data.item.id_Oferta || data.item.id_demanda,
      type,
      "delete",
      rowMap
    );
  };
  const filterDeleted = (data) => {
    if (!data) return null;
    return {
      ...data,
      mevesOfertes: data.mevesOfertes?.filter(
        (offer) => offer.situacioOferta !== "B"
      ),
      mevesDemandes: data.mevesDemandes?.filter(
        (demand) => demand.situacioDemanda !== "B"
      ),
    };
  };

  const modifyOfferOrDemand = async (id, type, action, rowMap) => {
    // Cierra la fila antes de eliminar el elemento
    const isOffer = type === "ofrece";
    // Usar el indicador para llamar a checkOfferLimit
    if (action === "reactivate" && !checkOfferLimit(isOffer)) {
      // Si el límite se ha alcanzado y es una oferta, detener la ejecución
      return;
    }

    if (rowMap[id]) {
      rowMap[id].closeRow();
    }
    const actuacioType = type === "ofrece" ? "SO" : "SD";
    const novaSituacio =
      action === "delete"
        ? "B"
        : action === "pause"
        ? "P"
        : action === "reactivate"
        ? "A"
        : undefined; // Si no se cumple ninguna de las anteriores

    const payloadKey = type === "ofrece" ? "id_oferta" : "id_demanda";

    const payload = {
      actuacio: actuacioType,
      [payloadKey]: id.toString(),
      novaSituacio: novaSituacio,
    };

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (data.status === "OK") {
        if (listViewRef.current) {
          listViewRef.current.closeAllOpenRows();
        }
        const apiUrl =
          type === "ofrece"
            ? `${serverAddress}/api/v1/perfils/mevesofertes`
            : `${serverAddress}/api/v1/perfils/mevesdemandes`;

        fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === "OK") {
              const updatedData = filterDeleted({
                ...userData.data[0],
                mevesOfertes:
                  type === "ofrece" ? data.data : userData.data[0].mevesOfertes,
                mevesDemandes:
                  type !== "ofrece"
                    ? data.data
                    : userData.data[0].mevesDemandes,
              });
              updateUser({ data: [updatedData] }); // Actualizamos el contexto
            } else {
              throw new Error("Error al obtener la lista actualizada");
            }
          })
          .catch((error) => {
            //console.error("Error en la solicitud fetch:", error);
          });
      } else {
        //console.error("Error al modificar la oferta o demanda:", data);
      }
    } catch (error) {
      //console.error("Error en la solicitud fetch:", error);
    }
  };

  const updateOfferOrDemandList = () => {
    const apiUrl =
      type === "ofrece"
        ? `${serverAddress}/api/v1/perfils/mevesofertes`
        : `${serverAddress}/api/v1/perfils/mevesdemandes`;

    fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const updatedData = filterDeleted({
            ...userData.data[0],
            mevesOfertes:
              type === "ofrece" ? data.data : userData.data[0].mevesOfertes,
            mevesDemandes:
              type !== "ofrece" ? data.data : userData.data[0].mevesDemandes,
          });
          updateUser({ data: [updatedData] }); // Actualizamos el contexto

          // Cerrar todas las filas abiertas del SwipeListView
          if (listViewRef.current) {
            listViewRef.current.closeAllOpenRows();
          }
        } else {
          throw new Error("Error al obtener la lista actualizada");
        }
      })
      .catch((error) => {
        //console.error("Error en la solicitud fetch:", error);
      });
  };

  // Componente modal para la web
  const WebModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isWebModalVisible}
      onRequestClose={closeModals}

    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>
            {translate[language].tituloLimiteAlcanzado}
          </Text>
          <Text style={styles.modalMessage}>
            {translate[language].limiteAlcanzado}
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={closeModals}>
              <Text style={styles.buttonText}>
                {translate[language].cerrar}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={openPro}>
              <Text style={styles.buttonText}>
                {translate[language].verPro}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );

  const handleIconPress = () => {
    setIsNotificationSelected(!isNotificationSelected);
  };

  const defaultImageUri = "../img/profiles/default.png";

  const handleImagePress = (imageUri, item) => {
    if (imageUri === defaultImageUri) {
      // Abrir EditModal si es la imagen por defecto
      openModalWithId(
        item.id_Oferta || item.id_demanda,
        type,
        item.aplica_OD,
        item.fotoOferta,
        item.esencia
      );
    } else {
      // Mostrar imagen en modal si no es la imagen por defecto
      setSelectedImageUri(imageUri);
      setImageModalVisible(true);
    }
  };

  return (
    <>
      {isShareModalVisible && (
        <ShareModal
          isVisible={isShareModalVisible}
          onClose={closeShareModal}
          type={type} // Pasar "ofrece" o "demanda"
          offerData={offerData} // Pasar los datos de la oferta o demanda seleccionada
        />
      )}

      <Modal
        animationType="fade"
        transparent={true}
        visible={isImageModalVisible}
        onRequestClose={() => setImageModalVisible(false)}
      >
        <TouchableOpacity
          style={styles.centeredView}
          activeOpacity={1}
          onPress={() => setImageModalVisible(false)}
        >
          <Image style={styles.modalImage} source={{ uri: selectedImageUri }} />
        </TouchableOpacity>
      </Modal>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isModalVisible}
        onRequestClose={() => setIsModalVisible(false)}
      >
        <EditModal
          selectedItem={selectedItem}
          closeModal={closeModal}
          userData={userData}
          updateUser={updateUser}
          updateOfferOrDemandList={updateOfferOrDemandList} // <-- Añadir esta línea
        />
      </Modal>
      {Platform.OS === "web" && <WebModal />}
      <Modal
        animationType="slide"
        transparent={true}
        visible={isProVisible}
        onRequestClose={closeModals}
      >
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={closeModals}
          activeOpacity={1}
        >
          <Pro closeModal={closeModals} />
        </TouchableOpacity>
      </Modal>
      <SwipeListView
        ref={listViewRef}
        data={dataArray}
        keyExtractor={(item) =>
          item.id_Oferta
            ? item.id_Oferta.toString()
            : item.id_demanda
            ? item.id_demanda.toString()
            : ""
        }
        renderItem={({ item }) => {
          // Aplica el estilo dinámicamente en base a si la oferta/demanda está pausada.
          const itemStyle = {
            ...styles.rectangleParent,
            borderColor:
              (type === "ofrece"
                ? item.situacioOferta
                : item.situacioDemanda) === "P"
                ? Colors.grayLight
                : Colors.secondary,
          };

          return (
            <Animated.View style={{ transform: [{ translateX: animation }] }}>
              <TouchableOpacity
                onPress={() => {
                  if (!isSwiping) {
                    openModalWithId(
                      item.id_Oferta || item.id_demanda,
                      type,
                      item.aplica_OD,
                      item.fotoOferta,
                      item.esencia
                    );
                  }
                }}
                style={itemStyle} // Utiliza el estilo dinámico aquí
                activeOpacity={1}
              >
                <View style={styles.offerContent}>
                  <Text
                    style={[
                      styles.classesDePiano,
                      (type === "ofrece"
                        ? item.situacioOferta
                        : item.situacioDemanda) === "P"
                        ? styles.inactiveStyle
                        : {},
                    ]}
                    numberOfLines={2}
                  >
                    {item.titul}
                  </Text>
                  <Text
                    style={[
                      styles.pucDonarClasses,
                      (type === "ofrece"
                        ? item.situacioOferta
                        : item.situacioDemanda) === "P"
                        ? styles.inactiveStyle
                        : {},
                    ]}
                    numberOfLines={3}
                  >
                    {item.descripcio}
                  </Text>
                </View>
                <Text
                  style={[
                    styles.transactionType,
                    (type === "ofrece"
                      ? item.situacioOferta
                      : item.situacioDemanda) === "P"
                      ? styles.inactiveStyle
                      : {},
                    {
                      top: 10,
                      left: 258,
                      position: "absolute",
                      textAlign: "right",
                      width: 70,
                    },
                  ]}
                  numberOfLines={1}
                >
                  {item.tipoTransac
                    ? getTransactionTypeDescription(
                        item.tipoTransac,
                        item.aplica_OD,
                        language
                      )
                    : ""}
                </Text>
                <Text
                  style={[
                    styles.hora,
                    (type === "ofrece"
                      ? item.situacioOferta
                      : item.situacioDemanda) === "P"
                      ? styles.inactiveStyle
                      : {},
                    {
                      top: 30,
                      left: 258,
                      position: "absolute",
                      textAlign: "right",
                      width: 70,
                    },
                  ]}
                  numberOfLines={1}
                >
                  {item.preu}
                </Text>
                {type === "ofrece" &&
                  getTransactionTypeDescription(
                    item.tipoTransac,
                    item.aplica_OD,
                    language
                  ) !== translate[language]["servicio"] &&
                  (item.fotoOferta ? (
                    <TouchableOpacity
                      onPress={() =>
                        handleImagePress(
                          `${serverAddress}/fotos/${item.fotoOferta}`,
                          item
                        )
                      }
                      style={styles.imageWrapper}
                    >
                      <Image
                        style={[
                          styles.offerImage,
                          item.situacioOferta === "P"
                            ? styles.inactiveStyle
                            : {},
                        ]}
                        source={{
                          uri: `${serverAddress}/fotos/${item.fotoOferta}`,
                        }}
                      />
                    </TouchableOpacity>
                  ) : (
                    <Image
                      style={[
                        styles.imageContainer,
                        item.situacioOferta === "P" ? styles.inactiveStyle : {},
                      ]}
                      source={require("../img/profiles/defaultcam.png")}
                    />
                  ))}
              </TouchableOpacity>
            </Animated.View>
          );
        }}
        renderHiddenItem={(data, rowMap) => (
          <View style={styles.actionContainer}>
            <TouchableOpacity
              style={[
                styles.actionButton,
                {
                  backgroundColor:
                    (type === "ofrece"
                      ? data.item.situacioOferta
                      : data.item.situacioDemanda) === "P"
                      ? "#5C83C2"
                      : "#e9c46a",
                  borderRadius: 10,
                },
              ]}
              onPress={() => {
                if (
                  (type === "ofrece"
                    ? data.item.situacioOferta
                    : data.item.situacioDemanda) === "P"
                ) {
                  // Verificar límite de ofertas antes de reactivar
                  if (checkOfferLimit()) {
                    modifyOfferOrDemand(
                      data.item.id_Oferta || data.item.id_demanda,
                      type,
                      "reactivate",
                      rowMap
                    );
                  }
                } else {
                  modifyOfferOrDemand(
                    data.item.id_Oferta || data.item.id_demanda,
                    type,
                    "pause",
                    rowMap
                  );
                }
              }}
            >
              <Text style={styles.actionText}>
                {(type === "ofrece"
                  ? data.item.situacioOferta
                  : data.item.situacioDemanda) === "P"
                  ? translate[language]["reactivar"]
                  : translate[language]["pausar"]}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={[
                styles.actionButton,
                { backgroundColor: "#61B04F", borderRadius: 10 },
              ]}
              onPress={() =>
                openModalWithId(
                  data.item.id_Oferta || data.item.id_demanda,
                  type,
                  data.item.aplica_OD,
                  data.item.fotoOferta,
                  data.item.esencia
                )
              }
            >
              <Text style={styles.actionText}>
                {translate[language]["editar"]}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[
                styles.actionButton,
                { backgroundColor: "#D0706C", borderRadius: 10 },
              ]}
              onPress={() => handleDelete(data, rowMap)}
            >
              <Text style={styles.actionText}>
                {translate[language]["eliminar"]}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        disableRightSwipe={true}
        rightOpenValue={-250}
        onSwipeValueChange={({ value }) => {
          // Actualizar el estado de deslizamiento
          setIsSwiping(value !== 0);
          setScrollEnabled(value >= 0);
        }}
        onRowDidOpen={() => {
          setIsSwiping(true);
          setScrollEnabled(true);
        }}
        onRowDidClose={() => {
          setIsSwiping(false);
          setScrollEnabled(true);
        }}
      />
    </>
  );
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo semi-transparente
  },
  modalContent: {
    width: 340, // O el ancho que desees
    backgroundColor: "#ffffff",
    borderRadius: 16,
    padding: 20,
    shadowColor: "#000000",
    shadowOpacity: 0.25,
    shadowRadius: 6,
    shadowOffset: { width: 1, height: 1 },
  },
  rectangleParent: {
    width: 340,
    height: 115,
    marginBottom: Platform.OS === "web" ? 9 : 4,
    marginTop: 7,
    paddingVertical: 0,
    borderRadius: 0,
    flexDirection: "row",
    backgroundColor: Colors.white,
    borderTopWidth: 1.4, // Línea en la parte superior
    borderBottomWidth: 1.4, // Línea en la parte inferior
    borderColor: Colors.secondary, // Color de las líneas
  },
  frameChild: {
    flex: 1,
    borderRadius: 16,
    backgroundColor: Colors.white,
  },
  classesDePiano: {
    ...Fonts.poppinsSemiBold16,
    marginLeft: 8, // Reemplaza 'position' y 'left' por marginLeft
    width: "70%",
    marginBottom: 0, // Añade margen inferior al título
  },

  pucDonarClasses: {
    ...Fonts.poppinsRegular14,
    marginLeft: 8, // Reemplaza 'position' y 'left' por marginLeft
    width: "80%",
    height: 59,
    overflow: "hidden",
    marginTop: 0, // Añade margen superior al texto
  },
  hora: {
    ...Fonts.poppinsMedium14,
    position: "absolute",
    top: 10,
    left: 258,
    width: 70, // Ajusta este valor según tus necesidades
    textAlign: "right",
    color: Colors.primary,
  },
  notification21Icon: {
    position: "absolute",
    top: -65,
    left: 289,
    width: 25,
    height: 27,
  },
  notificacin: {
    ...Fonts.poppinsRegular10,
    position: "absolute",
    top: 87,
    left: 272,
  },
  inactiveStyle: {
    opacity: 0.4,
  },
  actionContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: "#EEEEEE",
    backgroundColor: Colors.white,
    width: "73%",
    position: "absolute",
    right: 0,
    height: "100%",
    bottom: 3,
    marginRight: 3,
  },
  actionButton: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: 83,
    height: 105,
    marginRight: 0,
  },
  actionText: {
    padding: 10,
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
  },

  offerContent: {
    flex: 1, // Esto permitirá que el contenido ocupe todo el espacio disponible, dejando espacio solo para la imagen
    padding: 10, // Añade algo de padding alrededor del contenido
    justifyContent: "space-between",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.7)", // fondo semi-transparente
  },
  modalImage: {
    width: "80%",
    height: "50%",
    resizeMode: "contain",
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%", // Ancho del modal
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary, // Color del título
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark, // Color del mensaje
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary, // Color de fondo de los botones
    marginHorizontal: 5,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  imageContainer: {
    position: "absolute",
    bottom: 5,
    right: 5,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  imageWrapper: {
    position: "absolute",
    bottom: 5,
    right: 5,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
  },
  offerImage: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
  // cameraIcon: {
  //   position: "absolute",
  //   width: 30,
  //   height: 30,
  //   resizeMode: "contain",
  // },
  // offerImage: {
  //   position: "absolute",
  //   bottom: 10, // Ajusta según lo necesites
  //   right: 10, // Ajusta según lo necesites
  //   width: 40, // Ajusta según lo necesites
  //   height: 40, // Ajusta según lo necesites
  //   borderRadius: 8, // Si quieres que la imagen sea circular
  // },
});

export default NewOffer;
