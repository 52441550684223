import React, { useContext, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Colors, Fonts } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";
import NeedRegister from "../selectables/NeedRegister.js";

const translations = {
  ESP: {
    venta: "Venta",
    alquiler: "Alquiler",
    prestamo: "Préstamo",
    regalo: "Regalo",
    compra: "Compra",
    prestado: "Prestado",
    regalado: "Regalado",
    servicio: "Servicio",
    noDisponible: "Pactar",
  },
  CAT: {
    venta: "Venda",
    alquiler: "Lloguer",
    prestamo: "Préstec",
    regalo: "Regal",
    compra: "Compra",
    prestado: "Prestat",
    regalado: "Regalat",
    servicio: "Servei",
    noDisponible: "Pactar",
  },
  ENG: {
    venta: "Sale",
    alquiler: "Rent",
    prestamo: "Loan",
    regalo: "Gift",
    compra: "Purchase",
    prestado: "Borrowed",
    regalado: "Given away",
    servicio: "Service",
    noDisponible: "Negotiate",
  },
};

const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const capitalizeLastName = (lastName) => {
  if (!lastName) return "";
  return lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
};

const CardSHorizontal = ({ data }) => {
  const navigation = useNavigation();
  const { serverAddress, userData, language } = useContext(AppContext);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const goToProfileOthers = () => {
    navigation.navigate("ProfileOther", { userId: data.id_usuari });
  };

  const isDefaultImage = !data.fotoOferta && !data.Foto;

  const isOfferOrDemand = data.id_Oferta !== undefined || data.id_demanda !== undefined;

  let imageUrl;
  if (isOfferOrDemand) {
    if (data.fotoOferta) {
      imageUrl = { uri: `${serverAddress}/fotos/${data.fotoOferta}` };
    } else if (data.Foto) {
      imageUrl = { uri: `${serverAddress}/fotos/${data.Foto}` };
    } else {
      imageUrl =
        profileImages[data.id_usuari % 10] ||
        require("../img/profiles/default.png");
    }
  } else {
    if (data.Foto) {
      imageUrl = { uri: `${serverAddress}/fotos/${data.Foto}` };
    } else {
      imageUrl =
        profileImages[data.id_usuari % 10] ||
        require("../img/profiles/default.png");
    }
  }

  const title = isOfferOrDemand ? capitalizeFirstLetter(data.titul) : `${data.nomUsuari} ${capitalizeLastName(data.cognomUsuari)}`;
  const description = isOfferOrDemand ? capitalizeFirstLetter(data.descripcio) : capitalizeFirstLetter(data.presentacio);

  const getTransactionTypeDescription = (typeTransac, aplica_OD, language) => {
    let transactionType;
    if (aplica_OD === "O") {
      switch (typeTransac) {
        case "V":
          transactionType = "venta";
          break;
        case "L":
          transactionType = "alquiler";
          break;
        case "P":
          transactionType = "prestamo";
          break;
        case "G":
          transactionType = "regalo";
          break;
        default:
          transactionType = "servicio";
      }
    } else if (aplica_OD === "D") {
      switch (typeTransac) {
        case "V":
          transactionType = "compra";
          break;
        case "L":
          transactionType = "alquiler";
          break;
        case "P":
          transactionType = "prestado";
          break;
        case "G":
          transactionType = "regalado";
          break;
        default:
          transactionType = "servicio";
      }
    }
    return translations[language][transactionType];
  };

  const formatPrice = (price) => {
    return isNaN(price) ? price : `${price} €`;
  };

  // Determinar los estilos de borde según el tipo de usuario
  let cardBorderStyle;
  let imageBorderStyle;
  let backgroundStyle;

  switch (data.tipusUsuari) {
    case "Premium":
      cardBorderStyle = styles.cardBorderPremium;
      imageBorderStyle = styles.imageBorderPremium;
      backgroundStyle = styles.cardBackgroundPremium;
      break;
    case "Colaborador":
      cardBorderStyle = styles.cardBorderCollaborator;
      imageBorderStyle = styles.imageBorderCollaborator;
      backgroundStyle = styles.cardBackgroundCollaborator;
      break;
    default:
      cardBorderStyle = styles.cardBorderStandard;
      imageBorderStyle = styles.imageBorderStandard;
      backgroundStyle = styles.cardBackgroundStandard;
      break;
  }

  return (
    <>
      <TouchableOpacity onPress={goToProfileOthers}>
        <View style={[styles.cardContainer, cardBorderStyle, backgroundStyle]}>
          <Image
            style={[
              styles.instanceItem,
              imageBorderStyle,
              isDefaultImage ? styles.defaultImageBackground : {},
            ]}
            resizeMode="cover"
            source={imageUrl}
          />

          {data.tipusUsuari === "Premium" && (
            <Text style={styles.proText}>PRO</Text>
          )}

          <View style={styles.textContainer}>
            <View style={styles.headerContainer}>
              <View style={styles.titleContainer}>
                <Text
                  style={[Fonts.poppinsMedium16, styles.title]}
                  numberOfLines={2}
                  ellipsizeMode="tail"
                >
                  {title}
                </Text>
              </View>
              {isOfferOrDemand && (
                <Text style={[Fonts.poppinsMedium14, styles.price]}>
                  {data.preu
                    ? formatPrice(data.preu)
                    : getTransactionTypeDescription(
                        data.tipoTransac,
                        data.aplica_OD,
                        language
                      ) || translations[language].noDisponible}
                </Text>
              )}
            </View>
            <Text
              style={[Fonts.poppinsRegular14, styles.descriptionText]}
              numberOfLines={2}
              ellipsizeMode="tail"
            >
              {description}
            </Text>
            <View style={styles.ratingRow}>
              <Image
                style={styles.ratingIcon}
                resizeMode="cover"
                source={require("../img/icons/puntuation.png")}
              />
              <Text style={Fonts.poppinsRegular12}>
                {data.valoracio && !isNaN(data.valoracio)
                  ? parseFloat(data.valoracio).toFixed(1)
                  : "-"}
              </Text>
              <View style={styles.separator} />
              <Text style={[Fonts.poppinsRegular12, styles.valoracionsText]}>
                {data.numComentaris ? data.numComentaris : "0"} valoraciones
              </Text>
              <View style={styles.separator} />
              <Text style={[Fonts.poppinsRegular12, styles.kmText]}>
                {data.distancia ? parseFloat(data.distancia).toFixed(1) : "0.0"} Km
              </Text>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      {isNeedRegisterVisible && (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "row",
    marginTop: 0,
    marginBottom: 16,
    alignItems: "center",
    width: 340,
    borderRadius: 16,
    borderWidth: 1, // Grosor del borde del card
    height: 115,
    justifyContent: "flex-start",
    padding: 16,
    alignSelf: "center",
  },
  cardBackgroundStandard: {
    backgroundColor: Colors.grayLighter,
  },
  cardBackgroundPremium: {
    backgroundColor: "rgba(255, 223, 158, 0.2)", // Fondo amarillo claro para Premium
  },
  cardBackgroundCollaborator: {
    backgroundColor: "rgba(177, 217, 244, 0.2)", // Fondo azul claro para Colaboradores
  },
  cardBorderStandard: {
    borderColor: Colors.grayLight, // Borde del card estándar
  },
  cardBorderPremium: {
    borderColor: Colors.secondary, // Borde amarillo para Premium
  },
  cardBorderCollaborator: {
    borderColor: Colors.primary, // Borde azul para Colaboradores
  },
  instanceItem: {
    borderRadius: 40,
    width: 71,
    height: 71,
    borderWidth: 2, // Grosor del borde de la foto
  },
  imageBorderStandard: {
    borderColor: Colors.grayMediumDark, // Borde estándar para la foto
  },
  imageBorderPremium: {
    borderColor: Colors.secondary, // Borde amarillo para la foto Premium
  },
  imageBorderCollaborator: {
    borderColor: Colors.primary, // Borde azul para la foto de Colaboradores
  },
  defaultImageBackground: {
    backgroundColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  textContainer: {
    marginLeft: 16,
    justifyContent: "space-around",
    flex: 1,
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  titleContainer: {
    flexShrink: 1,
    paddingRight: 10,
  },
  title: {
    marginBottom: 6,
  },
  price: {
    color: Colors.primary,
    flexShrink: 0,
  },
  descriptionText: {
    marginTop: 4,
  },
  ratingRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 12,
  },
  valoracionsText: {
    marginLeft: 5,
  },
  kmText: {
    marginLeft: 5,
  },
  separator: {
    width: 1,
    height: 12,
    backgroundColor: Colors.black,
    marginHorizontal: 5,
  },
  ratingIcon: {
    width: 16,
    height: 14,
    marginRight: 2,
    marginTop: -3,
  },
});

export default CardSHorizontal;
