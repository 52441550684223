import React, { useContext, useEffect, useState, useRef } from "react";
import {
  StyleSheet,
  Alert,
  Image,
  Platform,
  TouchableOpacity,
  View,
  Animated,
  Easing,
} from "react-native";
import * as ImagePicker from "expo-image-picker";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { AppContext } from "../../AppContext";
import { Colors } from "../styles/styles.js";
import profileImages from "../img/profileimage";
import { Dimensions } from "react-native";
import defaultProfileImg from "../img/profiles/default.png";
import cameraIcon from "../img/icons/camera2.png";

const { height } =
  Platform.OS !== "web" ? Dimensions.get("window") : { height: 0 };
const smallDevice = Platform.OS !== "web" && height < 768;

const translations = {
  ESP: {
    loading: "Cargando...",
    permissionsRequired:
      "Se requieren permisos para acceder a la cámara o la galería.",
    camera: "Cámara",
    gallery: "Galería",
    cancel: "Cancelar",
  },
  CAT: {
    loading: "Carregant...",
    permissionsRequired: "Calen permisos per accedir a la càmera o la galeria.",
    camera: "Càmera",
    gallery: "Galeria",
    cancel: "Cancel·lar",
  },
  ENG: {
    loading: "Loading...",
    permissionsRequired:
      "Permissions are required to access the camera or gallery.",
    camera: "Camera",
    gallery: "Gallery",
    cancel: "Cancel",
  },
};

const ProfileImage = ({ style }) => {
  const { userData, serverAddress, language, token, updateUser } =
    useContext(AppContext);
  const t = translations[language];
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState(null);
  const tipusUsuari = userData && userData.data[0].tipusUsuari;
  const cameraRotation = useRef(new Animated.Value(0)).current;

  // Configuración de animación
  const pulseAnimation = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    if (!selectedImage) {
      const pulseAndRotate = () => {
        Animated.sequence([
          Animated.parallel([
            // Crecimiento y giro a la derecha
            Animated.timing(pulseAnimation, {
              toValue: 1.08,
              duration: 1000,
              useNativeDriver: true,
              easing: Easing.inOut(Easing.ease),
            }),
            Animated.timing(cameraRotation, {
              toValue: 3, // 3 vueltas a la derecha
              duration: 1000,
              useNativeDriver: true,
              easing: Easing.inOut(Easing.ease),
            }),
          ]),
          Animated.parallel([
            // Decrecimiento y giro a la izquierda
            Animated.timing(pulseAnimation, {
              toValue: 1,
              duration: 1000,
              useNativeDriver: true,
              easing: Easing.inOut(Easing.ease),
            }),
            Animated.timing(cameraRotation, {
              toValue: 0, // Regreso a la posición inicial (3 vueltas a la izquierda)
              duration: 1000,
              useNativeDriver: true,
              easing: Easing.inOut(Easing.ease),
            }),
          ]),
        ]).start();
      };

      pulseAndRotate();
      const interval = setInterval(() => {
        pulseAndRotate();
      }, 10000);

      return () => clearInterval(interval);
    }
  }, [selectedImage]);

  useEffect(() => {
    if (userData && userData.data[0].foto) {
      setSelectedImage({
        uri: `${serverAddress}/fotos/${userData.data[0].foto}`,
      });
    }
  }, [userData, serverAddress]);

  const requestPermissions = async () => {
    if (Platform.OS !== "web") {
      const cameraStatus = await ImagePicker.requestCameraPermissionsAsync();
      const libraryStatus =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (
        cameraStatus.status !== "granted" ||
        libraryStatus.status !== "granted"
      ) {
        alert(t.permissionsRequired);
      }
    }
  };

  const resizeAndCompressImage = async (uri) => {
    const manipResult = await manipulateAsync(
      uri,
      [{ resize: { width: 500 } }],
      { compress: 0.5, format: SaveFormat.JPEG }
    );
    return manipResult.uri;
  };

  const handleImageResult = async (result) => {
    if (!result.canceled && result.assets) {
      const resizedImageUri = await resizeAndCompressImage(
        result.assets[0].uri
      );
      setSelectedImage({ uri: resizedImageUri });

      const imageData = await fetch(resizedImageUri);
      const blob = await imageData.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => setBase64Image(reader.result.split(",")[1]);
    }
  };

  const uploadToServer = async () => {
    if (base64Image) {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/perfils/actuacions`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ actuacio: "GF", foto: base64Image }),
          }
        );

        const responseData = await response.text();
        if (response.headers.get("content-type").includes("application/json")) {
          const data = JSON.parse(responseData);
          if (response.ok) {
            const updatedUserData = {
              ...userData,
              data: [{ ...userData.data[0], foto: data.foto }],
            };
            updateUser(updatedUserData);
          }
        }
      } catch (error) {
        //console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    if (base64Image) {
      uploadToServer();
    }
  }, [base64Image]);

  const showImagePickerOptions = () => {
    if (Platform.OS === "web") {
      pickImage();
    } else {
      Alert.alert(t.uploadPhoto, t.permissionsRequired, [
        { text: t.camera, onPress: () => takePhoto() },
        { text: t.gallery, onPress: () => pickImage() },
        { text: t.cancel, style: "cancel" },
      ]);
    }
  };

  const takePhoto = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const pickImage = async () => {
    await requestPermissions();
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });
    handleImageResult(result);
  };

  const isDefaultImage = !selectedImage && userData.foto == null;

  const getUserStyles = (tipoUsuari) => {
    switch (tipoUsuari) {
      case "Premium":
        return {
          borderStyle: styles.premiumBorder,
        };
      case "Colaborador":
        return {
          borderStyle: styles.colaboradorBorder,
        };
      default: // Standard
        return {
          borderStyle: styles.standardBorder,
        };
    }
  };

  const userStyles = getUserStyles(tipusUsuari);

  return (
    <View style={styles.imageContainer}>
      <TouchableOpacity
        onPress={showImagePickerOptions}
        style={styles.touchableArea}
      >
        <Animated.View
          style={[
            styles.imageWrapper,
            isDefaultImage && { transform: [{ scale: pulseAnimation }] },
          ]}
        >
          <Image
            source={
              selectedImage
                ? selectedImage
                : userData && userData.data[0].id_usuari != null
                ? profileImages[userData.data[0].id_usuari % 10]
                : defaultProfileImg
            }
            style={[
              styles.profileImage,
              userStyles.borderStyle,
              style,
              isDefaultImage && styles.defaultImageBackground,
            ]}
          />
          {isDefaultImage && (
            <Animated.Image
              source={cameraIcon}
              style={[
                styles.cameraIcon,
                {
                  transform: [
                    {
                      rotate: cameraRotation.interpolate({
                        inputRange: [0, 3],
                        outputRange: ["0deg", "1080deg"], // 3 vueltas a la derecha
                      }),
                    },
                  ],
                },
              ]}
            />
          )}
        </Animated.View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    alignItems: "center",
    marginTop: 0,
  },
  touchableArea: {},
  profileImage: {
    height: smallDevice ? 80 : 80,
    width: smallDevice ? 80 : 80,
    borderRadius: 50,
    overflow: "hidden",
    backgroundColor: Colors.primary,
  },
  premiumBorder: {
    borderWidth: 2,
    borderColor: Colors.secondary,
  },
  colaboradorBorder: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  standardBorder: {
    borderWidth: 2,
    borderColor: Colors.grayMedium,
  },
  imageWrapper: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
  },
  defaultImageBackground: {
    backgroundColor: Colors.primary,
  },
  cameraIcon: {
    position: "absolute",
    width: 28,
    height: 28,
    bottom: 3,
    right: 3,
  },
});

export default ProfileImage;