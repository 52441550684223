import React, { useContext, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  Modal,
  TouchableOpacity,
  Platform,
  TouchableWithoutFeedback,
  ImageBackground,
  Alert,
  Share,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import { SHARE_BASE_URL } from "../docs/config.js";
import { useNavigation } from "@react-navigation/native";
import Pro from "./Pro.js";
import NeedRegister from "../selectables/NeedRegister.js";

const translate = {
  ESP: {
    vendo: "Vendo",
    alquilo: "Alquilo",
    presto: "Presto",
    regalo: "Regalo",
    compra: "Compra",
    alquila: "Alquila",
    prestado: "Prestado",
    regalado: "Regalado",
    servicio: "Servicio",
    preu: "Precio",
    miraEstaOferta: "Mira esta oferta que he encontrado en un perfil de Trocalia:",
    miraEstaDemanda: "Mira esta demanda que he encontrado en un perfil de Trocalia:",
    errorCompartir: "Error, no se pudo compartir. Inténtalo de nuevo.",
    tituloLimiteAlcanzado:
      "¡Ya has alcanzado tu límite de chats nuevos este mes!",
    limiteAlcanzado:
      "¡Buenas noticias! Aún puedes continuar tus conversaciones actuales y recibir mensajes. Además, el día 1 de cada mes tu límite se reiniciará y podrás abrir nuevos chats. ¿Quieres más? Actualízate a PRO y disfruta de chats ilimitados con todos los usuarios que desees, ¡sin esperas!",
    cerrar: "Cerrar",
    verPro: "Explora las ventajas de PRO",
  },
  CAT: {
    vendo: "Venc",
    alquilo: "Llogo",
    presto: "Deixo",
    regalo: "Regal",
    compra: "Compra",
    alquila: "Lloga",
    prestado: "Prestat",
    regalado: "Regalat",
    servicio: "Servei",
    preu: "Preu",
    miraEstaOferta: "Mira aquesta oferta que he trobat en un perfil de Trocalia:",
    miraEstaDemanda: "Mira aquesta demanda que he trobat en un perfil de Trocalia:",
    errorCompartir: "Error, no es va poder compartir. Torna-ho a intentar.",
    tituloLimiteAlcanzado:
      "Ja has arribat al teu límit de xats nous aquest mes!",
    limiteAlcanzado:
      "Bones notícies! Encara pots continuar les teves converses actuals i rebre missatges. A més, el dia 1 de cada mes el teu límit es reiniciarà i podràs obrir nous xats. Vols més? Actualitza't a PRO i gaudeix de xats il·limitats amb tots els usuaris que vulguis, sense esperes!",
    cerrar: "Tancar",
    verPro: "Explora les avantatges de PRO",
  },
  ENG: {
    vendo: "Sell",
    alquilo: "Rent",
    presto: "Lend",
    regalo: "Gift",
    compra: "Buy",
    alquila: "Rents",
    prestado: "Lent",
    regalado: "Gifted",
    servicio: "Service",
    preu: "Price",
    miraEstaOferta: "Check out this offer I found on a Trocalia profile:",
    miraEstaDemanda: "Check out this demand I found on a Trocalia profile:",
    errorCompartir: "Error, could not share. Please try again.",
    tituloLimiteAlcanzado:
      "You have reached your limit of new chats this month!",
    limiteAlcanzado:
      "Good news! You can still continue your current conversations and receive messages. In addition, on the 1st of each month your limit will be reset and you will be able to open new chats. Want more? Upgrade to PRO and enjoy unlimited chats with all the users you want, without waiting!",
    cerrar: "Close",
    verPro: "Explore PRO benefits",
  },
};

const getTransactionTypeDescription = (type, aplica_OD, language) => {
  let key = "";
  if (aplica_OD === "O") {
    switch (type) {
      case "V":
        key = "vendo";
        break;
      case "L":
        key = "alquilo";
        break;
      case "P":
        key = "presto";
        break;
      case "G":
        key = "regalo";
        break;
      default:
        key = "servicio";
    }
  } else if (aplica_OD === "D") {
    switch (type) {
      case "V":
        key = "compra";
        break;
      case "L":
        key = "alquila";
        break;
      case "P":
        key = "prestado";
        break;
      case "G":
        key = "regalado";
        break;
      default:
        key = "servicio";
    }
  }
  language = translate[language] ? language : "ESP";
  return translate[language][key];
};

const CardOfertas = ({ data, userId, userData }) => {
  const { serverAddress, language, token, miTipo } = useContext(AppContext);
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const navigation = useNavigation();
  const [isLimitModalVisible, setLimitModalVisible] = useState(false);
  const [isProVisible, setIsProVisible] = useState(false);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);
  const isUserGuest = miTipo === "Guest";

  const navigateToChat = () => {
    const userPhoto = userData?.foto
      ? `${serverAddress}/fotos/${userData.foto}`
      : require("../img/profiles/default.png"); // Imagen predeterminada si no hay foto

    navigation.navigate("ChatScreen", {
      otherUserId: selectedOffer?.userId,
      otherUserPhoto: userPhoto,
      otherUserName: userData?.nomUsuari ?? "",
      otherUserSurname: userData?.cognomUsuari ?? "",
      // Otros datos necesarios
    });
  };
  const handleOfferPress = (offer) => {
    const completeOffer = {
      ...offer,
      userId: userId, // Asegúrate de que esta es la propiedad correcta
      // otras propiedades si son necesarias
    };
    setSelectedOffer(completeOffer);
    setModalVisible(true);
  };

  const handleTalkPress = async () => {
    if (!selectedOffer?.userId) {
      //console.error("No userId in selectedOffer");
      return;
    }
  
    const data = {
      actuacio: "XM",
      usu_Desti: selectedOffer.userId,
    };
  
    try {
      const response = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
  
      if (responseData.status === "OK") {
        setModalVisible(false); // Cierra el modal de la oferta actual
  
        const { nousXatsAquestMes, maxXatsMes, tipususuari } = responseData.data;
  
        // Si el usuario es Premium, permite el paso sin restricciones
        if (tipususuari === "Premium") {
          navigateToChat();
          return;
        }
  
        // Si el usuario no es Premium o el tipususuari no existe, verifica la condición de los chats
        if (nousXatsAquestMes < maxXatsMes) {
          navigateToChat();
        } else {
          // Mostrar modal o alerta si se ha alcanzado el límite de chats
          if (Platform.OS === "web") {
            setLimitModalVisible(true); // Muestra el modal de límite para web
          } else {
            Alert.alert(
              translate[language].tituloLimiteAlcanzado,
              translate[language].limiteAlcanzado,
              [
                { text: translate[language].verPro, onPress: openPro },
                {
                  text: translate[language].cerrar,
                  onPress: () => {
                    /* lógica adicional si es necesaria */
                  },
                },
              ]
            );
          }
        }
      } else {
        // Manejar caso en que la respuesta no sea OK
      }
    } catch (error) {
      //console.error("Error al enviar petición:", error);
    }
  };
  

  const openPro = () => {
    setLimitModalVisible(false); // Cierra primero el modalLimit
    setIsProVisible(true); // Luego abre el modalPro
  };

  const closeProModal = () => {
    setIsProVisible(false);
  };

  const closeModal = () => {
    setLimitModalVisible(false);
  };

  const WebModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isLimitModalVisible}
      onRequestClose={() => setLimitModalVisible(false)}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>
            {translate[language].tituloLimiteAlcanzado}
          </Text>
          <Text style={styles.modalMessage}>
            {translate[language].limiteAlcanzado}
          </Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={closeModal}>
              <Text style={styles.buttonText}>
                {translate[language].cerrar}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={openPro}>
              <Text style={styles.buttonText}>
                {translate[language].verPro}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );

  const handleShare = async (offer) => {
    const urlToShare = `${SHARE_BASE_URL}/perfil/${userId}`;
    const shareMessage = `${offer.titul}\n${
      offer.aplica_OD === "D"
        ? translate[language].miraEstaDemanda
        : translate[language].miraEstaOferta
    } \n${urlToShare}`;

    if (Platform.OS === "web" && navigator.share) {
      try {
        await navigator.share({
          title: "Compartir oferta",
          text: shareMessage,
          url: urlToShare,
        });
      } catch (error) {
        Alert.alert("Error", "No se pudo compartir. Inténtalo de nuevo.");
      }
    } else {
      // Para otras plataformas, usa el método Share de React Native
      try {
        await Share.share({
          message: shareMessage,
          title: "Compartir oferta",
        });
      } catch (error) {
        Alert.alert("Error", "No se pudo compartir. Inténtalo de nuevo.");
      }
    }
  };

  return (
    <View>
      {(data || []).map((item, index) => (
        <TouchableOpacity key={index} onPress={() => handleOfferPress(item)}>
          <View style={styles.rectangleParent}>
            <View style={styles.offerContent}>
              <Text style={styles.classesDePiano} numberOfLines={2}>
                {item.titul}
              </Text>
              <Text style={styles.pucDonarClasses} numberOfLines={3}>
                {item.descripcio}
              </Text>
              <Text style={styles.hora} numberOfLines={1}>
                {item.tipoTransac
                  ? getTransactionTypeDescription(
                      item.tipoTransac,
                      item.aplica_OD,
                      language
                    )
                  : ""}
              </Text>
              <Text style={styles.price} numberOfLines={1}>
                {item.esencia === "O"
                  ? item.preu || ""
                  : item.aplica_OD !== "D" && (item.preu || "")}
              </Text>
            </View>
            {item.fotoOferta && (
              <Image
                style={styles.offerImage}
                source={{ uri: `${serverAddress}/fotos/${item.fotoOferta}` }}
              />
            )}
          </View>
        </TouchableOpacity>
      ))}
      <Modal
        animationType="fade"
        transparent={true}
        visible={isModalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.centeredView}>
            <TouchableWithoutFeedback>
              <View style={styles.modalContent}>
                {selectedOffer?.fotoOferta && (
                  <Image
                    style={styles.modalImage}
                    source={{
                      uri: `${serverAddress}/fotos/${selectedOffer.fotoOferta}`,
                    }}
                  />
                )}
                <Text style={styles.modalTitle}>{selectedOffer?.titul}</Text>
                <Text style={styles.modalDescription}>
                  {selectedOffer?.descripcio}
                </Text>
                <Text style={styles.modalType}>
                  {getTransactionTypeDescription(
                    selectedOffer?.tipoTransac,
                    selectedOffer?.aplica_OD,
                    language
                  )}
                </Text>
                <Text style={styles.modalPrice}>{selectedOffer?.preu}</Text>
                <View style={styles.iconsContainerModal}>
                  <TouchableOpacity
                    onPress={() => handleShare(selectedOffer)}
                    style={styles.iconButton}
                  >
                    <ImageBackground
                      source={require("../img/icons/share.png")}
                      resizeMode="contain"
                      style={styles.shareIcon}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      if (miTipo === "Guest") {
                        setIsNeedRegisterVisible(true); // Mostrar un modal que indica la necesidad de registrarse
                      } else {
                        handleTalkPress(); // Continuar con la lógica de chat si no es un invitado
                      }
                    }}
                    style={styles.iconButton}
                  >
                    <ImageBackground
                      source={require("../img/icons/talk.png")}
                      resizeMode="contain"
                      style={styles.talkIcon}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
      <WebModal />
      <Modal
        animationType="slide"
        transparent={true}
        visible={isProVisible}
        onRequestClose={closeProModal}
      >
        <Pro closeModal={closeProModal} userData={userData || {}} />
      </Modal>
      {isNeedRegisterVisible && (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  rectangleParent: {
    width: 340,
    height: 115,
    marginBottom: 20,
    marginTop: 12,
    borderRadius: 16,
    flexDirection: "row",
    backgroundColor: Colors.grayLighter,
    borderWidth: 1,
    borderColor: Colors.grayLight,
  },
  offerContent: {
    flex: 1,
    padding: 10,
  },
  classesDePiano: {
    ...Fonts.poppinsSemiBold16,
    marginLeft: 8,
    width: "70%",
    marginBottom: 0,
  },
  pucDonarClasses: {
    ...Fonts.poppinsRegular14,
    marginLeft: 8,
    width: "80%",
    height: 59,
    overflow: "hidden",
    marginTop: 0,
  },
  hora: {
    ...Fonts.poppinsMedium14,
    position: "absolute",
    top: 10,
    left: 258,
    width: 70,
    textAlign: "right",
  },
  price: {
    ...Fonts.poppinsMedium14,
    position: "absolute",
    top: 30,
    left: 258,
    width: 70,
    textAlign: "right",
    color: Colors.primary,
  },
  offerImage: {
    position: "absolute",
    bottom: 10,
    right: 10,
    width: 40,
    height: 40,
    borderRadius: 8,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.7)",
  },
  modalContent: {
    backgroundColor: Colors.white,
    padding: 20,
    borderRadius: 10,
    width: "90%",
    alignItems: "center",
  },
  modalTitle: {
    ...Fonts.poppinsSemiBold16,
    marginBottom: 10,
  },
  modalDescription: {
    ...Fonts.poppinsRegular14,
    marginBottom: 10,
  },
  modalType: {
    ...Fonts.poppinsMedium14,
    marginBottom: 10,
  },
  modalPrice: {
    ...Fonts.poppinsMedium14,
    marginBottom: 10,
  },
  modalImage: {
    width: "100%",
    height: 200,
    resizeMode: "contain",
    marginBottom: 10,
    ...Platform.select({
      web: {
        height: 300,
      },
    }),
  },
  iconsContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 15,
    marginTop: -40,
  },
  iconsContainerModal: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 0,
  },
  icon: {
    width: 30,
    height: 30,
    marginLeft: 10,
  },
  shareIcon: {
    width: 35,
    height: 35,
    marginLeft: 10,
    marginTop: -7,
  },
  talkIcon: {
    width: 35,
    height: 35,
    marginLeft: 24,
    marginTop: -4,
  },
  iconButton: {
    padding: 5,
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%", // Ancho del modal
  },
  modalMessage: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
    color: Colors.grayDark, // Color del mensaje
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%", // Ancho del modal
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 15,
    color: Colors.primary, // Color del título
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 22,
    marginBottom: 0,
    paddingHorizontal: 30,
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary, // Color de fondo de los botones
    marginHorizontal: 5,
  },
  buttonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
});

export default CardOfertas;
