import React, { useContext, useEffect } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import { useNavigation } from "@react-navigation/native";
import { SocketContext } from "../../SocketContext.js";

const SubscriptionOk = () => {
    const { language, token } = useContext(AppContext);
    const { isSubscriptionOkVisible, setIsSubscriptionOkVisible } = useContext(SocketContext);
    const navigation = useNavigation();
    const { nouToken } = useContext(SocketContext); // Accede al nouToken del SocketContext
  const { updateToken } = useContext(AppContext);
    const routeNames = {
        ESP: "Inicio",
        CAT: "Inici",
        ENG: "Home",
      };
  
      // Selecciona el nombre de la ruta basado en el idioma
      const routeName = routeNames[language] || "Home";
    const handleClose = () => {
        //console.log("Cerrando modal y navegando a Home");
        if (nouToken) {
            updateToken(nouToken); // Actualiza el token global con el nuevo token
          }
        setIsSubscriptionOkVisible(false);
        navigation.navigate("Home");
      };
  

      const translations = {
        ESP: {
          title: "¡Gracias por unirte a Trocalia Pro!",
          content:
            "Tu suscripción  ha sido activada con éxito. Ahora tienes acceso a funcionalidades exclusivas diseñadas para optimizar tu experiencia.",
          button: "Descubre Trocalia Pro",
        },
        CAT: {
          title: "Gràcies per unir-te a Trocalia Pro!",
          content:
            "La teva subscripció s'ha activat amb èxit. Ara tens accés a funcionalitats exclusives dissenyades per optimitzar la teva experiència. ",
          button: "Descobreix Trocalia Pro",
        },
        ENG: {
          title: "Thank You for Joining Trocalia Pro!",
          content:
            "Your subscription has been successfully activated. You now have access to exclusive functionalities designed to optimize your experience. ",
          button: "Discover Trocalia Pro",
        },
      };
      

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isSubscriptionOkVisible}
      onRequestClose={handleClose} // Aquí utilizas handleClose correctamente
    >
      <TouchableWithoutFeedback onPress={handleClose}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>
              {translations[language]?.title}
            </Text>
            <Text style={styles.modalContent}>
              {translations[language]?.content}
            </Text>
            <TouchableOpacity
              style={styles.button}
              onPress={handleClose} // Aquí también deberías usar handleClose
            >
              <Text style={styles.textStyle}>
                {translations[language]?.button}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  // Puedes reutilizar los estilos de ManageSub y adaptarlos según sea necesario
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalTitle: {
    marginBottom: 15,
    textAlign: "center",
    ...Fonts.poppinsRegular16,
    color: Colors.primary,
  },
  modalContent: {
    marginBottom: 20,
    textAlign: "center",
    ...Fonts.poppinsRegular14,
    color: Colors.darkGrey,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: Colors.primary,
  },
  textStyle: {
    color: "white",
    textAlign: "center",
    ...Fonts.poppinsRegular16,
  },
});

export default SubscriptionOk;
