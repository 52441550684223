import React, { useContext, useState, useEffect, useRef } from 'react';
import { 
  View, 
  Text, 
  StyleSheet, 
  Image, 
  TouchableOpacity, 
  Platform, 
  Alert, 
  Modal, 
  Animated, 
  Easing 
} from 'react-native';
import { Fonts, Colors } from '../styles/styles.js';
import { AppContext } from '../../AppContext'; 
import Pro from '../components/Pro.js'; // Asegúrate de que la ruta es correcta
import addImage from '../img/icons/add2.png';
import * as Haptic from 'expo-haptics';

// Objeto de traducción
const translate = {
  ESP: {
    ofrece: 'Ofrece un servicio u objeto a la comunidad (¿Qué ofreces?)',
    necesita: 'Añade una demanda de servicio u objeto (¿Qué necesitas?)',
    limiteAlcanzado: 'Has alcanzado el límite de ofertas permitidas para tu tipo de cuenta. Puedes pausar, eliminar ofertas o mejorar tu perfil a PRO.',
    tituloLimiteAlcanzado: 'Límite Alcanzado',
    cerrar: 'Cerrar',
    verPro: 'Mirar ventajas PRO',
  },
  CAT: {
    ofrece: 'Ofereix un servei o objecte a la comunitat (Què ofereixes?)',
    necesita: 'Afegeix una demanda de servei o objecte (Què necessites?)',
    limiteAlcanzado: 'Has assolit el límit d\'ofertes permeses per al teu tipus de compte. Pots pausar, eliminar ofertes o millorar el teu perfil a PRO.',
    tituloLimiteAlcanzado: 'Límit Assolit',
    cerrar: 'Tancar',
    verPro: 'Veure avantatges PRO',
  },
  ENG: {
    ofrece: 'Offer a service or object to the community (What do you offer?)',
    necesita: 'Add a need for a service or object (What do you need?)',
    limiteAlcanzado: 'You have reached the limit of offers allowed for your account type. You can pause or delete offers or upgrade your profile to PRO.',
    tituloLimiteAlcanzado: 'Limit Reached',
    cerrar: 'Close',
    verPro: 'View PRO benefits',
  }
};

// Función auxiliar para dividir el texto en principal y ejemplo
const splitText = (text) => {
  const regex = /^(.*?)\s*\((.*?)\)$/;
  const match = text.match(regex);
  if (match) {
    return { main: match[1], example: match[2] };
  }
  return { main: text, example: '' };
};

const FirstOffer = ({ onPress, selectedOption }) => {
  const { userData, language } = useContext(AppContext);
  const [isProVisible, setIsProVisible] = useState(false); // Inicializar en false
  const [isWebModalVisible, setWebModalVisible] = useState(false);

  // Animaciones
  const fadeAnim = useRef(new Animated.Value(0)).current; // Opacidad para fadeIn
  const bounceAnim = useRef(new Animated.Value(1)).current; // Escala para bounce
  const scaleAnim = useRef(new Animated.Value(1)).current; // Escala para animación periódica
  const rotateAnim = useRef(new Animated.Value(0)).current; // Rotación para icono

  useEffect(() => {
    // Animación de fadeIn para el contenedor principal
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 800,
      useNativeDriver: true,
    }).start();

    // Animación de bounce para el icono
    Animated.sequence([
      Animated.timing(bounceAnim, {
        toValue: 1.2,
        duration: 300,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
      Animated.timing(bounceAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }),
    ]).start();

    // Animación periódica para el contenedor principal y el icono
    const startPeriodicAnimations = () => {
      // Animación de escalado del contenedor
      Animated.sequence([
        Animated.timing(scaleAnim, {
          toValue: 1.05, // Ampliar ligeramente
          duration: 1800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnim, {
          toValue: 1, // Volver al tamaño original
          duration: 800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start();

      // Animación de rotación del icono
      Animated.sequence([
        Animated.timing(rotateAnim, {
          toValue: 1, // Completar una rotación (1440 grados = 4 vueltas)
          duration: 2000, // Duración de la rotación
          easing: Easing.linear,
          useNativeDriver: true,
        }),
        Animated.timing(rotateAnim, {
          toValue: 0, // Volver a la posición original
          duration: 0, // Inmediatamente
          useNativeDriver: true,
        }),
      ]).start();
    };

    // Iniciar animaciones periódicas después de 4 segundos
    const animationTimeout = setTimeout(() => {
      startPeriodicAnimations();
      // Configurar interval para repetir cada 10 segundos
      const interval = setInterval(() => {
        startPeriodicAnimations();
      }, 10000);

      // Limpiar el interval al desmontar
      return () => clearInterval(interval);
    }, 4000);

    // Limpiar animaciones al desmontar
    return () => {
      fadeAnim.stopAnimation();
      bounceAnim.stopAnimation();
      scaleAnim.stopAnimation();
      rotateAnim.stopAnimation();
      clearTimeout(animationTimeout);
    };
  }, [fadeAnim, bounceAnim, scaleAnim, rotateAnim]);

  // Interpolación para la rotación del icono
  const rotateInterpolate = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '1440deg'], // 4 vueltas completas (360deg x4)
  });

  const openPro = () => {
    setWebModalVisible(false);
    setIsProVisible(true); 
  };

  const closeModal = () => {
    setIsProVisible(false);
    setWebModalVisible(false);
  };

  const showLimitAlert = () => {
    Alert.alert(
      translate[language].tituloLimiteAlcanzado,
      translate[language].limiteAlcanzado,
      [
        { text: translate[language].verPro, onPress: openPro },
        { text: translate[language].cerrar, onPress: closeModal, style: 'cancel' }
      ],
      { cancelable: true }
    );
  };

  const checkOfferLimit = async () => {
    try {
      if (Platform.OS !== 'web') {
        // Añade la respuesta háptica al presionar
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      }
    } catch (error) {
      //console.log("Haptic feedback no está disponible o ha fallado", error);
    }
  
    if (selectedOption === 'ofrece') {
      const activeOffers = userData?.data[0]?.mevesOfertes?.filter(offer => offer.situacioOferta === "A").length;
      const maxAllowedOffers = userData?.data[0]?.maxOfertes_Permesses;
  
      if (activeOffers >= maxAllowedOffers) {
        Platform.OS === 'web' ? setWebModalVisible(true) : showLimitAlert();
      } else {
        onPress();
      }
    } else {
      onPress();
    }
  };

  // Eliminamos las animaciones personalizadas para el WebModal y ProModal
  // y alineamos la implementación con NewOffer

  const WebModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isWebModalVisible}
      onRequestClose={closeModal}
    >
      <TouchableOpacity style={styles.centeredView} onPress={closeModal} activeOpacity={1}>
        <View style={styles.modalView}>
          <Text style={styles.modalTitle}>{translate[language].tituloLimiteAlcanzado}</Text>
          <Text style={styles.modalMessage}>{translate[language].limiteAlcanzado}</Text>
          <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={closeModal}>
              <Text style={styles.buttonText}>{translate[language].cerrar}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={openPro}>
              <Text style={styles.buttonText}>{translate[language].verPro}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    </Modal>
  );
  

  const ProModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isProVisible}
      onRequestClose={closeModal}
    >
      <TouchableOpacity style={{ flex: 1 }} onPress={closeModal} activeOpacity={1}>
        <Pro closeModal={closeModal} />
      </TouchableOpacity>
    </Modal>
  );

  return (
    <>
      <Animated.View 
        style={[
          styles.frame,
          { opacity: fadeAnim, transform: [{ scale: scaleAnim }] }
        ]}
      >
        <TouchableOpacity 
          onPress={checkOfferLimit} 
          activeOpacity={0.7}
          accessible={true}
          accessibilityLabel={translate[language][selectedOption]}
        > 
          <Animated.View style={styles.div}>
            <View style={styles.textContainer}>
              <Text style={styles.textWrapper}>
                {splitText(translate[language][selectedOption]).main}
              </Text>
              {splitText(translate[language][selectedOption]).example !== '' && (
                <Text style={styles.subtitle}>
                  {splitText(translate[language][selectedOption]).example}
                </Text>
              )}
            </View>
            <Animated.Image 
              source={addImage} 
              style={[
                styles.add, 
                { 
                  transform: [
                    { scale: bounceAnim },
                    { rotate: rotateInterpolate },
                  ] 
                }
              ]}
            />
          </Animated.View>
        </TouchableOpacity>
      </Animated.View>
      
      {Platform.OS === 'web' && <WebModal />}
      <ProModal />
    </>
  );
};

const styles = StyleSheet.create({
  frame: {
    width: 340, // Ancho original
    height: 67, 
    marginBottom: 20,
    marginTop: 10,
    alignSelf: 'center',
  },
  div: {
    borderRadius: 16,
    shadowColor: Colors.primary,
    shadowOffset: {
      width: 3, 
      height: 4, 
    },
    shadowOpacity: 0.5,
    shadowRadius: 6,
    height: 67,
    width: 340, // Ancho original
    position: 'relative',
    alignItems: 'center', 
    backgroundColor: Colors.primary,
    flexDirection: 'row', // Alinear elementos en fila
    paddingHorizontal: 20,
    ...Platform.select({
      android: {
        elevation: 12, 
      },
    }),
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center', // Centrar verticalmente
    paddingRight: 50, // Espacio para el icono
  },
  textWrapper: {
    color: Colors.white,
    ...Fonts.poppinsMedium16,
    lineHeight: 20,
    fontWeight: '600',
  },
  subtitle: {
    fontSize: 12,
    lineHeight: 16,
    color: Colors.white,
    marginTop: 2,
    opacity: 0.8,
  },
  add: {
    height: 34, // Tamaño original
    width: 34,
    position: 'absolute',
    right: 15,
  },
  modalView: {
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80%', 
    maxHeight: '80%', // Asegurar que el modal no se corte
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
    color: Colors.primary, 
    textAlign: 'center',
  },
  modalMessage: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 20,
    color: Colors.grayDark, 
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary, 
    marginHorizontal: 5,
    flex: 1,
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    ...Fonts.poppinsMedium14,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  
});

export default FirstOffer;
