import React, { useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Image,
  Modal,
  TouchableWithoutFeedback,
  Platform,
  Dimensions,
} from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import { Linking } from "react-native";

const Options = ({ closeModal }) => {
  const { language } = useContext(AppContext);
  const t = {
    ESP: {
      title: "Planes de Anunciante",
      plans: [
        {
          name: "Básico",
          price: "19,99 €/mes",
          benefits: [
            "Hasta 5,000 impresiones mensuales",
            "1 categoría de anuncios",
            "Anuncios en 1 código postal",
          ],
        },
        {
          name: "Profesional",
          price: "49,99 €/mes",
          benefits: [
            "Hasta 20,000 impresiones mensuales",
            "3 categorías de anuncios",
            "Anuncios en hasta 3 códigos postales",
            "Estadísticas avanzadas",
          ],
        },
        {
          name: "Premium",
          price: "99,99 €/mes",
          benefits: [
            "Impresiones ilimitadas",
            "Categorías ilimitadas de anuncios",
            "Anuncios en códigos postales ilimitados",
            "Asesoramiento personalizado",
          ],
        },
      ],
      selectPlan: "Contáctanos",
    },
    CAT: {
      title: "Plans d'Anunciant",
      plans: [
        {
          name: "Bàsic",
          price: "19,99 €/mes",
          benefits: [
            "Fins a 5,000 impressions mensuals",
            "1 categoria d'anuncis",
            "Anuncis en 1 codi postal",
          ],
        },
        {
          name: "Professional",
          price: "49,99 €/mes",
          benefits: [
            "Fins a 20,000 impressions mensuals",
            "3 categories d'anuncis",
            "Anuncis en fins a 3 codis postals",
            "Estadístiques avançades",
          ],
        },
        {
          name: "Premium",
          price: "99,99 €/mes",
          benefits: [
            "Impressions il·limitades",
            "Categories il·limitades d'anuncis",
            "Anuncis en codis postals il·limitats",
            "Assessorament personalitzat",
          ],
        },
      ],
      selectPlan: "Contacta'ns",
    },
    ENG: {
      title: "Advertiser Plans",
      plans: [
        {
          name: "Basic",
          price: "19,99 €/month",
          benefits: [
            "Up to 5,000 monthly impressions",
            "1 ad category",
            "Ads in 1 postal code",
          ],
        },
        {
          name: "Professional",
          price: "49,99 €/month",
          benefits: [
            "Up to 20,000 monthly impressions",
            "3 ad categories",
            "Ads in up to 3 postal codes",
            "Advanced statistics",
          ],
        },
        {
          name: "Premium",
          price: "99,99 €/month",
          benefits: [
            "Unlimited impressions",
            "Unlimited ad categories",
            "Ads in unlimited postal codes",
            "Personalized advice",
          ],
        },
      ],
      selectPlan: "Contact us",
    },
  };

  // Considera el idioma actual para las traducciones
  const translations = t[language] || t.ESP; // Asumimos ESP por defecto

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
        <Image
          style={styles.closeIcon}
          source={require("../img/icons/block.png")}
        />
      </TouchableOpacity>
      <ScrollView
        style={styles.scrollViewStyle}
        contentContainerStyle={styles.scrollViewContent}
      >
        <View style={styles.container}>
          <Text style={styles.title}>{translations.title}</Text>
          {translations.plans.map((plan, index) => (
            <View
              key={index}
              style={[
                styles.planContainer,
                index === 1
                  ? styles.planHighlighted
                  : styles.planNotHighlighted,
              ]}
            >
              <Text style={styles.planName}>{plan.name}</Text>
              <Text style={styles.planPrice}>{plan.price}</Text>
              {plan.benefits.map((benefit, idx) => (
                <Text key={idx} style={styles.planBenefit}>
                  {benefit}
                </Text>
              ))}
              <TouchableOpacity
                style={styles.selectPlanButton}
                onPress={() => Linking.openURL("mailto:publicitat@trocalia.net")}
              >
                <Text style={styles.selectPlanText}>
                  {translations.selectPlan}
                </Text>
              </TouchableOpacity>
            </View>
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

const { width, height } = Dimensions.get("window");

const styles = StyleSheet.create({
  scrollViewStyle: {
    backgroundColor: Colors.white,
    width: width, // Ocupa todo el ancho de la pantalla
    height: height, // Ocupa todo el alto de la pantalla
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flexGrow: 1,
    alignItems: "center",
    paddingTop: Platform.OS === "web" ? 16 : 54,
    paddingBottom: 50, // Espacio extra para scrolling
    width: "100%", // Asegura que el contenedor use todo el ancho disponible
  },
  closeButton: {
    position: "absolute",
    right: 10,
    top: Platform.OS === "web" ? 16 : 84,
    padding: 10,
    zIndex: 99,
  },
  closeIcon: {
    width: 30,
    height: 30,
  },
  title: {
    ...Fonts.poppinsMedium18,
    color: Colors.primary,
    marginBottom: 20,
  },
  planContainer: {
    padding: 20,
    borderRadius: 10,
    marginBottom: 20,
    width: "90%", // Ajusta al diseño deseado
  },
  planHighlighted: {
    backgroundColor: Colors.lightPrimary,
    borderColor: Colors.primary,
    borderWidth: 1,
  },

  planName: {
    ...Fonts.poppinsMedium18,
    color: Colors.primary,
    marginBottom: 10,
  },
  planPrice: {
    ...Fonts.poppinsRegular16,
    marginBottom: 10,
  },
  planBenefit: {
    ...Fonts.poppinsRegular14,
    marginBottom: 5,
  },
  selectPlanButton: {
    marginTop: 10,
    backgroundColor: Colors.primary,
    padding: 10,
    borderRadius: 5,
    alignItems: "center",
  },
  selectPlanText: {
    ...Fonts.poppinsSemiBold14,
    color: Colors.white,
  },
  planNotHighlighted: {
    backgroundColor: Colors.white, // Fondo blanco para planes no destacados
    borderColor: Colors.grayLight, // Usa Colors.inactive para el borde
    borderWidth: 1,
  },
});

export default Options;
