import React, { useContext } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import * as SecureStore from "expo-secure-store";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";

const NeedRegister = ({ isVisible, onClose }) => {
  const { language, setToken, eraseUserData } = useContext(AppContext);
  const navigation = useNavigation();

  const translations = {
    ESP: "Únete a nuestra comunidad y desbloquea todas las funcionalidades.",
    CAT: "Uneix-te a la nostra comunitat i desbloqueja totes les funcionalitats.",
    ENG: "Join our community and unlock all features.",
};
  
  const buttonLabel = {
    ESP: "Registrarse",
    CAT: "Registrar-se",
    ENG: "Sign Up",
  };

  const handleRegister = async () => {
    // Limpia el contexto y el almacenamiento seguro
    setToken(null);
    eraseUserData();
    if (Platform.OS === "web") {
      localStorage.removeItem("userToken");
    } else {
      await SecureStore.deleteItemAsync("userToken");
    }
    // Cierra el modal antes de navegar
    onClose();
    // Navega a la pantalla de registro
    navigation.navigate("Register");
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{translations[language]}</Text>
            <TouchableOpacity
              style={styles.button}
              onPress={handleRegister}
            >
              <Text style={styles.textStyle}>{buttonLabel[language]}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    color: Colors.grayDark,
    ...Fonts.poppinsMedium16,
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary,
    width: '100%', // Asegúrate de que el botón se extienda todo lo posible
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    ...Fonts.poppinsRegular16,
  },
});

export default NeedRegister;
