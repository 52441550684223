import React, { useState, useRef, useEffect, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image,
  Platform,
  ScrollView,
  Animated,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import Carrousel from "./Carrousel";
import OfreceServicio from "../forms/OfereceServicio";
import { AppContext } from "../../AppContext";


const translate = {
  ESP: {
    anadirOferta: "Añadir Oferta",
    anadirNecesidad: "Añadir Demanda",
    explicaOferta: "¿Qué ofreces a la comunidad?",
    notOfers: "AQUÍ NO AÑADAS LO QUE OFRECES",
    ejemplosOferta: [
      "Ej. Ofrezco mis servicios de canguro",
      "Ej. Regalo una bicicleta",
      "Ej. Doy clases de piano gratuitas",
      "Ej. Ofrezco servicio de jardinería",
      "Ej. Doy clases de cocina",
      "Ej. Ofrezco asesoría legal gratuita",
      "Ej. Entrenamiento personal",
      "Ej. Dono ropa usada",
      "Ej. Reparo electrodomésticos",
      "Ej. Paseo mascotas",
      "Ej. Cocino a domicilio",
      "Ej. Doy clases de arte y creatividad",
      "Ej. Ofrezco clases de refuerzo escolar",
      "Ej. Dono juguetes",
      "Ej. Regalo libros usados",
    ],
    explicaDemanda: "¿Qué necesitas de la comunidad?",
    ejemplosDemanda: [
      "Ej. Busco persona para la limpieza del hogar",
      "Ej. Necesito un televisor",
      "Ej. Requiero ayuda con mudanza",
      "Ej. Necesito clases de inglés",
      "Ej. Busco asesoría contable",
      "Ej. Necesito transporte para muebles",
      "Ej. Busco compañero de entrenamiento",
      "Ej. Necesito herramientas de jardinería",
      "Ej. Busco servicios de reparación de hogar",
      "Ej. Necesito un paseador de perros",
      "Ej. Busco un cocinero para eventos",
      "Ej. Necesito clases de arte",
      "Ej. Busco tutorías para refuerzo escolar",
      "Ej. Necesito juguetes para niños",
      "Ej. Busco libros de segunda mano",
    ],
  },
  CAT: {
    anadirOferta: "Afegir Oferta",
    anadirNecesidad: "Afegir Demanda",
    explicaOferta: "Què ofereixes a la comunitat?",
    notOfers: "AQUÍ NO AFEGEIXIS EL QUE OFEREIXES",
    ejemplosOferta: [
      "Ex. Ofereixo serveis de cangur",
      "Ex. Regalo una bicicleta",
      "Ex. Classes de piano gratuïtes",
      "Ex. Servei de jardineria",
      "Ex. Classes de cuina",
      "Ex. Assessorament legal gratuït",
      "Ex. Entrenament personal",
      "Ex. Donació de roba usada",
      "Ex. Reparo electrodomèstics",
      "Ex. Passeig mascotes",
      "Ex. Cuino a domicili",
      "Ex. Classes d'art i creativitat",
      "Ex. Classes de reforç escolar",
      "Ex. Donació de joguines",
      "Ex. Regalo llibres usats",
    ],
    explicaDemanda: "Què necessites de la comunitat?",
    ejemplosDemanda: [
      "Ex. Busco persona per a la neteja de la llar",
      "Ex. Necessito un televisor",
      "Ex. Requereixo ajuda amb la mudança",
      "Ex. Necessito classes d'anglès",
      "Ex. Busco assessorament comptable",
      "Ex. Necessito transport per a mobles",
      "Ex. Busco company d'entrenament",
      "Ex. Necessito eines de jardineria",
      "Ex. Busco serveis de reparació de la llar",
      "Ex. Necessito un passejador de gossos",
      "Ex. Busco un cuiner per a esdeveniments",
      "Ex. Necessito classes d'art",
      "Ex. Busco tutories per a reforç escolar",
      "Ex. Necessito joguines per a nens",
      "Ex. Busco llibres de segona mà",
    ],
  },
  ENG: {
    anadirOferta: "Add Offer",
    anadirNecesidad: "Add Need",
    explicaOferta: "What do you offer to the community?",
    notOfers: "DO NOT ADD WHAT YOU OFFER HERE",
    ejemplosOferta: [
      "Ex. I offer babysitting services",
      "Ex. I give away a bicycle",
      "Ex. Free piano lessons",
      "Ex. Gardening service",
      "Ex. Cooking classes",
      "Ex. Free legal advice",
      "Ex. Personal training",
      "Ex. Donation of used clothes",
      "Ex. Appliance repair",
      "Ex. Pet walking services",
      "Ex. Home cooking services",
      "Ex. Art and creativity classes",
      "Ex. Tutoring for school subjects",
      "Ex. Donation of toys",
      "Ex. Giving away used books",
    ],
    explicaDemanda: "What do you need from the community?",
    ejemplosDemanda: [
      "Ex. I'm looking for someone to clean my house",
      "Ex. I need a TV",
      "Ex. Need help with moving",
      "Ex. Looking for English lessons",
      "Ex. Seeking accounting advice",
      "Ex. Need furniture transportation",
      "Ex. Looking for a workout buddy",
      "Ex. Need gardening tools",
      "Ex. Looking for home repair services",
      "Ex. Need a dog walker",
      "Ex. Looking for a cook for events",
      "Ex. Need art classes",
      "Ex. Looking for tutoring services",
      "Ex. Need toys for children",
      "Ex. Looking for second-hand books",
    ],
  },
};



const OfferModal = ({ closeModal, type }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedOption, setSelectedOption] = useState("servicios");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const { language } = useContext(AppContext);

  const scrollViewRef = useRef(null);
  const isWeb = Platform.OS === "web";

  const position = useRef(new Animated.Value(-600)).current;
  const scaleAnimation = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.timing(position, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, []);

  const [exampleIndex, setExampleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setExampleIndex((prevIndex) => (prevIndex + 1) % ejemplos.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [language, type]);

  // Animación para el texto notOfers
  useEffect(() => {
    if (type === "necesita") {
      Animated.sequence([
        Animated.timing(scaleAnimation, {
          toValue: 1.1,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnimation, {
          toValue: 1,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnimation, {
          toValue: 1.1,
          duration: 800,
          useNativeDriver: true,
        }),
        Animated.timing(scaleAnimation, {
          toValue: 1,
          duration: 800,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [type]);

  const ejemplos =
    type === "ofrece"
      ? translate[language].ejemplosOferta
      : translate[language].ejemplosDemanda;

  return (
    <View style={styles.container}>
      <View style={isWeb ? styles.backgroundWeb : styles.background}>
        <Animated.View
          style={[
            isWeb ? styles.componentWeb : styles.component,
            { bottom: position },
          ]}
        >
          <TouchableOpacity
            onPress={closeModal}
            style={styles.blockIconWrapper}
          >
            <Image
              style={styles.blockIcon}
              source={require("../img/icons/block.png")}
            />
          </TouchableOpacity>
          <ScrollView
            style={styles.div}
            ref={scrollViewRef}
            contentContainerStyle={isWeb ? { alignItems: "center" } : {}}
            scrollToOverflowEnabled={false}
          >
            <Text style={styles.titleText}>
              {type === "ofrece"
                ? translate[language].anadirOferta
                : translate[language].anadirNecesidad}
            </Text>
            <Text style={styles.descriptionText}>
              {type === "ofrece"
                ? translate[language].explicaOferta
                : translate[language].explicaDemanda}
            </Text>

            {/* Mostrar notOfers solo para demandas */}
            {type === "necesita" && (
              <Animated.Text
                style={[
                  styles.notOfersText,
                  { transform: [{ scale: scaleAnimation }] },
                ]}
              >
                {translate[language].notOfers}
              </Animated.Text>
            )}

            <Text style={styles.exampleText}>{ejemplos[exampleIndex]}</Text>
            <View style={styles.carouselContainer}>
              <Carrousel
                setSelectedCategoryIdProp={setSelectedCategory}
                setSelectedSubCategoryIdProp={setSelectedSubCategoryId}
                setSelectedOptionProp={setSelectedOption}
              />
            </View>
            <View style={styles.centeredServiceContainer}>
              <OfreceServicio
                selectedCategory={selectedCategory}
                selectedSubCategoryId={selectedSubCategoryId}
                closeModal={closeModal}
                type={type}
                selectedOption={selectedOption}
              />
            </View>
            <View style={{ height: 400 }}></View>
          </ScrollView>
        </Animated.View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    bottom: 0,
    position: "absolute",
    zIndex: 9999,
    left: 0,
    right: 0,
  },
  background: {
    flex: 1,
  },
  component: {
    backgroundColor: "#EFEFEF",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  div: {
    height: 600,
    width: Platform.OS === "web" ? "100%" : 375,
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowOpacity: 0.25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    elevation: 5,
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderColor: "#ccc",
    padding: 2,
  },
  blockIconWrapper: {
    zIndex: 9999,
    position: "absolute",
    top: 22,
    right: 22,
    width: 44,
    height: 44,
    justifyContent: "center",
    alignItems: "center",
  },
  blockIcon: {
    width: 24,
    height: 24,
  },
  titleText: {
    marginTop: 25,
    marginLeft: 25,
    fontFamily: "Poppins-Medium",
    fontSize: 20,
    lineHeight: 37,
  },
  descriptionText: {
    marginTop: 0,
    marginLeft: 25,
    fontFamily: "Poppins-Regular",
    fontSize: 14,
    lineHeight: 26,
    fontStyle: 'italic',
    color: '#808080'
  },
  exampleText: {
    marginLeft: 25,
    fontFamily: "Poppins-Regular",
    fontSize: 12,
    lineHeight: 22,
    fontStyle: 'italic',
    color: 'black'
  },
  carouselContainer: {
    marginLeft: 25,
  },
  centeredServiceContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  notOfersText: {
    marginLeft: 25,
    fontFamily: "Poppins-Regular",
    fontSize: 12, // Tamaño similar al de los ejemplos
    lineHeight: 22,
    color: "red",
    marginTop: 0,
    fontStyle: 'italic',
  },
});

export default OfferModal;