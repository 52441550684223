import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Modal,
  Alert,
  Share,
  ActivityIndicator,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";

const traducciones = {
  ESP: {
    shareTitleOffer: "¿Quieres compartir tu oferta para aumentar su visibilidad? 🚀",
    shareTitleDemand: "¿Quieres compartir tu demanda para aumentar su visibilidad? 🚀",
    shareMessageOffer: (title, description, url) =>
      `🌟 He añadido una nueva Oferta en Trocalia:\n${title}\n${description}\nPuedes verla entrando en:\n${url}`,
    shareMessageDemand: (title, description, url) =>
      `🔍 He añadido una nueva Demanda en Trocalia:\n${title}\n${description}\nPuedes verla entrando en:\n${url}`,
    shareSuccessTitle: "Compartido correctamente",
    shareSuccessOffer: "Tu oferta ha sido compartida con éxito.",
    shareSuccessDemand: "Tu demanda ha sido compartida con éxito.",
    shareErrorTitle: "Error",
    shareErrorMessage: "No se pudo compartir. Inténtalo de nuevo.",
    shareYes: "Sí, compartir",
    shareNo: "No, gracias",
  },
  CAT: {
    shareTitleOffer: "Vols compartir la teva oferta per augmentar-ne la seva visibilitat? 🚀",
    shareTitleDemand: "Vols compartir la teva demanda per augmentar-ne la seva visibilitat? 🚀",
    shareMessageOffer: (title, description, url) =>
      `🌟 He afegit una nova Oferta a Trocalia:\n${title}\n${description}\nLa pots veure accedint a:\n${url}`,
    shareMessageDemand: (title, description, url) =>
      `🔍 He afegit una nova Demanda a Trocalia:\n${title}\n${description}\nLa pots veure accedint a:\n${url}`,
    shareSuccessTitle: "Compartit correctament",
    shareSuccessOffer: "La teva oferta s'ha compartit amb èxit.",
    shareSuccessDemand: "La teva demanda s'ha compartit amb èxit.",
    shareErrorTitle: "Error",
    shareErrorMessage: "No s'ha pogut compartir. Intenta-ho de nou.",
    shareYes: "Sí, compartir",
    shareNo: "No, gràcies",
  },
  ENG: {
    shareTitleOffer: "Do you want to share your offer for more visibility? 🚀",
    shareTitleDemand: "Do you want to share your demand for more visibility? 🚀",
    shareMessageOffer: (title, description, url) =>
      `🌟 I have added a new Offer on Trocalia:\n${title}\n${description}\nYou can see it here:\n${url}`,
    shareMessageDemand: (title, description, url) =>
      `🔍 I have added a new Demand on Trocalia:\n${title}\n${description}\nYou can see it here:\n${url}`,
    shareSuccessTitle: "Shared Successfully",
    shareSuccessOffer: "Your offer has been shared successfully.",
    shareSuccessDemand: "Your demand has been shared successfully.",
    shareErrorTitle: "Error",
    shareErrorMessage: "Sharing failed. Please try again.",
    shareYes: "Yes, Share",
    shareNo: "No, Thanks",
  },
};

const ShareModal = ({ isVisible, onClose, type, offerData }) => {
    const { serverAddress, userData, language } = useContext(AppContext);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
  
    const langText = traducciones[language] || traducciones["ESP"];
    const title = offerData?.titul;
    const description = offerData?.descripcio;
    const isOffer = type === "ofrece";
  
    useEffect(() => {
      let timer;
      if (isVisible) {
        timer = setTimeout(() => setShowModal(true), 2000);
      } else {
        setShowModal(false);
      }
      return () => clearTimeout(timer);
    }, [isVisible]);
  
    const handleShare = async () => {
      setIsLoading(true);
      const miID = userData?.data[0]?.id_usuari;
  
      if (!miID) {
        Alert.alert(langText.shareErrorTitle, langText.shareErrorMessage);
        setIsLoading(false);
        return;
      }
  
      const urlToShare = `${serverAddress}/perfil/${miID}`;
      const shareMessage = isOffer
        ? langText.shareMessageOffer(title, description, urlToShare)
        : langText.shareMessageDemand(title, description, urlToShare);
  
      try {
        const result = await Share.share({
          message: shareMessage,
          title: "Compartir",
        });
  
        if (result.action === Share.sharedAction) {
          Alert.alert(
            langText.shareSuccessTitle,
            isOffer ? langText.shareSuccessOffer : langText.shareSuccessDemand
          );
        }
      } catch (error) {
        Alert.alert(langText.shareErrorTitle, langText.shareErrorMessage);
      } finally {
        setIsLoading(false);
        setShowModal(false);
        onClose();
      }
    };
  
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={showModal}
        onRequestClose={() => {
          setShowModal(false);
          onClose();
        }}
      >
        <View style={styles.modalCenteredView}>
          <View style={styles.shareModalView}>
            <Text style={styles.shareModalTitle}>
              {isOffer ? langText.shareTitleOffer : langText.shareTitleDemand}
            </Text>
            <View style={styles.shareButtonContainer}>
              <TouchableOpacity
                style={styles.shareButtonYes}
                onPress={handleShare}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ActivityIndicator color="#fff" />
                ) : (
                  <Text style={styles.shareButtonText}>{langText.shareYes}</Text>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.shareButtonNo}
                onPress={() => {
                  setShowModal(false);
                  onClose();
                }}
                disabled={isLoading}
              >
                <Text style={styles.shareButtonText}>{langText.shareNo}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  

const styles = StyleSheet.create({
  modalCenteredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  shareModalView: {
    width: 300,
    backgroundColor: Colors.white,
    borderRadius: 20,
    padding: 25,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  shareModalTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 15,
    textAlign: "center",
    color: Colors.primary,
  },
  shareButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  shareButtonYes: {
    flex: 1,
    backgroundColor: Colors.primary,
    paddingVertical: 10,
    marginHorizontal: 5,
    borderRadius: 10,
    alignItems: "center",
  },
  shareButtonNo: {
    flex: 1,
    backgroundColor: Colors.grayLight,
    paddingVertical: 10,
    marginHorizontal: 5,
    borderRadius: 10,
    alignItems: "center",
  },
  shareButtonText: {
    color: "white",
    fontWeight: "bold",
  },
});

export default ShareModal;
