import React, { useContext, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
  ScrollView,
  Alert,
  Linking,
  Animated,
  Easing,
} from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import imageMap from "../db/imageMap";
import ProfileImage from "./ProfileImage";
import { useRevenueCat } from "../../RevenueCatContext";
import * as Haptic from "expo-haptics";
// import { logError } from "../utils/logError"; // Ajusta la ruta según tu estructura

const translations = {
  ESP: {
    pro: {
      trocaliaPro: "Trocalia PRO",
      benefits: [
        "Destaca tu perfil y tus ofertas",
        "Chats ilimitados con usuarios",
        "Descubre quién visita tu perfil",
        "Ofertas ilimitadas para publicar",
        "Hazte mecenas de la comunidad",
      ],
      visibilidad: "Más visibilidad, más clientes",
      nombreanual: "Pro Anual",
      nombremes: "Pro Mensual",
      bePro: "Acceso PRO",
      beProTrial: "Pruébalo Gratis",
    },
    colab: {
      trocaliaPro: "Trocalia COLAB",
      benefits: [
        "Descubre quién visita tu perfil",
        "Colabora a mantener los costes del proyecto",
        "Destaca tu perfil en Azul",
        "Promueve la economía local y sostenible",
        "Contribuye para que Trocalia siga funcionando",
      ],
      visibilidad: "Tu ayuda mantiene la comunidad",
      nombreanual: "Colab Anual",
      nombremes: "Colab Mensual",
      colabButtonText: "Quiero Colaborar",
    },
    priceAnnualPeriod: "/año",
    priceMonthlyPeriod: "/mes",
    priceannualios: "/cada año (auto-renovable)",
    pricemonthlyios: "/cada mes (auto-renovable)",
    or: "o",
    close: "Cerrar",
    save: "Ahorra",
    popupBlockerWarning:
      "Si el proceso de pago no se abre, asegúrate de permitir ventanas emergentes en Safari. Configuración > Safari > Bloquear ventanas emergentes.",
    errorcheckout:
      "Error al iniciar el proceso de pago: verifica tu conexión a internet y vuelve a intentarlo.",
    termsAndConditions: "Términos y Condiciones",
    termsAndConditionsEULA: "Términos de uso (EULA)",
    privacyPolicy: "Política de Privacidad",
    support: "Soporte",
    yasoypro:
      "Si previamente has adquirido Trocalia PRO, puedes restaurar tus compras aquí.",
    restaurar: "Restaurar Compras",
    offeringsError: "No disponible",
    offeringsErrorMessage:
      "En este momento no se puede realizar esta acción, prueba más tarde o escríbenos a suport@trocalia.net.",
    trialPeriodAnnual: "15 días gratis",
    trialPeriodMonthly: "7 días gratis",
  },
  CAT: {
    pro: {
      trocaliaPro: "Trocalia PRO",
      benefits: [
        "Destaca el teu perfil i les teves ofertes",
        "Xats il·limitats amb usuaris",
        "Descobreix qui visita el teu perfil",
        "Ofertes il·limitades per publicar",
        "Sigues mecenes de la comunitat",
      ],
      visibilidad: "Més visibilitat, més clients",
      nombreanual: "Pro Anual",
      nombremes: "Pro Mensual",
      bePro: "Accés PRO",
      beProTrial: "Prova-ho Gratis",
    },
    colab: {
      trocaliaPro: "Trocalia COLAB",
      benefits: [
        "Descobreix qui visita el teu perfil",
        "Col·labora per a mantenir els costos del projecte",
        "Destaca el teu perfil en Blau",
        "Promou l'economia local i sostenible",
        "Contribueix perquè Trocalia segueixi funcionant",
      ],
      visibilidad: "La teva ajuda manté la comunitat",
      nombreanual: "Colab Anual",
      nombremes: "Colab Mensual",
      colabButtonText: "Vull Col·laborar",
    },
    priceAnnualPeriod: "/any",
    priceMonthlyPeriod: "/mes",
    priceannualios: "/cada any (auto-renovable)",
    pricemonthlyios: "/cada mes (auto-renovable)",
    or: "o",
    close: "Tancar",
    save: "Estalvi",
    popupBlockerWarning:
      "Si el procés de pagament no s'obre, assegura't de permetre finestres emergents a Safari. Configuració > Safari > Bloquejar finestres emergents.",
    errorcheckout:
      "Error en iniciar el procés de pagament: verifica la teva connexió a internet i torna-ho a intentar.",
    termsAndConditions: "Termes i Condicions",
    termsAndConditionsEULA: "Termes d'ús (EULA)",
    privacyPolicy: "Política de Privacitat",
    support: "Suport",
    yasoypro:
      "Si prèviament has adquirit Trocalia PRO, pots restaurar les teves compres aquí.",
    restaurar: "Restaurar Compres",
    offeringsError: "No disponible",
    offeringsErrorMessage:
      "En aquest moment no es pot realitzar aquesta acció, prova-ho més tard o escriu-nos a suport@trocalia.net.",
    trialPeriodAnnual: "15 dies gratis",
    trialPeriodMonthly: "7 dies gratis",
  },
  ENG: {
    pro: {
      trocaliaPro: "Trocalia PRO",
      benefits: [
        "Highlight your profile and offers",
        "Unlimited chats with users",
        "Discover who visits your profile",
        "Unlimited offers to post",
        "Become a patron of the community",
      ],
      visibilidad: "More visibility, more clients",
      nombreanual: "Pro Annual",
      nombremes: "Pro Monthly",
      bePro: "PRO Access",
      beProTrial: "Try it Free",
    },
    colab: {
      trocaliaPro: "Trocalia COLAB",
      benefits: [
        "See who visits your profile",
        "Support project maintenance costs",
        "Highlight your profile in blue",
        "Promote the local and sustainable economy",
        "Contribute to Trocalia's ongoing success",
      ],
      visibilidad: "Your help maintains the community",
      nombreanual: "Colab Annual",
      nombremes: "Colab Monthly",
      colabButtonText: "Collaborate",
    },
    priceAnnualPeriod: "/year",
    priceMonthlyPeriod: "/month",
    priceannualios: "/every year (auto-renewable)",
    pricemonthlyios: "/every month (auto-renewable)",
    or: "or",
    close: "Close",
    save: "Save",
    popupBlockerWarning:
      "If the payment process doesn't open, make sure to allow pop-ups in Safari. Settings > Safari > Block Pop-ups.",
    errorcheckout:
      "Error starting the payment process: check your internet connection and try again.",
    termsAndConditions: "Terms and Conditions",
    termsAndConditionsEULA: "End User License Agreement (EULA)",
    privacyPolicy: "Privacy Policy",
    support: "Support",
    yasoypro:
      "If you have previously purchased Trocalia PRO, you can restore your purchases here.",
    restaurar: "Restore Purchases",
    offeringsError: "Not Available",
    offeringsErrorMessage:
      "This action cannot be performed at the moment. Please try again later or contact us at suport@trocalia.net.",
    trialPeriodAnnual: "15-day trial",
    trialPeriodMonthly: "7-day trial",
  },
};

const iconsForBenefits = {
  pro: [187, 391, 240, 233, 179],
  colab: [82, 52, 233, 310, 179],
};

const ProColab = ({ closeModal, initialMode = "pro" }) => {
  const { isReady, offerings, purchaseSubscription, restorePurchases } =
    useRevenueCat();
  const { language, serverAddress, token, userData } = useContext(AppContext);
  const [mode, setMode] = useState(initialMode); // 'pro' or 'colab'
  const t = translations[language];
  const [planType, setPlanType] = useState("annual");
  const [showIOSMessage, setShowIOSMessage] = useState(false);
  const [monthlyPrice, setMonthlyPrice] = useState("");
  const [annualPrice, setAnnualPrice] = useState("");
  const [savings, setSavings] = useState("");
  const [isTrialMode, setIsTrialMode] = useState(true);
  const isPremium = userData?.data?.[0]?.tipusUsuari === "Premium";
  const isColab = userData?.data?.[0]?.tipusUsuari === "Colaborador";

  // Animaciones para los beneficios
  const benefitsAnimations = useRef(
    t[mode].benefits.map(() => ({
      iconScale: new Animated.Value(0),
      textOpacity: new Animated.Value(0),
      iconOpacity: new Animated.Value(0),
    }))
  ).current;

  // Animación para el botón CTA
  const ctaAnimation = useRef(new Animated.Value(1)).current;

  // Función auxiliar para encontrar un paquete por su identificador
  const findPackage = (identifier) => {
    if (!offerings || !offerings.availablePackages) {
      return null;
    }
    const pkg = offerings.availablePackages.find(
      (pkg) => pkg.identifier === identifier
    );
    return pkg || null;
  };

  useEffect(() => {
    // Reiniciar las animaciones al cambiar el modo
    benefitsAnimations.forEach((anim) => {
      anim.iconScale.setValue(0);
      anim.iconOpacity.setValue(0);
      anim.textOpacity.setValue(0);
    });

    // Animación secuencial de los beneficios
    t[mode].benefits.forEach((_, index) => {
      Animated.sequence([
        Animated.delay(index * 500), // Retraso entre beneficios
        Animated.parallel([
          Animated.timing(benefitsAnimations[index].iconScale, {
            toValue: 1,
            duration: 600,
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
          Animated.timing(benefitsAnimations[index].iconOpacity, {
            toValue: 1,
            duration: 600,
            useNativeDriver: true,
          }),
          Animated.timing(benefitsAnimations[index].textOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
          }),
        ]),
      ]).start();
    });

    // Reiniciar la animación del botón CTA
    ctaAnimation.setValue(1);
    const animateCTAButton = () => {
      Animated.sequence([
        Animated.delay(3000),
        Animated.timing(ctaAnimation, {
          toValue: 1.05,
          duration: 500,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(ctaAnimation, {
          toValue: 1,
          duration: 500,
          easing: Easing.in(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.delay(6000 - 1000), // Ajuste para que el ciclo sea de 6 segundos
      ]).start(() => {
        animateCTAButton(); // Repite la animación
      });
    };

    animateCTAButton(); // Inicia la animación de inmediato
  }, [mode]);

  useEffect(() => {
    if (isReady && offerings && offerings.availablePackages) {
      try {
        // Seleccionar los paquetes adecuados basados en el modo y el plan
        let monthlyOffering, annualOffering;

        if (mode === "pro") {
          monthlyOffering = findPackage("$rc_monthly");
          annualOffering = findPackage("$rc_annual");
        } else if (mode === "colab") {
          monthlyOffering = findPackage("colab_monthly");
          annualOffering = findPackage("colab_annual");
        }

        if (monthlyOffering && annualOffering) {
          // Configurar los precios si ambas ofertas existen
          const monthlyPriceString = `${monthlyOffering.product.priceString}`;
          const annualPriceString = `${annualOffering.product.priceString}`;
          setMonthlyPrice(monthlyPriceString);
          setAnnualPrice(annualPriceString);

          // Cálculo del ahorro
          const monthlyPriceValue = monthlyOffering.product.price;
          const annualPriceValue = annualOffering.product.price;
          const calculatedSavings = Math.round(
            100 - (annualPriceValue / (monthlyPriceValue * 12)) * 100
          );
          setSavings(`${calculatedSavings}%`);
        } else {
          // Configuración de valores predeterminados si no se encuentran ofertas
          setDefaultPrices();
        }
      } catch (error) {
        setDefaultPrices();
      }
    } else {
      // Configuración de valores predeterminados si `isReady` o `offerings` son falsos
      setDefaultPrices();
    }
  }, [isReady, offerings, mode]);

  // Función para configurar valores predeterminados
  const setDefaultPrices = () => {
    try {
      if (mode === "pro") {
        setMonthlyPrice("5.99 €");
        setAnnualPrice("39.99 €");
        const defaultSavings = Math.round(100 - (39.99 / (5.99 * 12)) * 100);
        setSavings(`${defaultSavings}%`);
      } else if (mode === "colab") {
        setMonthlyPrice("1 €");
        setAnnualPrice("10 €");
        const defaultSavings = Math.round(100 - (10 / (1 * 12)) * 100);
        setSavings(`${defaultSavings}%`);
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };

  const detectOS = () => {
    try {
      if (typeof navigator !== "undefined" && navigator.userAgent) {
        const userAgent =
          navigator.userAgent || navigator.vendor || window.opera;
        return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
          ? "iOS"
          : "other";
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
    return "other"; // Valor por defecto en caso de error
  };

  const openLink = (url) => {
    try {
      Linking.canOpenURL(url)
        .then((supported) => {
          if (supported) {
            Linking.openURL(url);
          } else {
            // Manejo si no se puede abrir la URL
          }
        })
        .catch((error) => {
          // Manejo de errores si es necesario
        });
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };

  const initiateCheckout = async () => {
    try {
      const os = detectOS();

      if (Platform.OS === "web") {
        setShowIOSMessage(os === "iOS");

        // Crear planType combinado
        let combinedPlanType;
        if (mode === "pro") {
          combinedPlanType = planType; // 'monthly' o 'annual'
        } else if (mode === "colab") {
          combinedPlanType = `colab_${planType}`; // 'colab_monthly' o 'colab_annual'
        }

        // Proceso de checkout en web
        try {
          const response = await fetch(
            `${serverAddress}/create-checkout-session`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                planType: combinedPlanType, // Enviar planType combinado
              }),
            }
          );

          const data = await response.json();

          if (data.url) {
            window.open(data.url, "_blank"); // Abrir en nueva pestaña
          } else {
            // Manejo si no se encuentra la URL de checkout
          }
        } catch (error) {
          Alert.alert(t.errorcheckout);
          // Manejo de errores si es necesario
        }
      } else if (isReady) {
        // Manejo para plataformas móviles
        let packageToPurchase;

        if (mode === "pro") {
          packageToPurchase =
            planType === "annual"
              ? findPackage("$rc_annual") // Pro Anual
              : findPackage("$rc_monthly"); // Pro Mensual
        } else if (mode === "colab") {
          packageToPurchase =
            planType === "annual"
              ? findPackage("colab_annual") // Colab Anual
              : findPackage("colab_monthly"); // Colab Mensual
        }

        if (packageToPurchase) {
          try {
            await purchaseSubscription(packageToPurchase);
            closeModal();
          } catch (error) {
            if (!error.userCancelled) {
              Alert.alert(
                "Error",
                "Error al procesar la compra. Por favor, inténtalo de nuevo."
              );
              // Manejo de errores si es necesario
            }
          }
        } else {
          Alert.alert(
            "Error",
            "No se encontró el paquete de suscripción deseado."
          );
          // Manejo de errores si es necesario
        }
      }
    } catch (error) {
      // Manejo de errores si es necesario
    }
  };

  return (
    <View style={styles.outerContainer}>
      <ScrollView
        style={styles.scrollViewStyle}
        contentContainerStyle={styles.scrollViewContent}
        keyboardShouldPersistTaps="handled"
      >
        <View
          style={styles.container}
          // Capturamos los eventos táctiles para evitar que se propaguen al componente padre
          onStartShouldSetResponder={() => true}
        >
          <View style={styles.header}>
            <Image
              source={require("../img/icons/trocalia2.png")}
              style={styles.trocaliaIcon}
            />
            <Text
              style={[
                styles.modeLabel,
                mode === "pro" ? styles.proLabel : styles.colabLabel,
              ]}
            >
              {t[mode].trocaliaPro.split(" ")[1]}
            </Text>
            <View style={styles.modeSelector}>
              <TouchableOpacity
                style={[
                  styles.modeButton,
                  mode === "pro"
                    ? styles.selectedModeButtonPro
                    : styles.unselectedModeButtonPro,
                ]}
                onPress={() => setMode("pro")}
              >
                <Text
                  style={[
                    styles.modeButtonText,
                    mode === "pro"
                      ? styles.selectedModeButtonText
                      : styles.unselectedModeButtonText,
                  ]}
                >
                  PRO
                </Text>
              </TouchableOpacity>
              {!isColab && !isPremium && (
                <TouchableOpacity
                  style={[
                    styles.modeButton,
                    mode === "colab" && styles.selectedModeButton,
                  ]}
                  onPress={() => setMode("colab")}
                >
                  <Text
                    style={[
                      styles.modeButtonText,
                      mode === "colab" && styles.selectedModeButtonText,
                    ]}
                  >
                    COLAB
                  </Text>
                </TouchableOpacity>
              )}
            </View>
            <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
              <Image
                style={styles.closeIcon}
                source={require("../img/icons/block.png")}
              />
            </TouchableOpacity>
          </View>

          <View style={styles.visibilityContainer}>
            <Text style={styles.visibilidad}>{t[mode].visibilidad}</Text>
          </View>

          <ProfileImage
            style={[
              styles.profileImageStyle,
              {
                borderColor: mode === "colab" ? Colors.primary : Colors.secondary,
              },
            ]}
          />

          <View style={styles.content}>
            {t[mode].benefits.map((benefit, index) => {
              const iconId = iconsForBenefits[mode][index];
              const iconSource =
                imageMap[iconId] || require("../img/icons/cat/22.png");

              return (
                <View key={index} style={styles.benefitWrapper}>
                  <Animated.View
                    style={[
                      styles.benefitContainer,
                      {
                        opacity: benefitsAnimations[index].textOpacity,
                      },
                    ]}
                  >
                    <Animated.Image
                      style={[
                        styles.benefitIcon,
                        {
                          opacity: benefitsAnimations[index].iconOpacity,
                          transform: [
                            {
                              scale: benefitsAnimations[
                                index
                              ].iconScale.interpolate({
                                inputRange: [0, 0.5, 1],
                                outputRange: [0.8, 1.1, 1],
                              }),
                            },
                          ],
                        },
                      ]}
                      source={iconSource}
                    />
                    <Text style={styles.textbenefit}>{benefit}</Text>
                  </Animated.View>
                </View>
              );
            })}
          </View>

          {/* Contenedor del selector de precios */}
          <View style={styles.priceContainer}>
            <TouchableOpacity
              style={[
                styles.priceOption,
                planType === "annual" && styles.selectedPrice,
              ]}
              onPress={async () => {
                try {
                  if (Platform.OS !== "web") {
                    await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
                  }
                  setPlanType("annual");
                } catch (error) {
                  // Manejo de errores si es necesario
                }
              }}
            >
              <Text
                style={[
                  styles.subscriptionTitle,
                  planType === "annual"
                    ? styles.selectedPlanTitleBold
                    : styles.subscriptionTitle,
                ]}
              >
                {t[mode].nombreanual}
              </Text>
              <View style={{ alignItems: "center" }}>
                <Text
                  style={
                    planType === "annual"
                      ? styles.selectedTextPrice
                      : styles.textPrice
                  }
                >
                  {`${annualPrice}`}
                </Text>
                <Text style={styles.textPeriod}>
                  {Platform.OS === "ios"
                    ? t.priceannualios.split(" (")[0]
                    : t.priceAnnualPeriod}
                </Text>

                {/* Muestra el ahorro */}
                <View style={styles.savingsBadge}>
                  <Text style={styles.savingsText}>{`${t.save} ${savings}`}</Text>
                </View>

                {/* Muestra el texto de prueba gratuita si está en modo de prueba y no es Colab */}
                {isTrialMode && mode === "pro" && (
                  <Text style={styles.trialTextHighlighted}>
                    {t.trialPeriodAnnual}
                  </Text>
                )}
              </View>
            </TouchableOpacity>
            <View style={styles.separator2}></View>
            {/* Para el plan mensual */}
            <TouchableOpacity
              style={[
                styles.priceOption,
                planType === "monthly" && styles.selectedPrice,
              ]}
              onPress={async () => {
                try {
                  if (Platform.OS !== "web") {
                    await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
                  }
                  setPlanType("monthly");
                } catch (error) {
                  // Manejo de errores si es necesario
                }
              }}
            >
              <Text
                style={[
                  styles.subscriptionTitle,
                  planType === "monthly"
                    ? styles.selectedPlanTitleBold
                    : styles.subscriptionTitle,
                ]}
              >
                {t[mode].nombremes}
              </Text>
              <View style={{ alignItems: "center" }}>
                <Text
                  style={
                    planType === "monthly"
                      ? styles.selectedTextPrice
                      : styles.textPrice
                  }
                >
                  {`${monthlyPrice}`}
                </Text>
                <Text style={styles.textPeriod}>
                  {Platform.OS === "ios"
                    ? t.pricemonthlyios.split(" (")[0]
                    : t.priceMonthlyPeriod}
                </Text>

                {/* Muestra el texto de prueba gratuita si está en modo de prueba y no es Colab */}
                {isTrialMode && mode === "pro" && (
                  <Text style={styles.trialTextHighlighted}>
                    {t.trialPeriodMonthly}
                  </Text>
                )}
              </View>
            </TouchableOpacity>
          </View>

          <Animated.View
            style={{
              transform: [{ scale: ctaAnimation }],
            }}
          >
            <TouchableOpacity
              style={styles.proButton}
              onPress={async () => {
                try {
                  if (Platform.OS === "ios" || Platform.OS === "android") {
                    await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
                  }
                  initiateCheckout();
                } catch (error) {
                  // Manejo de errores si es necesario
                }
              }}
              activeOpacity={0.8}
            >
              <Text style={styles.proButtonText}>
                {mode === "colab"
                  ? t[mode].colabButtonText
                  : isTrialMode && mode === "pro"
                  ? t[mode].beProTrial
                  : t[mode].bePro}
              </Text>
            </TouchableOpacity>
          </Animated.View>

          <View style={styles.linksContainer}>
            <TouchableOpacity
              onPress={() =>
                openLink(
                  Platform.OS === "ios"
                    ? "https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                    : "https://trocalia.net/termes"
                )
              }
            >
              <Text style={styles.linkText}>
                {Platform.OS === "ios"
                  ? t.termsAndConditionsEULA
                  : t.termsAndConditions}
              </Text>
            </TouchableOpacity>
            <Text style={styles.separator}></Text>
            <TouchableOpacity
              onPress={() => openLink("https://trocalia.net/privacitat")}
            >
              <Text style={styles.linkText}>{t.privacyPolicy}</Text>
            </TouchableOpacity>
          </View>
          {Platform.OS === "ios" && (
            <>
              <Text style={styles.restoreInfoText}>{t.yasoypro}</Text>
              <TouchableOpacity
                style={styles.restoreButton}
                onPress={() => {
                  try {
                    restorePurchases();
                  } catch (error) {
                    // Manejo de errores si es necesario
                  }
                }}
              >
                <Text style={styles.restoreButtonText}>{t.restaurar}</Text>
              </TouchableOpacity>
            </>
          )}

          {showIOSMessage && (
            <Text style={styles.iosMessageStyle}>{t.popupBlockerWarning}</Text>
          )}
          <View style={styles.bottomSpacer} />
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
  },
  scrollViewStyle: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  scrollViewContent: {
    flexGrow: 1,
    alignItems: "center",
  },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    justifyContent: "flex-start",
    alignItems: "center",
    // Capturamos los eventos táctiles aquí
    // onStartShouldSetResponder se agrega en el componente directamente
  },
  header: {
    alignItems: "center",
    paddingHorizontal: 20,
    paddingTop: Platform.OS === "web" ? 14 : Platform.OS === "ios" ? 70 : 30,
  },
  closeButton: {
    position: "absolute",
    right: -80,
    top: Platform.OS === "web" ? 22 : Platform.OS === "ios" ? 55 : 28,
    zIndex: 1,
  },
  closeIcon: {
    width: 30,
    height: 30,
  },
  content: {
    marginTop: 2,
    paddingHorizontal: 0,
    marginBottom: 22,
  },
  benefitWrapper: {
    overflow: "hidden",
  },
  benefitContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginBottom: 24,
    maxWidth: 340,
    paddingHorizontal: 10,
    
  },
  benefitIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  proButton: {
    backgroundColor: Colors.primary,
    paddingVertical: 14,
    paddingHorizontal: 30,
    borderRadius: 30,
    marginTop: 18,
    shadowColor: Colors.secondary,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.65,
    shadowRadius: 6,
    elevation: 6,
    marginBottom: 18,
  },
  proButtonText: {
    ...Fonts.poppinsSemiBold16,
    color: Colors.white,
    textAlign: "center",
    textTransform: "uppercase",
  },
  priceContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 0,
    marginBottom: 26,
  },
  priceOption: {
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.grayMedium,
    marginHorizontal: 5,
    marginTop: 0,
    width: 140,
  },
  selectedPrice: {
    backgroundColor: Colors.primary,
    borderColor: Colors.primary,
  },
  profileImageStyle: {
    borderWidth: 3,
    marginTop: 10,
    marginBottom: 20,
  },
  trocaliaIcon: {
    width: 150,
    height: 50,
    resizeMode: "contain",
  },
  savingsBadge: {
    flexDirection: "row",
    height: 20,
    paddingHorizontal: 6,
    position: "absolute",
    top: -50,
    right: -18,
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.grayDark,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  savingsText: {
    color: Colors.grayDark,
    fontSize: 12,
    fontWeight: "bold",
  },
  modeLabel: {
    ...Fonts.poppinsSemiBold14,
    color: Colors.white,
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 10,
    textAlign: "center",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: Platform.OS === "web" ? 40 : Platform.OS === "ios" ? 95 : 54,
    right: -22,
  },
  proLabel: {
    backgroundColor: Colors.secondary,
  },
  colabLabel: {
    backgroundColor: Colors.primary,
    right: -38,
  },
  visibilidad: {
    color: Colors.primary,
    ...Fonts.poppinsMedium12,
    fontWeight: "600",
    textAlign: "center",
    marginTop: 0,
    marginBottom: 2,
    textTransform: "uppercase",
    letterSpacing: 2,
  },
  textbenefit: {
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
    flexShrink: 1,
    textAlign: "left",
  },
  textPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.grayDark,
  },
  selectedTextPrice: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.white,
  },
  textPeriod: {
    fontSize: 14,
    color: Colors.grayDark,
  },
  separator: {
    height: 40,
    width: 2,
    backgroundColor: Colors.grayMedium,
    marginHorizontal: 10,
  },
  separator2: {
    height: 40,
    width: 2,
    backgroundColor: Colors.grayMedium,
    alignSelf: "center",
    marginHorizontal: 6,
  },
  iosMessageStyle: {
    marginTop: 10,
    color: "red",
    textAlign: "center",
    paddingHorizontal: 20,
  },
  linksContainer: {
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    marginTop: 20,
    marginBottom: 10,
  },
  linkText: {
    color: Colors.primary,
    textDecorationLine: "underline",
    marginHorizontal: 5,
    marginVertical: 10,
    fontSize: 16,
  },
  bottomSpacer: {
    height: 30,
  },
  restoreInfoText: {
    marginVertical: 10,
    textAlign: "center",
    color: Colors.grayDark,
    paddingHorizontal: 20,
  },
  restoreButton: {
    marginVertical: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 10,
    paddingHorizontal: 20,
    alignSelf: "center",
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  restoreButtonText: {
    color: Colors.primary,
    textAlign: "center",
  },
  subscriptionTitle: {
    color: Colors.grayDark,
    textAlign: "center",
    padding: 0,
    marginBottom: 6,
  },
  selectedSubscriptionTitle: {
    color: Colors.white,
    textAlign: "center",
    padding: 0,
    marginBottom: 6,
  },
  trialTextHighlighted: {
    color: Colors.secondary,
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 4,
  },
  planTitle: {
    fontSize: 16,
    color: Colors.grayDark,
    textAlign: "center",
    marginBottom: 3,
  },
  selectedPlanTitleBold: {
    fontSize: 16,
    color: Colors.white,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 3,
    textDecorationLine: "underline",
  },
  modeSelector: {
    position: "absolute",
    left: 180,
    top: Platform.OS === "web" ? 100 : Platform.OS === "ios" ? 150 : 123,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  modeButton: {
    padding: 5,
    marginVertical: 5,
    backgroundColor: Colors.white,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: Colors.grayMedium,
    width: 70,
  },
  selectedModeButton: {
    backgroundColor: Colors.primary,
    borderColor: Colors.primary,
  },
  selectedModeButtonPro: {
    backgroundColor: Colors.secondary,
    borderColor: Colors.secondary,
  },
  modeButtonText: {
    color: Colors.primary,
    fontWeight: "bold",
    textAlign: "center",
  },
  selectedModeButtonText: {
    color: Colors.white,
    fontWeight: "bold",
    textAlign: "center",
  },
  proInactiveText: {
    color: Colors.secondary,
  },
  unselectedModeButtonPro: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayMedium,
  },
  unselectedModeButtonText: {
    color: Colors.primary,
  },
});

export default ProColab;
