import React, { useContext, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
  Platform,
  Modal,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Colors, Fonts } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";
import Pro from "../components/Pro.js";
import NeedRegister from "../selectables/NeedRegister.js";

const translations = {
  ESP: {
    venta: "Venta",
    alquiler: "Alquiler",
    prestamo: "Préstamo",
    regalo: "Regalo",
    compra: "Compra",
    prestado: "Prestado",
    regalado: "Regalado",
    servicio: "Servicio",
    promoPart1: "¿Quieres aparecer destacado?",
    promoPart2: "Hazte PRO",
  },
  CAT: {
    venta: "Venda",
    alquiler: "Lloguer",
    prestamo: "Préstec",
    regalo: "Regal",
    compra: "Compra",
    prestado: "Prestat",
    regalado: "Regalat",
    servicio: "Servei",
    promoPart1: "Vols aparèixer destacat?",
    promoPart2: "Fes-te PRO",
  },
  ENG: {
    venta: "Sale",
    alquiler: "Rent",
    prestamo: "Loan",
    regalo: "Gift",
    compra: "Purchase",
    prestado: "Borrowed",
    regalado: "Given away",
    servicio: "Service",
    promoPart1: "Do you want to stand out?",
    promoPart2: "Become PRO",
  },
};

const CardSVerticalSmall = ({ data, isPromotionCard }) => {
  const navigation = useNavigation();
  const { serverAddress, language, userData } = useContext(AppContext);
  const [isProModalVisible, setIsProModalVisible] = useState(false);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const handlePress = () => {
    navigation.navigate("ProfileOther", { userId: data.id_usuari });
  };

  const renderNeedRegisterModal = () => {
    if (isNeedRegisterVisible) {
      return (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      );
    }
    return null;
  };

  const handlePressPromotion = () => {
    if (userData?.data[0]?.tipusUsuari !== "Premium") {
      setIsProModalVisible(true);
    } else {
      alert("User is already a PRO");
    }
  };

  if (isPromotionCard) {
    return (
      <View>
        <TouchableOpacity onPress={handlePressPromotion}>
          <View style={[styles.cardContainer, styles.promotionCard]}>
            <Text style={styles.promotionCardTextPart1}>
              {translations[language].promoPart1}
            </Text>
            <Text style={styles.promotionCardTextPart2}>
              {translations[language].promoPart2}
            </Text>
          </View>
        </TouchableOpacity>
        <Modal
          animationType="slide"
          transparent={false}
          visible={isProModalVisible}
          onRequestClose={() => setIsProModalVisible(false)}
        >
          <Pro closeModal={() => setIsProModalVisible(false)} />
        </Modal>
      </View>
    );
  }

  if (!data) {
    return null;
  }

  const isOfferOrDemand =
    data.id_Oferta !== undefined || data.id_demanda !== undefined;

  const roundedDistancia = data.distancia ? Math.round(data.distancia) : null;
  const roundedValoracio = data.valoracio
    ? Math.round(data.valoracio * 10) / 10
    : "-";

  const imageUrl = isOfferOrDemand
    ? data.fotoOferta
      ? `${serverAddress}/fotos/${data.fotoOferta}`
      : data.foto || data.Foto
      ? `${serverAddress}/fotos/${data.foto || data.Foto}`
      : null
    : data.foto || data.Foto
    ? `${serverAddress}/fotos/${data.foto || data.Foto}`
    : null;

  const title = isOfferOrDemand
    ? data.titul
    : `${data.nomUsuari} ${data.cognomUsuari}`;

  const description = isOfferOrDemand ? data.descripcio : data.presentacio;

  const getTransactionTypeDescription = (typeTransac, aplica_OD, language) => {
    let transactionType;
    if (aplica_OD === "O") {
      switch (typeTransac) {
        case "V":
          transactionType = "venta";
          break;
        case "L":
          transactionType = "alquiler";
          break;
        case "P":
          transactionType = "prestamo";
          break;
        case "G":
          transactionType = "regalo";
          break;
        default:
          transactionType = "servicio";
      }
    } else if (aplica_OD === "D") {
      switch (typeTransac) {
        case "V":
          transactionType = "compra";
          break;
        case "L":
          transactionType = "alquiler";
          break;
        case "P":
          transactionType = "prestado";
          break;
        case "G":
          transactionType = "regalado";
          break;
        default:
          transactionType = "servicio";
      }
    }
    return translations[language][transactionType];
  };

  return (
    <TouchableOpacity onPress={handlePress}>
      <View
        style={[
          styles.cardContainer,
          data.tipusUsuari === "Premium"
            ? styles.cardContainerPremium
            : data.tipusUsuari === "Colaborador"
            ? styles.cardContainerCollaborator
            : styles.cardContainerStandard,
        ]}
      >
        <Image
          style={[
            styles.cardImage,
            data.tipusUsuari === "Premium"
              ? styles.premiumBorder
              : data.tipusUsuari === "Colaborador"
              ? styles.collaboratorBorder
              : {},
            isOfferOrDemand ? styles.offerDemandImageStyle : {},
          ]}
          resizeMode="cover"
          source={
            imageUrl
              ? { uri: imageUrl }
              : data.id_usuari != null
              ? profileImages[data.id_usuari % 10]
              : require("../img/profiles/default.png")
          }
        />

        {data.tipusUsuari === "Premium" && (
          <Text style={styles.proText}>PRO</Text>
        )}

        <Text
          style={[
            Fonts.poppinsMedium14,
            styles.nameText,
            data.tipusUsuari === "Premium" ? styles.premiumText : {},
          ]}
          numberOfLines={3}
          ellipsizeMode="tail"
        >
          {title}
        </Text>
        {roundedDistancia !== null && (
          <Text style={[Fonts.poppinsRegular12, styles.kmText]}>
            {`${roundedDistancia} Km`}
          </Text>
        )}
        {isOfferOrDemand ? (
          <Text style={[Fonts.poppinsRegular12, styles.transactionText]}>
            {getTransactionTypeDescription(
              data.tipoTransac,
              data.aplica_OD,
              language
            )}
          </Text>
        ) : (
          <View style={styles.valoracionContainer}>
            <Text style={[Fonts.poppinsRegular12, styles.ratingText]}>
              {roundedValoracio}
            </Text>
            <Image
              style={styles.iconText}
              resizeMode="cover"
              source={require("../img/icons/puntuation.png")}
            />
          </View>
        )}
      </View>
      {renderNeedRegisterModal()}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 123,
    height: 180,
    marginRight: 16,
    marginBottom: 15,
    marginTop: 8,
    borderWidth: 1,
    borderRadius: 16,
  },
  cardContainerStandard: {
    backgroundColor: Colors.grayDark,
    borderColor: Colors.grayMedium, // Cambiado a gris medio para borde completo del componente
  },
  cardContainerPremium: {
    backgroundColor: "rgba(255, 223, 158, 0.2)", // Fondo amarillo claro para Premium
    borderColor: Colors.secondary,
  },
  cardContainerCollaborator: {
    backgroundColor: "rgba(177, 217, 244, 0.2)", // Fondo azul claro para Colaboradores
    borderColor: Colors.primary,
  },
  cardImage: {
    position: "absolute",
    left: 20,
    top: 9,
    borderRadius: 69,
    width: 79,
    height: 73,
    backgroundColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  premiumBorder: {
    borderWidth: 2,
    borderColor: Colors.secondary,
  },
  collaboratorBorder: {
    borderWidth: 2,
    borderColor: Colors.primary,
  },
  nameText: {
    left: 6,
    top: 87,
    width: 113,
    marginBottom: -25,
    lineHeight: 21,
  },
  kmText: {
    position: "absolute",
    top: 160,
    right: 10,
    textAlign: "right",
    maxWidth: 130,
  },
  ratingText: {
    marginRight: 8,
    marginBottom: -6,
    marginLeft: 2,
  },
  iconText: {
    width: 16,
    height: 16,
    marginBottom: -2,
  },
  valoracionContainer: {
    position: "absolute",
    left: 8,
    bottom: 6,
    flexDirection: "row",
    alignItems: "center",
  },
  transactionText: {
    position: "absolute",
    left: 8,
    top: 160,
  },
  promotionCard: {
    borderRadius: 15,
    backgroundColor: Colors.white, // Fondo blanco para la tarjeta de promoción
    borderColor: Colors.grayMedium, // Borde gris claro para que no sea tan oscuro
    borderWidth: 1, // Añadir borde para la tarjeta de promoción
    justifyContent: "center",
    alignItems: "center",
  },
  promotionCardTextPart1: {
    color: Colors.grayDark,
    ...Fonts.poppinsMedium16,
    textAlign: "center",
  },
  promotionCardTextPart2: {
    color: Colors.secondary,
    ...Fonts.poppinsMedium16,
    textAlign: "center",
    marginTop: 12,
  },
});

export default CardSVerticalSmall;
