import React, { useContext, useEffect, useState } from "react";
import {
  Text,
  View,
  StyleSheet,
  Modal,
  TouchableOpacity,
  Image,
} from "react-native";
import { AppContext } from "../../AppContext";
import Ubication from "./Ubication";
import pinImage from "../img/icons/location.png";
import { Colors, Fonts } from "../styles/styles.js";

const translations = {
  ESP: {
    loading: "Cargando...",
    greeting: "Hola,",
  },
  CAT: {
    loading: "Carregant...",
    greeting: "Hola,",
  },
  ENG: {
    loading: "Loading...",
    greeting: "Hello,",
  },
};

const Greeting = () => {
  const { userData, language } = useContext(AppContext);
  const t = translations[language];
  const [modalVisible, setModalVisible] = useState(false);

  const showLocationModal = () => setModalVisible(true);
  const hideLocationModal = () => setModalVisible(false);

  if (!userData) {
    return <Text>{t.loading}</Text>;
  }

  return (
    <View style={styles.container}>
      <Modal
        animationType="slide"
        transparent={false}
        visible={modalVisible}
        onRequestClose={hideLocationModal}
      >
        <Ubication hideModal={hideLocationModal} />
      </Modal>

      <View style={styles.greetingContainer}>
        <View style={styles.overlapGroup}>
          <Text style={styles.name}>
            {t.greeting} {userData.data[0].nomUsuari}
          </Text>
          <TouchableOpacity
            onPress={showLocationModal}
            style={styles.locationContainer}
          >
            <Image source={pinImage} style={styles.pinInstance} />
            <Text style={styles.location}>{userData.data[0].zona}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: -4,
    marginLeft: -90,
  },
  greetingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  overlapGroup: {
    height: 63,
    width: 233,
  },
  name: {
    ...Fonts.dmSerifDisplayRegular24,
    color: Colors.black,
    width: 300,
  },
  locationContainer: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    top: 36,
    left: 0,
  },
  location: {
    ...Fonts.poppinsRegular14,
    color: Colors.primary,
    marginLeft: 5,
    
  },
  pinInstance: {
    height: 16,
    width: 16,
  },
});

export default Greeting;
