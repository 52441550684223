import React, { useContext, useEffect, useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Easing,
  Image,
} from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import { MaterialIcons } from "@expo/vector-icons";
import imageMap from "../db/imageMap"; // Asegúrate de la ruta correcta

const translations = {
  ESP: {
    proTitle: "Prueba Trocalia Pro Gratis",
    proMessage: "Destaca tu perfil y tus ofertas",
    colabTitle: "1€ Trocalia COLAB",
    colabMessage: "Tu ayuda mantiene la comunidad",
    publicitariTitle: "Ofertas del Portal Publicitario",
    publicitariMessage: "Impulsa tu negocio con publicidad",
    compartirTitle: "Invita a tus conocidos",
    compartirMessage: "Comparte con tus grupos",
    close: "Cerrar",
  },
  CAT: {
    proTitle: "Prova Trocalia Pro Gratis",
    proMessage: "Destaca el teu perfil i ofertes",
    colabTitle: "1€ Trocalia COLAB",
    colabMessage: "La teva ajuda manté la comunitat",
    publicitariTitle: "Ofertes al Portal Publicitari",
    publicitariMessage: "Impulsa el teu negoci amb publicitat",
    compartirTitle: "Convida als teus coneguts",
    compartirMessage: "Comparteix amb els teus grups ",
    close: "Tancar",
  },
  ENG: {
    proTitle: "Try Trocalia Pro Free",
    proMessage: "Highlight your profile and offers",
    colabTitle: "1€ Trocalia COLAB",
    colabMessage: "Your support keeps the community alive",
    publicitariTitle: "Offers of the Advertising Portal",
    publicitariMessage: "Boost your business by ads",
    compartirTitle: "Grow the Community",
    compartirMessage: "Share with your groups",
    close: "Close",
  },
};

const Popup = ({
  onClose,
  visible,
  popupType, // Nuevo prop para indicar el tipo de popup
  onPressPro,
  onPressColab,
  onPressPublicitari,
  onPressCompartir, // Nuevo prop para Compartir Trocalia
  sendActionToServer,
}) => {
  const { language, userData } = useContext(AppContext);
  const t = translations[language];

  const slideAnim = useRef(new Animated.Value(300)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const scaleAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    if (visible) {
      // Animación inicial al mostrar el popup
      Animated.timing(slideAnim, {
        toValue: 0,
        duration: 1000,
        easing: Easing.out(Easing.ease),
        useNativeDriver: true,
      }).start();

      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 800,
        useNativeDriver: true,
      }).start();

      // Animación de ampliación periódica
      const startPeriodicAnimations = () => {
        Animated.sequence([
          Animated.timing(scaleAnim, {
            toValue: 1.05,
            duration: 1800,
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
          Animated.timing(scaleAnim, {
            toValue: 1,
            duration: 800,
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
        ]).start();
      };

      // Iniciar animación periódica después de 4 segundos
      const animationTimeout = setTimeout(() => {
        startPeriodicAnimations();
        const interval = setInterval(() => {
          startPeriodicAnimations();
        }, 10000);

        return () => clearInterval(interval);
      }, 4000);

      // Limpiar animaciones al desmontar
      return () => {
        fadeAnim.stopAnimation();
        scaleAnim.stopAnimation();
        clearTimeout(animationTimeout);
      };
    } else {
      slideAnim.setValue(300);
      fadeAnim.setValue(0);
    }
  }, [visible]);

  const handleClose = () => {
    // console.log("Popup closed");
  
    Animated.timing(slideAnim, {
      toValue: 300,
      duration: 800,
      easing: Easing.in(Easing.ease),
      useNativeDriver: true,
    }).start(() => {
      onClose();
  
      // Determina si el usuario es Guest de manera segura
      const isUserGuest = userData?.data?.[0]?.tipusUsuari === "Guest";

      // Si es "Guest", salimos del código aquí y no enviamos la acción al servidor
      if (isUserGuest) {
        return;
      }
    
      // Si no es "Guest" o el estado no se pudo determinar, enviamos la acción al servidor
      try {
        sendActionToServer("HaTancatPopup");
        //console.log("sendActionToServer has been called successfully");
      } catch (error) {
        //console.error("Error calling sendActionToServer:", error);
      }
    });
  };
  

  // Mapeo de tipos de popup a sus propiedades
  const popupConfig = {
    1: {
      title: t.compartirTitle,
      message: t.compartirMessage,
      icon: imageMap[107],
      backgroundColor: Colors.secondary, // Suponiendo que 'secondary' es para Compartir
      onPress: onPressCompartir,
      titleStyle: styles.compartirTitleText,
      messageStyle: styles.compartirMessageText,
    },
    2: {
      title: t.proTitle,
      message: t.proMessage,
      icon: imageMap[22],
      backgroundColor: Colors.secondary,
      onPress: onPressPro,
      titleStyle: styles.proTitleText,
      messageStyle: styles.proMessageText,
    },
    3: {
      title: t.colabTitle,
      message: t.colabMessage,
      icon: imageMap[179],
      backgroundColor: Colors.primary,
      onPress: onPressColab,
      titleStyle: styles.colabTitleText,
      messageStyle: styles.colabMessageText,
    },
    4: {
      title: t.publicitariTitle,
      message: t.publicitariMessage,
      icon: imageMap[240],
      backgroundColor: Colors.primary,
      onPress: onPressPublicitari,
      titleStyle: styles.publicitariTitleText,
      messageStyle: styles.publicitariMessageText,
    },
  };

  // Si el popupType no es válido, no renderizamos nada
  if (!visible || !popupConfig[popupType]) {
    return null;
  }

  const { title, message, icon, backgroundColor, onPress, titleStyle, messageStyle } = popupConfig[popupType];

  return (
    <Animated.View
      style={[
        styles.popupContainer,
        {
          backgroundColor: backgroundColor,
          transform: [{ translateY: slideAnim }, { scale: scaleAnim }],
          opacity: fadeAnim,
        },
      ]}
    >
      <TouchableOpacity onPress={handleClose} style={styles.closeButton}>
        <MaterialIcons name="close" size={28} color={Colors.white} />
      </TouchableOpacity>

      <TouchableOpacity onPress={onPress} style={styles.content}>
        <Image source={icon} style={styles.iconImage} />

        <View style={styles.textContainer}>
          <Text style={[styles.titleText, titleStyle]}>
            {title}
          </Text>
          <Text style={[styles.messageText, messageStyle]}>
            {message}
          </Text>
        </View>
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  popupContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderTopLeftRadius: 80,
    borderTopRightRadius: 80,
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 10,
    height: 72,
    zIndex: 1000,
  },
  closeButton: {
    position: "absolute",
    top: 10,
    right: 30,
  },
  content: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: -22,
  },
  iconImage: {
    width: 36,
    height: 36,
    marginRight: 10,
  },
  textContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  titleText: {
    ...Fonts.poppinsSemiBold16,
    marginBottom: 3,
    textAlign: "center",
  },
  proTitleText: {
    color: Colors.grayDark,
  },
  colabTitleText: {
    color: Colors.white,
  },
  publicitariTitleText: {
    color: Colors.white,
  },
  compartirTitleText: { // Nuevo estilo para Compartir Trocalia
    color: Colors.grayDark,
  },
  messageText: {
    ...Fonts.poppinsRegular16,
    textAlign: "center",
  },
  proMessageText: {
    color: Colors.grayDark,
  },
  colabMessageText: {
    color: Colors.white,
  },
  publicitariMessageText: {
    color: Colors.white,
  },
  compartirMessageText: { // Nuevo estilo para Compartir Trocalia
    color: Colors.grayDark,
  },
});

export default Popup;
