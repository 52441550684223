// RevenueCatContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import { Platform, LogBox, Alert } from "react-native";
import Constants from "expo-constants";
import { AppContext } from "./AppContext";
import Purchases from "react-native-purchases";
// import { logError } from "./src/utils/logError"; // Asegúrate de ajustar la ruta según tu estructura

const RevenueCatContext = createContext();

export const RevenueCatProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [offerings, setOfferings] = useState(null);
  const { miID, language } = useContext(AppContext);
  const translations = {
    ESP: {
      restoreCompleted: "Restauración completada",
      restoreCompletedMessage: "Tus compras han sido restauradas.",
      restoreError: "No hemos encontrado compras",
      restoreErrorMessage:
        "Si estás seguro de haber adquirido Trocalia PRO, por favor, contacta con suport@trocalia.net",
    },
    CAT: {
      restoreCompleted: "Restauració completada",
      restoreCompletedMessage: "Les teves compres han estat restaurades.",
      restoreError: "No hem trobat compres",
      restoreErrorMessage:
        "Si estàs segur de haver adquirit Trocalia PRO, si us plau, contacta amb suport@trocalia.net",
    },
    ENG: {
      restoreCompleted: "Restore completed",
      restoreCompletedMessage: "Your purchases have been restored.",
      restoreError: "No purchases found",
      restoreErrorMessage:
        "If you are sure you have purchased Trocalia PRO, please contact suport@trocalia.net",
    },
  };

  const restorePurchases = async () => {
    try {
      await Purchases.restoreTransactions(); // Se quita la asignación a `restoredInfo`
      const translation = translations[language];
      Alert.alert(translation.restoreCompleted, translation.restoreCompletedMessage);
    } catch (error) {
      const translation = translations[language];
      // Alert.alert(translation.restoreError, translation.restoreErrorMessage);
      // logError({
      //   message: "Error al restaurar compras",
      //   error: error.message,
      // });
    }
  };

  useEffect(() => {
    async function initializeRevenueCat() {
      if (Platform.OS === "web" || !miID) {
        // console.log(
        //   `Skipping RevenueCat initialization. Platform: ${Platform.OS}, miID: ${miID}`
        // );
        setIsLoading(false);
        return;
      }

      const { REVENUECAT_API_KEY_IOS, REVENUECAT_API_KEY_ANDROID } =
        Constants.expoConfig.extra;
      const apiKey = Platform.select({
        ios: REVENUECAT_API_KEY_IOS,
        android: REVENUECAT_API_KEY_ANDROID,
      });

      if (!apiKey) {
        // console.warn(
        //   "API key for RevenueCat is not set for this platform:",
        //   Platform.OS
        // );
        // logError({
        //   message: "API key de RevenueCat no está configurada para esta plataforma",
        //   platform: Platform.OS,
        // });
        setIsLoading(false);
        return;
      }

      try {
        // console.log(
        //   "Initializing RevenueCat with API Key:",
        //   apiKey,
        //   "and User ID:",
        //   miID
        // );
        //Purchases.setDebugLogsEnabled(true); // Habilita logs de debug de RevenueCat
        await Purchases.configure({ apiKey, appUserID: String(miID) });
        //console.log("RevenueCat initialized successfully with miID:", miID);
        setIsReady(true);
      } catch (error) {
        // console.error("Error initializing RevenueCat with miID:", miID, error);
        // logError({
        //   message: "Error al inicializar RevenueCat",
        //   miID,
        //   error: error.message,
        // });
        setIsLoading(false);
      }
    }

    initializeRevenueCat();
  }, [miID]);

  useEffect(() => {
    if (isReady) {
      // Obtener la información del cliente
      Purchases.getCustomerInfo()
        .then((customerInfo) => {
          // console.log("Customer Info:", customerInfo);
        })
        .catch((error) => {
          // console.error("Error fetching customer info:", error);
          // logError({
          //   message: "Error al obtener información del cliente",
          //   error: error.message,
          // });
        });

      // Obtener las ofertas disponibles
      Purchases.getOfferings()
        .then((fetchedOfferings) => {
          if (fetchedOfferings.current) {
            setOfferings(fetchedOfferings.current); // Almacena las offerings en el estado
            // console.log("Offerings fetched:", fetchedOfferings.current);
          } else {
            // console.log("No offerings found");
            // logError({
            //   message: "No se encontraron ofertas disponibles en RevenueCat",
            // });
          }
        })
        .catch((error) => {
          // console.error("Error fetching offerings:", error);
          // logError({
          //   message: "Error al obtener ofertas de RevenueCat",
          //   error: error.message,
          // });
        });
    }
  }, [isReady]);

  // Función para manejar las compras
  const purchaseSubscription = async (packageToPurchase) => {
    if (!isReady) {
      // console.log("RevenueCat no está listo.");
      // Alert.alert("Error", "RevenueCat no está listo.");
      // logError({
      //   message: "Intento de compra cuando RevenueCat no está listo",
      // });
      return;
    }

    try {
      const purchase = await Purchases.purchasePackage(packageToPurchase);
      // console.log("Compra exitosa:", purchase);
      // Actualiza el estado de la suscripción del usuario según sea necesario
    } catch (error) {
      if (!error.userCancelled) {
        // console.error("Error al realizar la compra:", error);
        // Alert.alert(
        //   "Error",
        //   "Error al procesar la compra. Por favor, inténtalo de nuevo."
        // );
        // logError({
        //   message: "Error al realizar la compra",
        //   error: error.message,
        // });
      }
    }
  };

  LogBox.ignoreLogs(["Setting a timer for a long period of time"]);

  return (
    <RevenueCatContext.Provider
      value={{ isReady, isLoading, offerings, restorePurchases, purchaseSubscription }}
    >
      {children}
    </RevenueCatContext.Provider>
  );
};

export const useRevenueCat = () => useContext(RevenueCatContext);
