import React, { useState, useCallback, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Switch,
  Image,
} from "react-native";
import Checkbox from "expo-checkbox";
import { Colors, Fonts } from "../styles/styles.js";
import { Platform } from "react-native";
import * as Haptics from "expo-haptics";
import { AppContext } from "../../AppContext";

// Traducciones para cada idioma
const translations = {
  ESP: {
    loading: "Cargando...",
    apply: "Aplicar",
    showResultsOf: "Mostrar resultados de:",
    applyFilters: "Aplicar filtros",
    onlyAcceptTrocs: "Solo los que aceptan Trocs",
    Particulares: "Particulares",
    Empresa: "Empresa",
    Venta: "Venta",
    Alquiler: "Alquiler",
    Préstamo: "Préstamo",
    Regalo: "Regalo",
  },
  CAT: {
    loading: "Carregant...",
    apply: "Aplicar",
    showResultsOf: "Mostrar resultats de:",
    applyFilters: "Aplicar filtres",
    onlyAcceptTrocs: "Només els que acceptin Trocs",
    Particulares: "Particulars",
    Empresa: "Empresa",
    Venta: "Venda",
    Alquiler: "Lloguer",
    Préstamo: "Préstec",
    Regalo: "Regal",
  },
  ENG: {
    loading: "Loading...",
    apply: "Apply",
    showResultsOf: "Show results of:",
    applyFilters: "Apply filters",
    onlyAcceptTrocs: "Only those who accept Trocs",
    Particulares: "Individuals",
    Empresa: "Company",
    Venta: "Sale",
    Alquiler: "Rent",
    Préstamo: "Loan",
    Regalo: "Gift",
  },
};

const Filter = ({ closeModal }) => {
  const { serverAddress, token, filterConfig, setFilterConfig, language } =
    useContext(AppContext);
  const t = translations[language] || translations.ESP; // ESP como idioma por defecto

  // Estado para los filtros y el interruptor
  const [switchValue, setSwitchValue] = useState(
    filterConfig?.switchValue || false
  );
  const [checkedItems, setCheckedItems] = useState(
    filterConfig?.checkedItems || {
      Venta: true,
      Alquiler: true,
      Préstamo: true,
      Regalo: true,
      Particulares: true,
      Empresa: true,
    }
  );

  // Función para cambiar el estado de los checkboxes
  const toggleCheckbox = useCallback((itemName) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
  }, []);

  // Función que se ejecuta al aplicar el filtro
  const onApplyFilter = async () => {
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);

    // Convertir los filtros al idioma ESP antes de enviar
    const spanishCheckedItems = {};
    Object.keys(checkedItems).forEach((key) => {
      const spanishKey =
        Object.keys(translations.ESP).find(
          (k) => translations.ESP[k] === key
        ) || key;
      spanishCheckedItems[spanishKey] = checkedItems[key];
    });

    setFilterConfig({
      switchValue,
      checkedItems: spanishCheckedItems,
    });
    closeModal();
  };

  // Renderizar los elementos de texto para los filtros
  const renderTextItems = useCallback(
    (items, startingTopPosition, leftPosition) => {
      const itemSeparation = 47;
      const adjustedLeftPosition = leftPosition - 15;

      return items.map((item, index) => (
        <View
          key={index}
          style={{
            position: "absolute",
            top: startingTopPosition + index * itemSeparation,
            left: adjustedLeftPosition,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Checkbox
            style={styles.checkbox}
            value={checkedItems[item]}
            onValueChange={() => toggleCheckbox(item)}
          />
          <Text style={Fonts.poppinsRegular16}>{t[item]}</Text>
        </View>
      ));
    },
    [checkedItems, toggleCheckbox, t]
  );

  const isWeb = Platform.OS === "web";

  return (
    <View style={styles.container}>
      <View
        style={isWeb ? styles.backgroundWeb : styles.background}
        onStartShouldSetResponder={isWeb ? null : () => true}
        onResponderRelease={isWeb ? null : closeModal}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <View
          style={isWeb ? styles.componentWeb : styles.component}
          onStartShouldSetResponder={() => true}
          onResponderRelease={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <View style={styles.component} onStartShouldSetResponder={() => true}>
            <View style={styles.div}>
              <TouchableOpacity
                onPress={closeModal}
                style={styles.blockIconWrapper}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
              >
                <Image
                  style={styles.blockIcon}
                  source={require("../img/icons/block.png")}
                />
              </TouchableOpacity>
              <View style={styles.rectangle} />
              <TouchableOpacity style={styles.buttons} onPress={onApplyFilter}>
                <Text style={styles.afegir}>{t.apply}</Text>
              </TouchableOpacity>
            </View>
            {renderTextItems(
              ["Venta", "Alquiler", "Préstamo", "Regalo"],
              278,
              114
            )}
            {renderTextItems(["Particulares", "Empresa"], 129, 114)}
            <Text
              style={[
                Fonts.poppinsRegular16,
                styles.textAbsolutePosition(223, 55),
              ]}
            >
              {t.showResultsOf}
            </Text>
            <Text
              style={[
                Fonts.poppinsMedium18,
                styles.textAbsolutePosition(26, 55),
              ]}
            >
              {t.applyFilters}
            </Text>
            <Text
              style={[
                Fonts.poppinsRegular16,
                styles.textAbsolutePosition(469, 124),
              ]}
            >
              {t.onlyAcceptTrocs}
            </Text>
            <Switch
              value={switchValue}
              onValueChange={setSwitchValue}
              style={styles.switchInstance}
            />
            <Image
              style={styles.bag}
              source={require("../img/icons/bag.png")}
            />
            <Image
              style={styles.troc}
              source={require("../img/icons/troc.png")}
            />
            <Text
              style={[
                Fonts.poppinsRegular16,
                styles.textAbsolutePosition(82, 55),
              ]}
            >
              {t.showResultsOf}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
  },
  component: {
    backgroundColor: Colors.grayLight,
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    bottom: 0,

    // añadir borde de 2px
  },
  div: {
    height: 612,
    width: Platform.OS === "web" ? "100%" : 375, // Aquí cambiamos el ancho según la plataforma
    backgroundColor: Colors.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowOpacity: 0.25,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 4,
    elevation: 5, // Añadir para Android
    ...(Platform.OS === "web" ? { alignItems: "center" } : {}),
  },
  rectangle: {
    backgroundColor: Colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    height: 679,
    position: "absolute",
    width: 375,
  },
  buttons: {
    borderRadius: 20,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderColor: "#85c7f2",
    borderWidth: 1,
    position: "absolute",
    top: 542,
    left: 137.5,
    width: 100,
    height: 35,
    alignItems: "center",
    justifyContent: "center",
  },
  afegir: {
    fontSize: 16,
    lineHeight: 30,
    fontWeight: "600",
    fontFamily: "Poppins-SemiBold",
    color: "#85c7f2",
    textAlign: "center",
  },
  switchInstance: {
    position: "absolute",
    top: 475,
    left: 54,
    width: 61,
  },
  blockIcon: {
    position: "absolute",
    top: 22, 
    right: 22, 
    width: 24, 
    height: 24, 
    zIndex: 1, 
  },
  bag: {
    height: 14,
    position: "absolute",
    top: 182,
    left: 204,
    width: 14,
  },
  troc: {
    height: 15,
    position: "absolute",
    top: 499,
    left: 174,
    width: 17,
  },
  blockIconWrapper: {
    position: "absolute",
    top: 22,
    right: 22,
    width: 44, 
    height: 44, 
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  blockIcon: {
    width: 24,
    height: 24,
  },
  textAbsolutePosition: (top, left) => ({
    position: "absolute",
    top: top,
    left: left,
    color: Colors.black,
  }),
  checkbox: {
    marginEnd: 8,
  },
});

export default Filter;
