export const NOTIFICATION_MESSAGES = {
  EMCH: {
    CAT: "T'han escrit un missatge",
    ESP: "Te han escrito un mensaje",
    ENG: "You have a new message",
  },
  HVTP: {
    CAT: "Han visitat el teu perfil",
    ESP: "Han visitado tu perfil",
    ENG: "Your profile has been visited",
  },
  EFAV: {
    CAT: "T'han afegit com a favorit",
    ESP: "Te han añadido como favorito",
    ENG: "You have been added as a favorite",
  },
  NVAL: {
    CAT: "T'han deixat una valoració",
    ESP: "Te han dejado una valoración",
    ENG: "You have received a rating",
  },
  TROCO: {
    CAT: "Nou Troc d'oferta",
    ESP: "Nuevo Troc de oferta",
    ENG: "New Troc offer",
  },
  TROCD: {
    CAT: "Nou Troc de demanda",
    ESP: "Nuevo Troc de demanda",
    ENG: "New Troc demand",
  },
  STROC: {
    CAT: "Nou SúperTroc!",
    ESP: "Nuevo SúperTroc!",
    ENG: "New SuperTroc!",
  },
  novaSubscripcioPro: {
    CAT: "Benvingut a Trocalia PRO!",
    ESP: "¡Bienvenido a Trocalia PRO!",
    ENG: "Welcome to Trocalia PRO!",
  },
  novaSubscripcioColab: {
    CAT: "Benvingut a Trocalia Colab!",
    ESP: "¡Bienvenido a Trocalia Colab!",
    ENG: "Welcome to Trocalia Colab!",
  },
  expiracioSubscripcioPro: {
    CAT: "La teva subscripció PRO ha expirat",
    ESP: "Tu suscripción PRO ha expirado",
    ENG: "Your PRO subscription has expired",
  },
  expiracioSubscripcioColab: {
    CAT: "La teva subscripció Colab ha expirat",
    ESP: "Tu suscripción Colab ha expirado",
    ENG: "Your Colab subscription has expired",
  },
  billingIssue: {
    CAT: "Problema amb el pagament",
    ESP: "Problema con el pago",
    ENG: "Payment issue",
  },
  FHPO: {
    CAT: "Un favorit teu ha publicat una oferta",
    ESP: "Un favorito tuyo ha publicado una oferta",
    ENG: "A favorite of yours has added an offer",
  },
  FHPD: {
    CAT: "Un favorit teu ha publicat una demanda",
    ESP: "Un favorito tuyo ha publicado una demanda",
    ENG: "A favorite of yours has added a demand",
  },
  HCTO: {
    CAT: "La teva oferta ha caducat i necessites reactivar-la",
    ESP: "Tu oferta ha caducado y necesitas reactivarla",
    ENG: "Your offer has expired and you need to reactivate it",
  },
  HCTD: {
    CAT: "La teva demanda ha caducat i necessites reactivar-la",
    ESP: "Tu demanda ha caducado y necesitas reactivarla",
    ENG: "Your demand has expired and you need to reactivate it",
  },
};

export const NOTIFICATION_MESSAGESID = {
  EMCH: {
    CAT: "t'ha escrit un missatge",
    ESP: "te ha escrito un mensaje",
    ENG: "has written you a message",
  },
  HVTP: {
    CAT: "ha visitat el teu perfil",
    ESP: "ha visitado tu perfil",
    ENG: "has visited your profile",
  },
  EFAV: {
    CAT: "t'ha afegit com a favorit",
    ESP: "te ha añadido como favorito",
    ENG: "has added you as a favorite",
  },
  NVAL: {
    CAT: "t'ha deixat una valoració",
    ESP: "te ha dejado una valoración",
    ENG: "has left you a rating",
  },
  TROCO: {
    CAT: "demanda quelcom que ofereixes",
    ESP: "demanda algo que ofreces",
    ENG: "needs something you offer",
  },
  TROCD: {
    CAT: "ofereix quelcom que demandes",
    ESP: "ofrece algo que demandas",
    ENG: "offers something you need",
  },
  STROC: {
    CAT: "Tens un nou SúperTroc",
    ESP: "Tienes un nuevo SúperTroc",
    ENG: "You have a new SuperTroc",
  },
  novaSubscripcioPro: {
    CAT: "Benvingut a Trocalia PRO!",
    ESP: "¡Bienvenido a Trocalia PRO!",
    ENG: "Welcome to Trocalia PRO!",
  },
  novaSubscripcioColab: {
    CAT: "Benvingut a Trocalia Colab!",
    ESP: "¡Bienvenido a Trocalia Colab!",
    ENG: "Welcome to Trocalia Colab!",
  },
  expiracioSubscripcioPro: {
    CAT: "La teva subscripció PRO ha expirat",
    ESP: "Tu suscripción PRO ha expirado",
    ENG: "Your PRO subscription has expired",
  },
  expiracioSubscripcioColab: {
    CAT: "La teva subscripció Colab ha expirat",
    ESP: "Tu suscripción Colab ha expirado",
    ENG: "Your Colab subscription has expired",
  },
  billingIssue: {
    CAT: "Problema amb el pagament",
    ESP: "Problema con el pago",
    ENG: "Payment issue",
  },
  NOTITXT: {
    CAT: "",
    ESP: "",
    ENG: "",
  },
  FHPO: {
    CAT: "Que és favorit teu ha publicat una oferta",
    ESP: "Que es favorito tuyo ha publicado una oferta",
    ENG: "Who is your favorite has published an offer",
  },
  FHPD: {
    CAT: "Que és favorit teu ha publicat una demanda",
    ESP: "Que es favorito tuyo ha publicado una demanda",
    ENG: "Who is your favorite has published a demand",
  },
  HCTO: {
    CAT: "La teva oferta ha caducat i necessites reactivar-la",
    ESP: "Tu oferta ha caducado y necesitas reactivarla",
    ENG: "Your offer has expired and you need to reactivate it",
  },
  HCTD: {
    CAT: "La teva demanda ha caducat i necessites reactivar-la",
    ESP: "Tu demanda ha caducado y necesitas reactivarla",
    ENG: "Your demand has expired and you need to reactivate it",
  },

};
