import React, { useContext, useEffect, useState, useRef } from "react";
import { Text, View, StyleSheet, TouchableOpacity, Share, Platform, Animated, Easing } from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles.js";
import * as Haptic from 'expo-haptics';

const translations = {
  ESP: {
    usersTextPrefix: "¡Ya somos",
    usersTextSuffix: "usuarios!",
    shareButtonText: "¡Comparte!🚀",
    shareMessage: "¡Hola! Te recomiendo la Red de Economía Vecinal 📍 Permite ofrecer y demandar Servicios y Objetos.\n👇\nhttps://trocalia.net/",
    xarxa: "Red de Economía Vecinal",
  },
  CAT: {
    usersTextPrefix: "Ja som",
    usersTextSuffix: "usuaris!",
    shareButtonText: "Comparteix!🚀",
    shareMessage: "Hola! Et recomano la Xarxa d’Economia Veïnal 📍 Permet oferir i demanar Serveis i Objectes.\n👇\nhttps://trocalia.net/",
    xarxa: "Xarxa d’Economia Veïnal",
  },
  ENG: {
    usersTextPrefix: "We are already",
    usersTextSuffix: "users!",
    shareButtonText: "Share!🚀",
    shareMessage: "Hi! I recommend the Local Economy Network 📍 to offer and request Services and Items.\n👇\nhttps://trocalia.net/",
    xarxa: "Local Economy Network",
  },
};
const ShareButton = () => {
  const { language, serverAddress, token } = useContext(AppContext);
  const t = translations[language];
  const [numUsuarios, setNumUsuarios] = useState(null);

  const bounceAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    const fetchNumUsuarios = async () => {
      try {
        const response = await fetch(
          `${serverAddress}/api/v1/auxiliars/numUsuaris`
        );
        const data = await response.json();
        if (data.status === "OK" && data.data && data.data[0]) {
          setNumUsuarios(data.data[0].numUsuaris);
        }
      } catch (error) {
        // Manejo de errores
      }
    };

    fetchNumUsuarios();
  }, [serverAddress]);

  useEffect(() => {
    const startBounceAnimation = () => {
      Animated.sequence([
        Animated.timing(bounceAnim, {
          toValue: 1.15,
          duration: 600,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(bounceAnim, {
          toValue: 1,
          duration: 300,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
      ]).start();
    };

    const interval = setInterval(() => {
      startBounceAnimation();
    }, 6000);

    return () => clearInterval(interval);
  }, [bounceAnim]);

  const handleShare = async () => {
    try {
      if (Platform.OS !== "web") {
        try {
          await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
        } catch (hapticError) {
         // console.log("Haptic feedback no disponible o ha fallado", hapticError);
        }
      }

      const result = await Share.share({
        message: t.shareMessage,
      });

      if (result.action === Share.sharedAction) {
        try {
          await sendActionToServer("haCompartitLaApp");
        } catch (serverError) {
          //console.log("Error al enviar la acción al servidor", serverError);
        }
      }
    } catch (error) {
      //console.log("Error al compartir", error);
    }
  };

  const sendActionToServer = async (action) => {
    try {
      const response = await fetch(`${serverAddress}/api/v1/perfils/actuacions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: action,
        }),
      });

      if (!response.ok) {
        //console.error(`Error al enviar la acción al servidor: ${action}`);
      }
    } catch (error) {
      //console.error(`Error al registrar la acción: ${action}`, error);
    }
  };

  return (
    <TouchableOpacity style={styles.shareButtonContainer} onPress={handleShare}>
      {numUsuarios !== null ? (
        <Text style={styles.shareUsersText}>
          {t.usersTextPrefix} <Text style={styles.boldText}>{numUsuarios}</Text> {t.usersTextSuffix}
        </Text>
      ) : (
        <Text style={styles.shareUsersText}>{t.xarxa}</Text>
      )}
      <Animated.Text style={[styles.shareButtonText, { transform: [{ scale: bounceAnim }] }]}>
        {t.shareButtonText}
      </Animated.Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  shareButtonContainer: {
    backgroundColor: Colors.white,
    borderColor: "green",
    borderWidth: 2,
    borderRadius: 10,
    paddingVertical: 2,
    paddingHorizontal: 8,
    alignItems: "center",
    justifyContent: "center",
  },
  shareUsersText: {
    color: Colors.black,
    fontSize: 10,
    marginBottom: 2,
    textAlign: "center",
  },
  shareButtonText: {
    color: "green",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
});

export default ShareButton;
