import React, { useState, useEffect, useContext } from "react";
import {
  View,
  ScrollView,
  StyleSheet,
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import { Buscar } from "../components/SearchBar";
import { useNavigation } from "@react-navigation/native";
import { AppContext } from "../../AppContext";
import SelectorServiciosObjetos from "../selectables/SelectorDeServiciosObjetos";
import VerticalList from "../selectables/VerticalList";
import { useIsFocused } from "@react-navigation/native";

const SearchScreen = ({ route }) => {
  const navigation = useNavigation();
  const { language } = useContext(AppContext);
  const translations = {
    ESP: { back: "Volver atrás" },
    CAT: { back: "Tornar enrere" },
    ENG: { back: "Go back" },
  };

  const handleBackPress = () => {
    navigation.goBack();
  };
  const [selectedOption, setSelectedOption] = useState("servicios");
  const [idCat, setIdCat] = useState(null);
  const [height, setHeight] = useState(0);

  // Establecer y actualizar el alto de la ventana para web
  useEffect(() => {
    if (Platform.OS === "web") {
      setHeight(window.innerHeight);
      window.addEventListener("resize", updateHeight);
      return () => window.removeEventListener("resize", updateHeight);
    }
  }, []);

  useEffect(() => {
    // Extraer id_cat del route si está disponible
    if (route.params?.id_cat) {
      setIdCat(route.params.id_cat);
      setSelectedOption("servicios"); // Asegurar que se muestren servicios
    }
  }, [route.params?.id_cat]);

  // Función para actualizar el alto de la ventana
  const updateHeight = () => {
    setHeight(window.innerHeight);
  };

  // Función para manejar el cambio de opción en el selector
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option === "objetos") {
      // Restablecer idCat a null cuando se seleccionen objetos
      setIdCat(null);
    }
  };

  return (
    <View style={{ flex: 1 }}>
      {Platform.OS === "web" && (
        <View style={styles.headerContainer}>
          <TouchableOpacity onPress={handleBackPress} style={styles.backButton}>
            <Text style={styles.backButtonText}>
              {translations[language].back}
            </Text>
          </TouchableOpacity>
          <Text style={styles.headerTitle}></Text>
        </View>
      )}
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          <View style={styles.centerContainer}>
            <Buscar />
            <SelectorServiciosObjetos onSelect={handleOptionSelect} />
          </View>
          <ScrollView style={Platform.OS === "web" ? { height: height } : {}}>
            <View style={styles.scrollViewContainer}>
              <VerticalList option={selectedOption} idCat={idCat} />
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
};

// Variables para dimensiones y estilos
const { width } = Dimensions.get("window");
const searchBarWidth = 340;

// Definición de estilos
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    alignItems: "center",
    paddingTop: Platform.select({
      ios: 50,
      android: 34,
      web: 36,
      default: 30, // valor por defecto para cualquier otra plataforma
    }),
  },
  innerContainer: {
    width: "92%",
  },
  centerContainer: {
    width: searchBarWidth,
    alignSelf: "center",
  },
  scrollViewContainer: {
    flexGrow: 1,
    marginTop: 30,
    paddingBottom: 120,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  backButton: {
    position: "absolute",
    left: 10,
  },
  backButtonText: {
    color: Colors.primary,
    fontSize: 16,
    textDecorationLine: "underline",
    ...Fonts.poppinsRegular16,
  },
  headerTitle: {
    ...Fonts.poppinsMedium16,
    color: Colors.black,
  },
});

export default SearchScreen;
