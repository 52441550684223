const imageMap = {
  '1': require('../img/icons/cat/1.png'),
  '3': require('../img/icons/cat/3.png'),
  '4': require('../img/icons/cat/4.png'),
  '5': require('../img/icons/cat/5.png'),
  '6': require('../img/icons/cat/6.png'),
  '7': require('../img/icons/cat/7.png'),
  '8': require('../img/icons/cat/8.png'),
  '9': require('../img/icons/cat/9.png'),
  '10': require('../img/icons/cat/10.png'),
  '11': require('../img/icons/cat/11.png'),
  '12': require('../img/icons/cat/12.png'),
  '13': require('../img/icons/cat/13.png'),

  '15': require('../img/icons/cat/15.png'),
  '16': require('../img/icons/cat/16.png'),
  '17': require('../img/icons/cat/17.png'),
  '18': require('../img/icons/cat/18.png'),
  '19': require('../img/icons/cat/19.png'),

  '21': require('../img/icons/cat/21.png'),
  '22': require('../img/icons/cat/22.png'),
  '23': require('../img/icons/cat/23.png'),
  '24': require('../img/icons/cat/24.png'),
  '25': require('../img/icons/cat/25.png'),
  '26': require('../img/icons/cat/26.png'),
  '27': require('../img/icons/cat/27.png'),
  '28': require('../img/icons/cat/28.png'),
  '29': require('../img/icons/cat/29.png'),
  '30': require('../img/icons/cat/30.png'),
  '31': require('../img/icons/cat/31.png'),
  '32': require('../img/icons/cat/32.png'),
  '33': require('../img/icons/cat/33.png'),
  '34': require('../img/icons/cat/34.png'),
  '35': require('../img/icons/cat/35.png'),
  '36': require('../img/icons/cat/36.png'),
  '37': require('../img/icons/cat/37.png'),
  '38': require('../img/icons/cat/38.png'),
  '39': require('../img/icons/cat/39.png'),
  '40': require('../img/icons/cat/40.png'),
  '41': require('../img/icons/cat/41.png'),
  '42': require('../img/icons/cat/42.png'),
  '43': require('../img/icons/cat/43.png'),
  '44': require('../img/icons/cat/44.png'),
  '45': require('../img/icons/cat/45.png'),
  '46': require('../img/icons/cat/46.png'),
  '47': require('../img/icons/cat/47.png'),
  '48': require('../img/icons/cat/48.png'),
  '49': require('../img/icons/cat/49.png'),
  '50': require('../img/icons/cat/50.png'),
  '51': require('../img/icons/cat/51.png'),
  '52': require('../img/icons/cat/52.png'),
  '53': require('../img/icons/cat/53.png'),
  '54': require('../img/icons/cat/54.png'),
  '55': require('../img/icons/cat/55.png'),
  '56': require('../img/icons/cat/56.png'),
  '57': require('../img/icons/cat/57.png'),
  '58': require('../img/icons/cat/58.png'),
  '59': require('../img/icons/cat/59.png'),
  '60': require('../img/icons/cat/60.png'),
  '61': require('../img/icons/cat/61.png'),
  '62': require('../img/icons/cat/62.png'),
  '63': require('../img/icons/cat/63.png'),
  '64': require('../img/icons/cat/64.png'),
  '65': require('../img/icons/cat/65.png'),
  '66': require('../img/icons/cat/66.png'),
  '67': require('../img/icons/cat/67.png'),
  '68': require('../img/icons/cat/68.png'),
  '69': require('../img/icons/cat/69.png'),
  '72': require('../img/icons/cat/72.png'),

  '70': require('../img/icons/cat/70.png'),
  '71': require('../img/icons/cat/71.png'),
  '73': require('../img/icons/cat/73.png'),
  '74': require('../img/icons/cat/74.png'),
  '75': require('../img/icons/cat/75.png'),
  '76': require('../img/icons/cat/76.png'),
  '77': require('../img/icons/cat/77.png'),
  '78': require('../img/icons/cat/78.png'),
  '79': require('../img/icons/cat/79.png'),

  '80': require('../img/icons/cat/80.png'),
  '81': require('../img/icons/cat/81.png'),
  '82': require('../img/icons/cat/82.png'),
  '83': require('../img/icons/cat/83.png'),
  '85': require('../img/icons/cat/85.png'),
  '86': require('../img/icons/cat/86.png'),
  '87': require('../img/icons/cat/87.png'),
  '88': require('../img/icons/cat/88.png'),
  '89': require('../img/icons/cat/89.png'),
  '90': require('../img/icons/cat/90.png'),
  
  '100': require('../img/icons/cat/100.png'),
  '101': require('../img/icons/cat/101.png'),
  '102': require('../img/icons/cat/102.png'),
  '103': require('../img/icons/cat/103.png'),
  '104': require('../img/icons/cat/104.png'),
  '105': require('../img/icons/cat/105.png'),
  '106': require('../img/icons/cat/106.png'),
  '107': require('../img/icons/cat/107.png'),
  '108': require('../img/icons/cat/108.png'),
  '109': require('../img/icons/cat/109.png'),
  
  '110': require('../img/icons/cat/110.png'),
  
  '150': require('../img/icons/cat/150.png'),
  '151': require('../img/icons/cat/151.png'),
  '152': require('../img/icons/cat/152.png'),
  '153': require('../img/icons/cat/153.png'),
  '154': require('../img/icons/cat/154.png'),
  '155': require('../img/icons/cat/155.png'),
  '156': require('../img/icons/cat/156.png'), 
  '158': require('../img/icons/cat/158.png'),
 
  '160': require('../img/icons/cat/160.png'),
  '161': require('../img/icons/cat/161.png'),
  '162': require('../img/icons/cat/162.png'),
  '163': require('../img/icons/cat/163.png'),
  '164': require('../img/icons/cat/164.png'),
  '165': require('../img/icons/cat/165.png'),
  '166': require('../img/icons/cat/166.png'),
  '167': require('../img/icons/cat/167.png'),
  '168': require('../img/icons/cat/168.png'),
  
  '170': require('../img/icons/cat/170.png'),
  '171': require('../img/icons/cat/171.png'),
  '172': require('../img/icons/cat/172.png'),
  '173': require('../img/icons/cat/173.png'),
  '174': require('../img/icons/cat/174.png'),
  '175': require('../img/icons/cat/175.png'),
  '176': require('../img/icons/cat/176.png'),
  '177': require('../img/icons/cat/177.png'),
  '178': require('../img/icons/cat/178.png'),
  '179': require('../img/icons/cat/179.png'),
  '180': require('../img/icons/cat/180.png'),
  '181': require('../img/icons/cat/181.png'),
  '182': require('../img/icons/cat/182.png'),
  '183': require('../img/icons/cat/183.png'),
  '184': require('../img/icons/cat/184.png'),
  '185': require('../img/icons/cat/185.png'),
  
  '187': require('../img/icons/cat/187.png'),
  '188': require('../img/icons/cat/188.png'),
  '189': require('../img/icons/cat/189.png'),
  '190': require('../img/icons/cat/190.png'),
  '191': require('../img/icons/cat/191.png'),
  '192': require('../img/icons/cat/192.png'),
  '193': require('../img/icons/cat/193.png'),
  '194': require('../img/icons/cat/194.png'),
  '195': require('../img/icons/cat/195.png'),
  '196': require('../img/icons/cat/196.png'),
  '197': require('../img/icons/cat/197.png'),
  '198': require('../img/icons/cat/198.png'),
  '199': require('../img/icons/cat/199.png'),
  '200': require('../img/icons/cat/200.png'),
 
  '202': require('../img/icons/cat/202.png'),
  '203': require('../img/icons/cat/203.png'),
  '204': require('../img/icons/cat/204.png'),
  '205': require('../img/icons/cat/205.png'),
  '206': require('../img/icons/cat/206.png'),
  '207': require('../img/icons/cat/207.png'),
  '208': require('../img/icons/cat/208.png'),
  '209': require('../img/icons/cat/209.png'),
  '210': require('../img/icons/cat/210.png'),
  '211': require('../img/icons/cat/211.png'),
  '212': require('../img/icons/cat/212.png'),
  '213': require('../img/icons/cat/213.png'),
  '214': require('../img/icons/cat/214.png'),
  '215': require('../img/icons/cat/215.png'),
  '216': require('../img/icons/cat/216.png'),
  '217': require('../img/icons/cat/217.png'),
  '218': require('../img/icons/cat/218.png'),
  '219': require('../img/icons/cat/219.png'),
  '220': require('../img/icons/cat/220.png'),

  '222': require('../img/icons/cat/222.png'),
  '223': require('../img/icons/cat/223.png'),
  
  '225': require('../img/icons/cat/225.png'),
  '226': require('../img/icons/cat/226.png'),
  '227': require('../img/icons/cat/227.png'),
  '228': require('../img/icons/cat/228.png'),
  '229': require('../img/icons/cat/229.png'),
  
  '231': require('../img/icons/cat/231.png'),
  '232': require('../img/icons/cat/232.png'),
  '233': require('../img/icons/cat/233.png'),
  '234': require('../img/icons/cat/234.png'),
  '235': require('../img/icons/cat/235.png'),
  '236': require('../img/icons/cat/236.png'),
  '237': require('../img/icons/cat/237.png'),
 
  '239': require('../img/icons/cat/239.png'),
  '240': require('../img/icons/cat/240.png'),
  '241': require('../img/icons/cat/241.png'),
  '242': require('../img/icons/cat/242.png'),
  '243': require('../img/icons/cat/243.png'),
  '244': require('../img/icons/cat/244.png'),
  '245': require('../img/icons/cat/245.png'),
  '246': require('../img/icons/cat/246.png'),
  '247': require('../img/icons/cat/247.png'),
  '248': require('../img/icons/cat/248.png'),
  '249': require('../img/icons/cat/249.png'),
  '250': require('../img/icons/cat/250.png'),
  '251': require('../img/icons/cat/251.png'),
  '252': require('../img/icons/cat/252.png'),
  '253': require('../img/icons/cat/253.png'),
  '254': require('../img/icons/cat/254.png'),
  '255': require('../img/icons/cat/255.png'),
  '256': require('../img/icons/cat/256.png'),
  '257': require('../img/icons/cat/257.png'),
  '258': require('../img/icons/cat/258.png'),
  '259': require('../img/icons/cat/259.png'),
  '260': require('../img/icons/cat/260.png'),
  '261': require('../img/icons/cat/261.png'),
  '262': require('../img/icons/cat/262.png'),
  '263': require('../img/icons/cat/263.png'),
  '264': require('../img/icons/cat/264.png'),
  '265': require('../img/icons/cat/265.png'),
  '266': require('../img/icons/cat/266.png'),
  '267': require('../img/icons/cat/267.png'),
  '268': require('../img/icons/cat/268.png'),
  '269': require('../img/icons/cat/269.png'),
  '270': require('../img/icons/cat/270.png'),
  '271': require('../img/icons/cat/271.png'),
  '272': require('../img/icons/cat/272.png'),
  '273': require('../img/icons/cat/273.png'),
  '274': require('../img/icons/cat/274.png'),
  '275': require('../img/icons/cat/275.png'),
  '276': require('../img/icons/cat/276.png'),
  '277': require('../img/icons/cat/277.png'),
  '278': require('../img/icons/cat/278.png'),

  '280': require('../img/icons/cat/280.png'),
  '281': require('../img/icons/cat/281.png'),
  '282': require('../img/icons/cat/282.png'),
  '283': require('../img/icons/cat/283.png'),
  '284': require('../img/icons/cat/284.png'),
  '285': require('../img/icons/cat/285.png'),
  '286': require('../img/icons/cat/286.png'),
  '287': require('../img/icons/cat/287.png'),
  '288': require('../img/icons/cat/288.png'),
  '289': require('../img/icons/cat/289.png'),
  '290': require('../img/icons/cat/290.png'),
  '291': require('../img/icons/cat/291.png'),
  '292': require('../img/icons/cat/292.png'),
  '293': require('../img/icons/cat/293.png'),
  '294': require('../img/icons/cat/294.png'),
  '295': require('../img/icons/cat/295.png'),
  '296': require('../img/icons/cat/296.png'),
  '297': require('../img/icons/cat/297.png'),
  '298': require('../img/icons/cat/298.png'),
  '299': require('../img/icons/cat/299.png'),
  '300': require('../img/icons/cat/300.png'),
  '301': require('../img/icons/cat/301.png'),
  '302': require('../img/icons/cat/302.png'),
  '303': require('../img/icons/cat/303.png'),
  '304': require('../img/icons/cat/304.png'),
  '305': require('../img/icons/cat/305.png'),
  
  '307': require('../img/icons/cat/307.png'),
  '308': require('../img/icons/cat/308.png'),
  
  '310': require('../img/icons/cat/310.png'),
  '311': require('../img/icons/cat/311.png'),
  '312': require('../img/icons/cat/312.png'),
  '313': require('../img/icons/cat/313.png'),
  '314': require('../img/icons/cat/314.png'),
  '315': require('../img/icons/cat/315.png'),
  '316': require('../img/icons/cat/316.png'),
  '317': require('../img/icons/cat/317.png'),
  '318': require('../img/icons/cat/318.png'),
  '319': require('../img/icons/cat/319.png'),
  
  '321': require('../img/icons/cat/321.png'),
  '322': require('../img/icons/cat/322.png'),
  '323': require('../img/icons/cat/323.png'),
  '324': require('../img/icons/cat/324.png'),
  '325': require('../img/icons/cat/325.png'),
  '326': require('../img/icons/cat/326.png'),
  '327': require('../img/icons/cat/327.png'),
  '328': require('../img/icons/cat/328.png'),
  '329': require('../img/icons/cat/329.png'),
  '331': require('../img/icons/cat/331.png'),
  '332': require('../img/icons/cat/332.png'),
  '333': require('../img/icons/cat/333.png'),
  '334': require('../img/icons/cat/334.png'),
  '335': require('../img/icons/cat/335.png'),
  '336': require('../img/icons/cat/336.png'),
  '337': require('../img/icons/cat/337.png'),
  '338': require('../img/icons/cat/338.png'),
  '339': require('../img/icons/cat/339.png'),
  '340': require('../img/icons/cat/340.png'),
  '341': require('../img/icons/cat/341.png'),
  '342': require('../img/icons/cat/342.png'),
  '343': require('../img/icons/cat/343.png'),
  '344': require('../img/icons/cat/344.png'),
  '345': require('../img/icons/cat/345.png'),
  '346': require('../img/icons/cat/346.png'),
  '347': require('../img/icons/cat/347.png'),
  '348': require('../img/icons/cat/348.png'),
  '349': require('../img/icons/cat/349.png'),
  '350': require('../img/icons/cat/350.png'),
  '351': require('../img/icons/cat/351.png'),
  '352': require('../img/icons/cat/352.png'),
  '353': require('../img/icons/cat/353.png'),
  '354': require('../img/icons/cat/354.png'),
  '355': require('../img/icons/cat/355.png'),
  '356': require('../img/icons/cat/356.png'),
  '357': require('../img/icons/cat/357.png'),
  '358': require('../img/icons/cat/358.png'),
  '359': require('../img/icons/cat/359.png'),
  '360': require('../img/icons/cat/360.png'),
  '361': require('../img/icons/cat/361.png'),
  '362': require('../img/icons/cat/362.png'),
  '363': require('../img/icons/cat/363.png'),
  '364': require('../img/icons/cat/364.png'),
  '365': require('../img/icons/cat/365.png'),
  '366': require('../img/icons/cat/366.png'),
  '367': require('../img/icons/cat/367.png'),
  '368': require('../img/icons/cat/368.png'),
  '369': require('../img/icons/cat/369.png'),
  '370': require('../img/icons/cat/370.png'),
  '371': require('../img/icons/cat/371.png'),
  '372': require('../img/icons/cat/372.png'),
  '373': require('../img/icons/cat/373.png'),
  '374': require('../img/icons/cat/374.png'),
  '375': require('../img/icons/cat/375.png'),
  '376': require('../img/icons/cat/376.png'),
  '377': require('../img/icons/cat/377.png'),
  '378': require('../img/icons/cat/378.png'),
  '379': require('../img/icons/cat/379.png'),
  '380': require('../img/icons/cat/380.png'),
  '381': require('../img/icons/cat/381.png'),
  '382': require('../img/icons/cat/382.png'),
  '383': require('../img/icons/cat/383.png'),
  '384': require('../img/icons/cat/384.png'),
  '385': require('../img/icons/cat/385.png'),
  '386': require('../img/icons/cat/386.png'),
  '387': require('../img/icons/cat/387.png'),
  '388': require('../img/icons/cat/388.png'),
  '389': require('../img/icons/cat/389.png'),
  '390': require('../img/icons/cat/390.png'),
  '391': require('../img/icons/cat/391.png'),
  '392': require('../img/icons/cat/392.png'),
  '394': require('../img/icons/cat/394.png'),
  '395': require('../img/icons/cat/395.png'),
  '396': require('../img/icons/cat/396.png'),
  '397': require('../img/icons/cat/397.png'),
  '398': require('../img/icons/cat/398.png'),
  '399': require('../img/icons/cat/399.png'),
  '400': require('../img/icons/cat/400.png'),
  '401': require('../img/icons/cat/401.png'),
  '402': require('../img/icons/cat/402.png'),
  
  '404': require('../img/icons/cat/404.png'),
  '405': require('../img/icons/cat/405.png'),
  
  '407': require('../img/icons/cat/407.png'),
  '408': require('../img/icons/cat/408.png'),
  '409': require('../img/icons/cat/409.png'),
  '411': require('../img/icons/cat/411.png'),
  '412': require('../img/icons/cat/412.png'),
  '414': require('../img/icons/cat/414.png'),
  '415': require('../img/icons/cat/415.png'),
  '416': require('../img/icons/cat/416.png'),
  '417': require('../img/icons/cat/417.png'),
  '419': require('../img/icons/cat/419.png'),
  '420': require('../img/icons/cat/420.png'),
  '421': require('../img/icons/cat/421.png'),
  '422': require('../img/icons/cat/422.png'),
  '423': require('../img/icons/cat/423.png'),
  '424': require('../img/icons/cat/424.png'),
  '425': require('../img/icons/cat/425.png'),
  '426': require('../img/icons/cat/426.png'),
  '427': require('../img/icons/cat/427.png'),
  '428': require('../img/icons/cat/428.png'),
  '430': require('../img/icons/cat/430.png'),
  '432': require('../img/icons/cat/432.png'),
  '433': require('../img/icons/cat/433.png'),
  '434': require('../img/icons/cat/434.png'),
  '435': require('../img/icons/cat/435.png'),
  '436': require('../img/icons/cat/436.png'),
  '437': require('../img/icons/cat/437.png'),
  '438': require('../img/icons/cat/438.png'),
  '439': require('../img/icons/cat/439.png'),
  '440': require('../img/icons/cat/440.png'),
 
  '442': require('../img/icons/cat/442.png'),
  '443': require('../img/icons/cat/443.png'),
  '444': require('../img/icons/cat/444.png'),
  '445': require('../img/icons/cat/445.png'),
  '446': require('../img/icons/cat/446.png'),
  '447': require('../img/icons/cat/447.png'),
  '448': require('../img/icons/cat/448.png'),
  '449': require('../img/icons/cat/449.png'),
  '450': require('../img/icons/cat/450.png'),
  '451': require('../img/icons/cat/451.png'),
  '452': require('../img/icons/cat/452.png'),
  '453': require('../img/icons/cat/453.png'),
  '454': require('../img/icons/cat/454.png'),
  '455': require('../img/icons/cat/455.png'),
  '456': require('../img/icons/cat/456.png'),
  '457': require('../img/icons/cat/457.png'),
  '458': require('../img/icons/cat/458.png'),
  '459': require('../img/icons/cat/459.png'),
  '460': require('../img/icons/cat/460.png'),
  '461': require('../img/icons/cat/461.png'),
  '462': require('../img/icons/cat/462.png'),
  '463': require('../img/icons/cat/463.png'),
  '464': require('../img/icons/cat/464.png'),
  '465': require('../img/icons/cat/465.png'),
  '466': require('../img/icons/cat/466.png'),
  '467': require('../img/icons/cat/467.png'),
  '468': require('../img/icons/cat/468.png'),
  '469': require('../img/icons/cat/469.png'),
  '470': require('../img/icons/cat/470.png'),
  
  '472': require('../img/icons/cat/472.png'),
  '473': require('../img/icons/cat/473.png'),
  '474': require('../img/icons/cat/474.png'),
  '475': require('../img/icons/cat/475.png'),
  '476': require('../img/icons/cat/476.png'),
  '477': require('../img/icons/cat/477.png'),
  
  '479': require('../img/icons/cat/479.png'),
  '480': require('../img/icons/cat/480.png'),
  '481': require('../img/icons/cat/481.png'),
  '482': require('../img/icons/cat/482.png'),
  
  '484': require('../img/icons/cat/484.png'),
  '485': require('../img/icons/cat/485.png'),
  '486': require('../img/icons/cat/486.png'),
  '487': require('../img/icons/cat/487.png'),
  '488': require('../img/icons/cat/488.png'),
  '489': require('../img/icons/cat/489.png'),
  '490': require('../img/icons/cat/490.png'),
  '491': require('../img/icons/cat/491.png'),
  '492': require('../img/icons/cat/492.png'),
  '493': require('../img/icons/cat/493.png'),
  '494': require('../img/icons/cat/494.png'),
  '495': require('../img/icons/cat/495.png'),
  '496': require('../img/icons/cat/496.png'),
  '497': require('../img/icons/cat/497.png'),
  '498': require('../img/icons/cat/498.png'),
  '499': require('../img/icons/cat/499.png'),
  '500': require('../img/icons/cat/500.png'),
  '501': require('../img/icons/cat/501.png'),
  '502': require('../img/icons/cat/502.png'),
  '503': require('../img/icons/cat/503.png'),
  '504': require('../img/icons/cat/504.png'),
  '505': require('../img/icons/cat/505.png'),
  '506': require('../img/icons/cat/506.png'),
  '507': require('../img/icons/cat/507.png'),
  '509': require('../img/icons/cat/509.png'),
  '510': require('../img/icons/cat/510.png'),
  '511': require('../img/icons/cat/511.png'),
  '512': require('../img/icons/cat/512.png'),
  '513': require('../img/icons/cat/513.png'),
  '514': require('../img/icons/cat/514.png'),
  '515': require('../img/icons/cat/515.png'),
  '516': require('../img/icons/cat/516.png'),
  '517': require('../img/icons/cat/517.png'),
  '518': require('../img/icons/cat/518.png'),
  '519': require('../img/icons/cat/519.png'),
 
  '521': require('../img/icons/cat/521.png'),
  '522': require('../img/icons/cat/522.png'),
  '523': require('../img/icons/cat/523.png'),
  '524': require('../img/icons/cat/524.png'),
  '525': require('../img/icons/cat/525.png'),
  '526': require('../img/icons/cat/526.png'),
  '527': require('../img/icons/cat/527.png'),

};
export default imageMap;
