import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
  ImageBackground,
  Image,
  Modal,
  Alert
} from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import backgroundImg from "../img/icons/publi.png";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import Options from "./Options";
import { AppState } from "react-native";
import * as Haptic from 'expo-haptics';


const images = [
  require("../img/autopubli/1.webp"),
  require("../img/autopubli/2.webp"),
  require("../img/autopubli/3.webp"),
];

const translate = {
  ESP: {
    message: "Impulsa tu negocio en [zona]: ",
    action: "Publicítate aquí",
    subject: "Anuncio en",
    whatsappMessage:
      "Hola, he visto tu publicidad en Trocalia y me gustaría obtener más información ",
    publi: "Publicidad",
    alertwhatsapp: "WhatsApp no está instalado o el enlace no es soportado",
    alertcorreo: "No se puede abrir el cliente de correo"
  },
  CAT: {
    message: "Impulsa el teu negoci a [zona]: ",
    action: "Publicita't aquí",
    subject: "Anunci a",
    whatsappMessage:
      "Hola, he vist la teva publicitat a Trocalia i m'agradaria obtenir més informació",
    publi: "Publicitat",
    alertwhatsapp: "WhatsApp no està instal·lat o l'enllaç no és compatible",
    alertcorreo: "No es pot obrir el client de correu"
  },
  ENG: {
    message: "Boost your business in [zona]: ",
    action: "Advertise here",
    subject: "Ad in",
    whatsappMessage:
      "Hi, I've seen your ad on Trocalia and I'd like to get more information",
    publi: "Advertising",
    alertwhatsapp: "WhatsApp is not installed or the link is not supported",
    alertcorreo: "Could not open mail client"
  },
};

const Publi = ({ formatAnunci, id_subcat, externalContainerStyle }) => {
  const { serverAddress, userData, language, token } = useContext(AppContext);
  const [anuncio, setAnuncio] = useState(null);
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = useState(true);
  const isFirstRender = useRef(true);
  const [appState, setAppState] = useState(AppState.currentState);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (formatAnunci === "Q") {
      setSelectedImage(images[Math.floor(Math.random() * images.length)]);
    }
  }, [formatAnunci]);

  const fetchAnuncio = useCallback(async () => {
    if (!token) {
      return;
    }

    const requestBody = JSON.stringify({
      actuacio: "DA",
      formatAnunci: formatAnunci,
      ...(id_subcat ? { id_subcat: id_subcat } : {}),
    });

    const requestUrl = `${serverAddress}/api/v1/perfils/actuacions`;

    try {
      const response = await fetch(requestUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: requestBody,
      });

      const data = await response.json();
      if (data && data.status === "OK") {
        if (Array.isArray(data.data) && data.data.length > 0) {
          setAnuncio(data.data[0]);
        } else {
          setAnuncio(null);
        }
      } else {
        setAnuncio(null);
      }
    } catch (error) {
      setAnuncio(null);
    } finally {
      setIsLoading(false);
    }
  }, [serverAddress, token, formatAnunci, id_subcat]);

  useEffect(() => {
    if (token) {
      fetchAnuncio();
    } else {
      setAnuncio(null);
    }
  }, [fetchAnuncio, token]);

  useFocusEffect(
    useCallback(() => {
      if (!isFirstRender.current) {
        fetchAnuncio();
      }
      isFirstRender.current = false;
    }, [fetchAnuncio])
  );

  useEffect(() => {
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (nextAppState === "active") {
        fetchAnuncio();
      }
    });

    return () => {
      subscription.remove();
    };
  }, [fetchAnuncio]);

  const openWhatsApp = async (number, message) => {
    let url = 'whatsapp://send?text=' + encodeURIComponent(message) + '&phone=' + number;
    try {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        await Linking.openURL(url);
      } else {
        alert(translate[language].alertwhatsapp);
      }
    } catch (error) {
      alert(translate[language].alertwhatsapp);
    }
  };

  const handlePress = async () => {
    // Añadir la respuesta háptica al pulsar el botón de publicidad
    try {
      if (Platform.OS !== 'web') {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      }
    } catch (error) {
      //console.log("Haptic feedback no está disponible o ha fallado", error);
    }
  
    if (!anuncio) {
      const url = "https://trocalia.net/anunciants";
      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          Linking.openURL(url);
        }
      });
    } else {
      const actuacioUrl = `${serverAddress}/api/v1/perfils/actuacions`;
      const actuacioBody = JSON.stringify({
        actuacio: "AP",
        id_anunci: anuncio.id_anunci,
        id_anunciant: anuncio.id_anunciant,
      });

      try {
        await fetch(actuacioUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: actuacioBody,
        });

        if (anuncio.linkAnunci) {
          await Linking.openURL(`http://${anuncio.linkAnunci}`);
        } else if (anuncio.telefonoAnunci) {
          const phoneNumber = anuncio.telefonoAnunci.startsWith('34') ? anuncio.telefonoAnunci : `34${anuncio.telefonoAnunci}`;
          let whatsappUrl;
          if (Platform.OS === 'ios') {
            whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=${encodeURIComponent(translate[language].whatsappMessage)}`;
          } else {
            whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(translate[language].whatsappMessage)}`;
          }
          try {
            await Linking.openURL(whatsappUrl);
          } catch (error) {
            alert(translate[language].alertwhatsapp);
          }
        } else if (anuncio.usuariTrocalia) {
          navigation.navigate("ProfileOther", {
            userId: anuncio.usuariTrocalia,
          });
        } else if (anuncio.mailAnunci) {
          const mailUrl = `mailto:${anuncio.mailAnunci}`;
          try {
            await Linking.openURL(mailUrl);
          } catch (error) {
            alert(translate[language].alertcorreo);
          }
        }
      } catch (error) {
        // Handle error
      }
    }
  };

  if (!userData) {
    return <Text style={styles.loadingText}></Text>;
  }

  const message = translate[language].message.replace(
    "[zona]",
    userData.data[0].zona
  );

  const imageBackgroundStyle = {
    width: formatAnunci === "Q" ? 227 : 340,
    height: formatAnunci === "Q" ? 227 : 80,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
    backgroundColor: anuncio ? "transparent" : Colors.primary,
    overflow: "hidden",
  };

  if (isLoading) {
    return (
      <View
        style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
      />
    );
  }

  const internalContainerStyle = {
    justifyContent: "center",
    alignItems: "center",
    marginRight: formatAnunci === "Q" ? 15 : 0,
    marginTop: formatAnunci === "Q" ? 0 : 12,
    marginBottom: formatAnunci === "Q" ? 8 : 4,
  };

  const combinedContainerStyle = {
    ...internalContainerStyle,
    ...externalContainerStyle,
  };

  return (
    <View style={combinedContainerStyle}>
      <TouchableOpacity style={styles.touchableStyle} onPress={handlePress}>
        {selectedImage && formatAnunci === "Q" ? (
          <ImageBackground
            source={selectedImage}
            style={imageBackgroundStyle}
            imageStyle={{ borderRadius: 16, opacity: anuncio ? 0 : 1 }}
          >
            <View style={anuncio ? styles.hidden : styles.overlay} />
            {anuncio ? (
              <View style={styles.imageContainer}>
                <Image
                  source={{ uri: `${serverAddress}/anuncis/${anuncio.fotoAnunci}` }}
                  style={styles.image}
                />
                <View style={styles.adLabelContainer}>
                  <Text style={styles.adLabel}>{translate[language].publi}</Text>
                </View>
              </View>
            ) : (
              <>
                <View style={styles.messageContainer}>
                  <Text style={styles.messageStyle}>
                    {translate[language].message.replace(
                      "[zona]",
                      userData.data[0].zona
                    )}
                  </Text>
                </View>
                <Text style={styles.actionStyle}>
                  {translate[language].action}
                </Text>
              </>
            )}
          </ImageBackground>
        ) : (
          <View style={imageBackgroundStyle}>
            {anuncio ? (
              <View style={styles.imageContainer}>
                <Image
                  source={{ uri: `${serverAddress}/anuncis/${anuncio.fotoAnunci}` }}
                  style={styles.image}
                />
                <View style={styles.adLabelContainer}>
                  <Text style={styles.adLabel}>{translate[language].publi}</Text>
                </View>
              </View>
            ) : (
              <>
                <Text style={styles.messageStyle}>
                  {translate[language].message.replace(
                    "[zona]",
                    userData.data[0].zona
                  )}
                </Text>
                <Text style={styles.actionStyle}>
                  {translate[language].action}
                </Text>
              </>
            )}
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  touchableStyle: {
    borderWidth: 1 / 2,
    borderColor: Colors.grayLight,
    borderRadius: 16,
    width: "100%",
  },
  messageContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: 5,
    paddingHorizontal: 5,
    paddingVertical: 2,
    margin: 6,
    marginTop: 34,
  },
  messageStyle: {
    ...Fonts.poppinsRegular14,
    color: "white",
    textAlign: "center",
  },
  actionStyle: {
    ...Fonts.poppinsMedium16,
    color: "white",
    textAlign: "center",
    paddingHorizontal: 5,
    paddingVertical: 2,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    borderRadius: 5,
    overflow: "hidden",
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: 16,
    overflow: 'hidden'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  adLabelContainer: {
    position: 'absolute',
    top: 1.5,
    right: 3,
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: 8,
    overflow: 'hidden'
  },
  adLabel: {
    color: 'white',
    padding: 2,
    fontSize: 8,
    fontWeight: 'bold',
  },
  hidden: {
    display: 'none',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0,1)',
    borderRadius: 16,
  },
  modalView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  imageBackground: {
    width: 340,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,
    overflow: "hidden",
  },
  defaultAd: {
    width: 340,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
  },
  touchableStyle: {
    borderWidth: 1 / 2,
    borderColor: Colors.grayLight,
    borderRadius: 16,
  },
  action: {
    ...Fonts.poppinsMedium14,
    color: Colors.primary,
    textAlign: "center",
    paddingHorizontal: 5,
    paddingVertical: 2,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  message: {
    ...Fonts.poppinsRegular14,
    color: Colors.black,
    textAlign: "center",
    marginBottom: 4,
    backgroundColor: "rgba(255, 255, 255, 0.35)",
  },
  defaultText: {},
});

export default Publi;
