import React, { useState, useContext } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  TextInput,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
  Alert,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import * as SecureStore from "expo-secure-store";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";

const NeedPostal = ({
  isVisible,
  onClose,
  onUsePostalCode,
  onUseRandomPostalCode,
}) => {
  const { setToken, eraseUserData, language, serverAddress } =
    useContext(AppContext);
  const navigation = useNavigation();
  const [postalCode, setPostalCode] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(false);

  const translations = {
    ESP: {
      description:
        "Si quieres entrar como invitado en tu zona, añade tu código postal.",
      placeholder: "Código postal",
      inMyZone: "Invitado en mi zona",
      randomZone: "Invitado en código postal aleatorio",
      postalCodeFound:
        "¡Confirmado! Trocalia está disponible en tu zona. Puedes acceder como invitado.",
      postalCodeNotFound:
        "En este código postal aún no trabajamos, si nos escribes a suport@trocalia.net lo añadiremos lo más rápido posible.",
      postalCodeValidation: "El código postal debe ser de 5 dígitos numéricos.",
      validationError: "Error al validar el código postal.",
    },
    CAT: {
      description:
        "Si vols entrar com a convidat a la teva zona, afegeix el teu codi postal.",
      placeholder: "Codi postal",
      inMyZone: "Convidat a la meva zona",
      randomZone: "Convidat a codi postal aleatori",
      postalCodeFound:
        "Confirmat! Trocalia està disponible a la teva zona. Pots accedir com a convidat.",
      postalCodeNotFound:
        "En aquest codi postal encara no hi treballem, si ens escrius a suport@trocalia.net l'afegirem el més ràpid possible.",
      postalCodeValidation: "El codi postal ha de ser de 5 dígits numèrics.",
      validationError: "Error al validar el codi postal.",
    },
    ENG: {
      description:
        "If you want to enter as a guest in your area, add your postal code.",
      placeholder: "Postal code",
      inMyZone: "Guest in my zone",
      randomZone: "Guest to random postal code",
      postalCodeFound:
        "Confirmed! Trocalia is available in your area. You can access as a guest.",
      postalCodeNotFound:
        "In this postal code we do not work yet, if you write to us at suport@trocalia.net we will add it as soon as possible.",
      postalCodeValidation: "The postal code must be 5 numeric digits.",
      validationError: "Error validating the postal code.",
    },
  };

  const handlePostalCodeInput = (code) => {
    setPostalCode(code);
    if (code.length === 5) {
      validatePostalCode(code);
    } else {
      // Resetear el estado de validación y errores si el código no tiene 5 dígitos
      setIsPostalCodeValid(false);
      setPostalCodeError("");
    }
  };

  const validatePostalCode = async (code) => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/auxiliars/zones/${code}`
      );
      const data = await response.json();

      if (data.status === "OK") {
        setIsPostalCodeValid(true);
        setPostalCodeError(translations[language].postalCodeFound);
      } else {
        setIsPostalCodeValid(false);
        setPostalCodeError(translations[language].postalCodeNotFound);
      }
    } catch (error) {
      //console.error("Error al validar el código postal:", error);
      setIsPostalCodeValid(false);
      setPostalCodeError(translations[language].validationError);
    }
  };

  const handleInMyZonePress = () => {
    if (postalCode.length === 5 && isPostalCodeValid) {
      onUsePostalCode(postalCode);
      onClose();
    } else {
      // Si el código no es válido o no tiene 5 dígitos, muestra un mensaje de error.
      setPostalCodeError(translations[language].postalCodeValidation);
    }
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.centeredView}>
          <TouchableWithoutFeedback>
            <View style={styles.modalView}>
              <Text style={styles.modalText}>
                {translations[language].description}
              </Text>
              <TextInput
                style={styles.input}
                onChangeText={handlePostalCodeInput}
                value={postalCode}
                placeholder={translations[language].placeholder}
                keyboardType="numeric"
                maxLength={5}
              />
              {postalCodeError ? (
                <Text
                  style={[
                    styles.errorText,
                    postalCodeError ===
                      translations[language].postalCodeFound &&
                      styles.successText,
                  ]}
                >
                  {postalCodeError}
                </Text>
              ) : null}
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    backgroundColor: isPostalCodeValid
                      ? Colors.primary
                      : Colors.grayMedium,
                  },
                ]}
                onPress={handleInMyZonePress}
                disabled={!isPostalCodeValid}
              >
                <Text style={styles.textStyle}>
                  {translations[language].inMyZone}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button,
                  {
                    backgroundColor: isPostalCodeValid
                      ? Colors.grayMedium
                      : Colors.primary,
                    marginTop: 10,
                  },
                ]}
                onPress={onUseRandomPostalCode}
              >
                <Text style={styles.textStyle}>
                  {translations[language].randomZone}
                </Text>
              </TouchableOpacity>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    color: Colors.grayDark,
    ...Fonts.poppinsMedium16,
  },
  input: {
    height: 40,
    marginVertical: 10,
    borderWidth: 1,
    padding: 10,
    width: "100%",
    borderRadius: 5,
    borderColor: Colors.grayMedium,
    ...Fonts.poppinsRegular16,
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    width: "100%",
    marginTop: 10,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    ...Fonts.poppinsRegular16,
  },
  errorText: {
    color: "red",
    marginTop: 5,
    ...Fonts.poppinsRegular14,
  },
  errorText: {
    color: "red",
    marginTop: 4,
    marginBottom: 4,
    ...Fonts.poppinsRegular14,
  },
  successText: {
    color: "green",
    marginTop: 4,
    marginBottom: 4,
    ...Fonts.poppinsRegular14,
  },
});

export default NeedPostal;
