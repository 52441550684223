import React, { useContext, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { AppContext } from "../../AppContext";
import { useNavigation } from "@react-navigation/native";
import Nodata from "./Nodata";
import profileImages from "../img/profileimage";
import { Colors, Fonts } from "../styles/styles";
import NeedRegister from "../selectables/NeedRegister.js"; // Importar el modal NeedRegister

const InfoTrocs = ({ supertrocs, language }) => {
  const { serverAddress } = useContext(AppContext);
  const { userData } = useContext(AppContext);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false); // Estado para controlar la visibilidad del modal
  const navigation = useNavigation();

  const translate = {
    ESP: {
      ofrece: "Ofrece",
      necesita: "Demanda",
      message: "Sin vecinos con doble Match, sube más Ofertas y Demandas",
      perfil: "Perfil",
    },
    CAT: {
      ofrece: "Ofereix",
      necesita: "Demanda",
      message: "Sense veïns amb doble Match, puja més Ofertes i Demandes",
      perfil: "Perfil",
    },
    ENG: {
      ofrece: "Offers",
      necesita: "Needs",
      message: "Without double Match users, try with more Offers and Needs",
      perfil: "Profile",
    },
  };

  const flatSupertrocs = supertrocs.reduce((acc, val) => acc.concat(val), []);
  const getImageSource = (imageUri) => {
    if (typeof imageUri === "string") {
      return { uri: imageUri };
    }
    return imageUri; // asumiendo que es el resultado de un require para imágenes locales
  };
  const isDataEmpty = (data) => {
    return data.every((supertroc) => !supertroc.id_usuari);
  };

  const handlePressNodata = () => {
    // Verifica si el usuario es un 'Guest'
    if (userData?.data[0]?.tipusUsuari === "Guest") {
      setIsNeedRegisterVisible(true); // Muestra el modal NeedRegister si el usuario es 'Guest'
    } else {
      navigation.navigate(translate[language].perfil); // Navega si el usuario no es 'Guest'
    }
  };

  if (!flatSupertrocs.length || isDataEmpty(flatSupertrocs)) {
    return (
      <>
        <View style={styles.centerContainer}>
          <Nodata
            message={translate[language].message}
            onNavigate={handlePressNodata} // Usa la función handlePressNodata
            styleType="default"
          />
        </View>
        {isNeedRegisterVisible && (
          <NeedRegister
            isVisible={isNeedRegisterVisible}
            onClose={() => setIsNeedRegisterVisible(false)}
          />
        )}
      </>
    );
  }

  return (
    <>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.scrollContainer}
      >
        {flatSupertrocs.map((supertroc, index) => {
          const userImageUri = supertroc.foto
            ? `${serverAddress}/fotos/${supertroc.foto}`
            : profileImages[supertroc.id_usuari % 10];

          const secondUserImageUri =
            userData &&
            userData.data &&
            userData.data.length > 0 &&
            userData.data[0].foto
              ? `${serverAddress}/fotos/${userData.data[0].foto}`
              : userData && userData.data && userData.data.length > 0
              ? profileImages[userData.data[0].id_usuari % 10]
              : null;

          return (
            <TouchableOpacity
              key={index}
              onPress={() =>
                navigation.navigate("ProfileOther", {
                  userId: supertroc.id_usuari,
                })
              }
            >
              <View style={styles.rectangleParent}>
                <View style={styles.centeredContent}>
                  <View style={styles.imageContainer}>
                    <Image
                      source={getImageSource(userImageUri)}
                      style={styles.imageStyle}
                    />
                    {secondUserImageUri && (
                      <Image
                        source={getImageSource(secondUserImageUri)}
                        style={[styles.imageStyle, styles.secondImageStyle]}
                      />
                    )}
                  </View>
                  <Text
                    style={styles.nombre}
                  >{`${supertroc.nomUsuari} ${supertroc.cognomUsuari}`}</Text>
                </View>
                <View style={styles.textContainer} numberOfLines={5}>
                  <Text style={styles.ofreceNecesita}>
                    <Text style={styles.decorativeText}>
                      {translate[language].ofrece}:{" "}
                    </Text>
                    {supertroc.ofereix.map((oferta, ofertaIndex) => (
                      <Text key={ofertaIndex} style={styles.titleText}>
                        {ofertaIndex > 0
                          ? `, ${oferta.titul_oferta}`
                          : oferta.titul_oferta}
                      </Text>
                    ))}
                  </Text>
                  <Text style={styles.ofreceNecesita}>
                    <Text style={styles.decorativeText}>
                      {translate[language].necesita}:{" "}
                    </Text>
                    {supertroc.demana.map((demanda, demandaIndex) => (
                      <Text key={demandaIndex} style={styles.titleText}>
                        {demandaIndex > 0
                          ? `, ${demanda.titul_demanda}`
                          : demanda.titul_demanda}
                      </Text>
                    ))}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
      {isNeedRegisterVisible && (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: Platform.OS === "web" ? 25 : 4,
    marginBottom: Platform.OS === "web" ? 25 : 4,
    alignSelf: "stretch",
  },
  rectangleParent: {
    flex: 1,
    width: 306,
    backgroundColor: Colors.primary,
    borderRadius: 16,
    marginRight: 16,
    padding: 10,
    marginBottom: 12,
    // shadowRadius: 4,
    // elevation: 5,
    alignSelf: "flex-start",
    marginTop: 30,
  },
  centeredContent: {
    alignItems: "center",
    alignSelf: "stretch",
  },
  imageContainer: {
    flexDirection: "row",
    position: "relative",
    justifyContent: "center",
    marginTop: -35,
  },
  imageStyle: {
    width: 40,
    height: 40,
    borderRadius: 25,
    backgroundColor: Colors.primary,
    borderWidth: 1,
    borderColor: Colors.grayMedium,
  },
  secondImageStyle: {
    marginLeft: -5,
  },
  nombre: {
    ...Fonts.dmSerifDisplayRegular18,
    color: Colors.white,
    marginTop: 4,
  },
  decorativeText: {
    ...Fonts.poppinsMedium14,
    color: Colors.white,
    textDecorationLine: "underline",
  },
  titleText: {
    ...Fonts.poppinsRegular14,
    color: Colors.darkGray,
  },

  textContainer: {
    flex: 1,
    alignItems: "flex-start",
    marginTop: 4,
  },
  ofreceNecesita: {
    flexDirection: "row",
    flexWrap: "wrap",
    ...Fonts.poppinsMedium14,
    color: Colors.white,
    textAlign: "left",
  },
  categoria: {
    ...Fonts.poppinsRegular14,
    color: Colors.darkGray,
  },
  scrollContainer: {
    flexDirection: "row",
    paddingHorizontal: 16,
  },
});

export default InfoTrocs;
