import { StyleSheet } from 'react-native';



export const Colors = {
  primary: '#85c7f2',
  secondary: '#FED766',
  black: '#000000',
  white: '#ffffff',
  grayLighter: '#f3f3f3',
  grayLight: '#ebebeb',
  grayMedium: '#c9c9c9',
  grayMediumDark: '#9B9B9B',
  grayDark: '#4b4b4b',
  inactive: '#808081',
  fondo: "#FFF6DD",
};

export const Fonts = StyleSheet.create({
  dmSerifDisplayRegular32: {
    fontFamily: 'DM-Serif-Display-Regular',
    fontSize: 32,
  },
  dmSerifDisplayRegular24: {
    fontFamily: 'DM-Serif-Display-Regular',
    fontSize: 24,
  },
  dmSerifDisplayRegular16: {
    fontFamily: 'DM-Serif-Display-Regular',
    fontSize: 16,
    lineHeight: 20,
    
  },
  dmSerifDisplayRegular18: {
    fontFamily: 'DM-Serif-Display-Regular',
    fontSize: 18,
    lineHeight: 20,
    
  },
  poppinsSemiBold24: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 24,
    lineHeight: 24 * 1.35,
  },
  poppinsMedium20: {
    fontFamily: 'Poppins-Medium',
    fontSize: 20,
    lineHeight: 20 * 1.85,
  },
  poppinsRegular16: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 16 * 1.51,
  },
  poppinsMedium16: {
    fontFamily: 'Poppins-Medium',
    fontSize: 16,
    lineHeight: 16 * 1.32,
  },
  poppinsMedium18: {
    fontFamily: 'Poppins-Medium',
    fontSize: 18,
    lineHeight: 16 * 1.32,
  },

  poppinsMedium12: {
    fontFamily: 'Poppins-Medium',
    fontSize: 12,
    lineHeight: 12 * 1.32,
  },
  poppinsMedium14: {
    fontFamily: 'Poppins-Medium',
    fontSize: 14,
    lineHeight: 14 * 1.22,
  },
  poppinsSemiBold16: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 16,
    lineHeight: 16 * 1.25,
  },
  poppinsSemiBold14: {
    fontFamily: 'Poppins-SemiBold',
    fontSize: 14,
    lineHeight: 14 * 1.25,
  },
  poppinsRegular14: {
    fontFamily: 'Poppins-Regular',
    fontSize: 14,
    lineHeight: 14 * 1.28,
  },
  poppinsRegular12: {
    fontFamily: 'Poppins-Regular',
    fontSize: 12,
    lineHeight: 14,
  },
  poppinsRegular10: {
    fontFamily: 'Poppins-Regular',
    fontSize: 10,
    lineHeight: 14,
  },
  
});
