import React, { createContext, useEffect, useState, useContext, useRef } from "react";
import { AppState, Platform } from "react-native";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import { AppContext } from "./AppContext"; // Importar tu AppContext

// Crear el contexto
export const ExpoPushContext = createContext();

// Definir los textos de los alerts y mensajes para cada idioma
const translations = {
  ESP: {
    alertTitle: "Permite que Trocalia te envíe notificaciones",
    alertMessage: "Toca configuración para actualizar tus preferencias.",
    noOption: "Ahora no",
    settingsOption: "Configuración",
    pushPermissionDenied: "Las notificaciones push están deshabilitadas. Activa los permisos en la configuración.",
    webPushMessage: "Las notificaciones push no son compatibles con aplicaciones web.",
    pushTokenError: "Error al obtener el push token.",
    successMessage: "Push token enviado correctamente.",
    apiError: "Error al enviar el push token al servidor.",
  },
  CAT: {
    alertTitle: "Permet que Trocalia t'envii notificacions",
    alertMessage: "Toca configuració per actualitzar les teves preferències.",
    noOption: "Ara no",
    settingsOption: "Configuració",
    pushPermissionDenied: "Les notificacions push estan desactivades. Activa els permisos a la configuració.",
    webPushMessage: "Les notificacions push no són compatibles amb aplicacions web.",
    pushTokenError: "Error en obtenir el push token.",
    successMessage: "Push token enviat correctament.",
    apiError: "Error en enviar el push token al servidor.",
  },
  ENG: {
    alertTitle: "Allow Trocalia to send you notifications",
    alertMessage: "Tap settings to update your preferences.",
    noOption: "Not now",
    settingsOption: "Settings",
    pushPermissionDenied: "Push notifications are disabled. Enable permissions in settings.",
    webPushMessage: "Push notifications are not supported on web applications.",
    pushTokenError: "Error obtaining push token.",
    successMessage: "Push token sent successfully.",
    apiError: "Error sending push token to the server.",
  },
};

export const ExpoPushProvider = ({ children }) => {
    const { token, serverAddress, language } = useContext(AppContext);
    const [expoPushToken, setExpoPushToken] = useState(null);
    const appState = useRef(AppState.currentState);
    const tokenRef = useRef(token); // Guardar el token en un ref
    const pushTokenRef = useRef(expoPushToken);
    
  
    // Sincroniza el token de contexto con el ref cada vez que cambia
    useEffect(() => {
      tokenRef.current = token;
    }, [token]);
  
    // Sincroniza el expoPushToken con el ref cada vez que cambia
    useEffect(() => {
      pushTokenRef.current = expoPushToken;
    }, [expoPushToken]);
  
    useEffect(() => {
      const subscription = AppState.addEventListener("change", handleAppStateChange);
  
      return () => {
        subscription.remove();
      };
    }, []);
  
    useEffect(() => {
      // Resetear los badges al iniciar la app
      const resetBadgeOnStart = async () => {
        try {
          await Notifications.setBadgeCountAsync(0);
          //console.log("Badge reseteado a 0 al iniciar la app");
        } catch (error) {
          //console.error("Error al resetear el badge al iniciar la app:", error);
        }
      };
    
      resetBadgeOnStart(); // Ejecuta el reset al iniciar
    
      // Suscribirse al cambio de estado de la app
      const subscription = AppState.addEventListener("change", handleAppStateChange);
    
      return () => {
        subscription.remove();
      };
    }, []);


    const handleAppStateChange = async (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        // La aplicación ha vuelto al estado activo.
        setTimeout(checkAndSendPushToken, 4000);
    
        // Resetear el conteo de los badges
        try {
          await Notifications.setBadgeCountAsync(0);
          // console.log("Badge reset a 0");
        } catch (error) {
          // console.error("Error al resetear el badge:", error);
        }
      }
      appState.current = nextAppState;
    };
    
  
    const checkAndSendPushToken = async () => {
      //console.log("Verificando permisos de notificación...");
      if (!Device.isDevice || Platform.OS === "web") {
        //console.log("No es un dispositivo físico o es una aplicación web. No se enviará el push token.");
        return;
      }
  
      const { status } = await Notifications.getPermissionsAsync();
      //console.log(`Estado de permisos de notificación: ${status}`);
      if (status === "granted") {
        //console.log("Permisos concedidos. Intentando obtener el push token...");
        await getPushTokenAndSendToServer();
      } else {
        //console.log("Permisos de notificación no concedidos.");
      }
    };
  
    const getPushTokenAndSendToServer = async () => {
      try {
        const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
        const pushToken = (await Notifications.getExpoPushTokenAsync({ projectId })).data;
       // console.log(`Push token obtenido: ${pushToken}`);
        setExpoPushToken(pushToken);
        pushTokenRef.current = pushToken;
  
        // Enviar el push token si ambos tokens están disponibles
        if (tokenRef.current && pushTokenRef.current) {
          //console.log("Enviando push token al servidor...");
          sendPushTokenToServer(pushTokenRef.current);
        } else {
          //console.log("Token de usuario o de push no disponible tras obtenerlo.");
        }
      } catch (error) {
        //console.error(translations[language]?.pushTokenError + " " + error.message || "Error al obtener el push token.");
      }
    };
  
    const sendPushTokenToServer = async (pushToken) => {
      if (!tokenRef.current || !pushToken) {
        //console.log("Token de usuario o de push no disponible en el momento de envío.");
        return;
      }
  
      const url = `${serverAddress}/api/v1/perfils/actuacions`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenRef.current}`,
          },
          body: JSON.stringify({
            actuacio: "GTP",
            expoPushToken: pushToken,
          }),
        });
  
        if (response.ok) {
          //console.log(translations[language]?.successMessage || "Push token enviado correctamente.");
        } else {
         // console.error(translations[language]?.apiError || "Error al enviar el push token al servidor.");
        }
      } catch (error) {
        //console.error(translations[language]?.apiError + " " + error.message || "Error al enviar el push token al servidor.");
      }
    };
  
    return (
      <ExpoPushContext.Provider value={{ expoPushToken }}>
        {children}
      </ExpoPushContext.Provider>
    );
  };
