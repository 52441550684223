// Importaciones necesarias
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { GiftedChat, Bubble, InputToolbar } from "react-native-gifted-chat";
import {
  View,
  Image,
  Platform,
  TouchableOpacity,
  Text,
  Modal,
  TouchableWithoutFeedback,
  StyleSheet,
  Keyboard,
  TextInput,
  ActivityIndicator,
} from "react-native";
import { AppContext } from "../../AppContext";
import SocketIOClient from "socket.io-client";
import { useNavigation } from "@react-navigation/native";
import { Fonts, Colors } from "../styles/styles.js";
import * as Haptic from "expo-haptics";
import profileImages from "../img/profileimage.js"; // Importar las imágenes de perfil

const translations = {
  ESP: {
    placeholder: "Escribe un mensaje...",
    back: "Volver",
    message: "Este chat está actualmente bloqueado.",
    desbloquear: "Desbloquear",
    atrás: "Atrás",
    send: "Enviar",
    today: "Hoy",
    block:
      "Tienes bloqueado a este usuario, no podréis mandaros mensajes hasta que lo desbloquees.",
    retry: "Volver a intentar",
    notSent: "No enviado. Toca para volver a intentar.",
  },
  CAT: {
    placeholder: "Escriu un missatge...",
    back: "Tornar",
    message: "Aquest xat està actualment bloquejat.",
    desbloquear: "Desbloquejar",
    atrás: "Enrere",
    send: "Enviar",
    today: "Avui",
    block:
      "Tens aquest usuari bloquejat, no us podreu enviar missatges fins que el desbloquegis.",
    retry: "Tornar a intentar",
    notSent: "No enviat. Toca per tornar a intentar.",
  },
  ENG: {
    placeholder: "Write a message...",
    message: "This chat is currently blocked.",
    back: "Back",
    desbloquear: "Unblock",
    atrás: "Back",
    send: "Send",
    today: "Today",
    block:
      "You have this user blocked, you won't be able to send messages until you unblock them.",
    retry: "Retry",
    notSent: "Not sent. Tap to retry.",
  },
};

const ChatScreen = ({ route }) => {
  const {
    miID,
    userData,
    serverAddress,
    token,
    language = "ESP",
  } = useContext(AppContext);
  const userId = miID;
  const t = translations[language];
  const chatSocketRef = useRef(null);
  const navigation = useNavigation();
  const [isBlocked, setIsBlocked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [blockIcon, setBlockIcon] = useState(
    require("../img/icons/noblock.png")
  );
  const [blockerId, setBlockerId] = useState(null);
  const {
    otherUserId,
    otherUserName,
    otherUserSurname,
    otherUserPhoto,
  } = route.params;
  const fullName = otherUserName
    ? `${otherUserName} ${otherUserSurname || ""}`.trim()
    : "";
  const [reload, setReload] = useState(false);
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [inputPlaceholder, setInputPlaceholder] = useState(t.placeholder);
  const messageTimers = useRef({});

  const handlePress = () => {
    handleBlockToggle();
    setModalVisible(true);
  };

  const handleBlockToggle = async () => {
    const newBlockState = !isBlocked;
    const response = await fetch(
      `${serverAddress}/api/v1/perfils/actuacions`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: newBlockState ? "BL" : "UNBL",
          usu_Desti: route.params.otherUserId,
          bloquejat: newBlockState ? 1 : 0,
        }),
      }
    );
    const data = await response.json();
    if (data.status === "OK") {
      setIsBlocked(newBlockState);
      setBlockIcon(
        newBlockState
          ? require("../img/icons/block2.png")
          : require("../img/icons/noblock.png")
      );
      if (!newBlockState) {
        cargarMensajesHistoricos(); // Recargar mensajes si se desbloquea
      }
    }
  };

  const handleUnblock = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "BL",
            usu_Desti: route.params.otherUserId,
            bloquejat: 0,
          }),
        }
      );
      const data = await response.json();
      if (data.status === "OK") {
        setIsBlocked(false);
        setBlockIcon(require("../img/icons/noblock.png"));
        setReload(!reload); // Recargar mensajes históricos después del desbloqueo
        setModalVisible(false); // Cierra el modal después de desbloquear
      } else {
        //console.error("Error al desbloquear:", data.message);
      }
    } catch (error) {
      //console.error("Error al desbloquear:", error);
    }
  };

  const handleBackPress = () => {
    navigation.goBack();
  };

  const handleUserPress = (userId) => {
    navigation.navigate("ProfileOther", { userId });
  };

  const cargarMensajesHistoricos = async () => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ actuacio: "LX", usu_Desti: otherUserId }),
        }
      );
      const data = await response.json();
      if (data.status === "OK" && Array.isArray(data.data)) {
        const mensajesHistoricos = data.data.map((mensaje) => ({
          _id: String(mensaje.id_missatge),
          text: mensaje.texte,
          createdAt: new Date(mensaje.dataCreacio),
          user: { _id: mensaje.usuariEmisor },
          situacioMissatge: mensaje.situacioMissatge,
          status: "sent", // Asumimos que los mensajes históricos fueron enviados correctamente
        }));
        const groupedMessages = groupMessagesByDate(mensajesHistoricos);
        setMessages(groupedMessages.reverse());
      } else if (data.data && data.data.status === "BLO") {
        setIsBlocked(true);
        setBlockerId(data.data.usu_bloquejador);
        setBlockIcon(require("../img/icons/block2.png"));
        setModalVisible(true);
      }
    } catch (error) {
      //console.error("Error al cargar mensajes históricos:", error);
    }
  };

  const groupMessagesByDate = (messages) => {
    const sortedMessages = messages.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
    const groupedMessages = [];
    let previousDate = "";

    sortedMessages.forEach((message, index) => {
      const messageDate = new Date(message.createdAt);
      const dateString = messageDate.toDateString();

      if (index === 0 || dateString !== previousDate) {
        const dateText = formatDate(messageDate, language);
        groupedMessages.push({
          _id: `date-${message._id}`,
          text: dateText,
          createdAt: messageDate,
          system: true,
        });
      }
      previousDate = dateString;
      groupedMessages.push(message);
    });

    return groupedMessages;
  };

  const formatDate = (date, languageCode = "ESP") => {
    const messageDate = new Date(date);
    messageDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    let locale;
    switch (languageCode) {
      case "ESP":
        locale = "es-ES";
        break;
      case "CAT":
        locale = "ca-ES";
        break;
      case "ENG":
        locale = "en-US";
        break;
      default:
        locale = "en-US";
    }

    if (messageDate.getTime() === today.getTime()) {
      return translations[languageCode].today || "Today";
    } else {
      return messageDate.toLocaleDateString(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };

  useEffect(() => {
    // Configuración del encabezado, incluyendo el nombre, bloquear y volver atrás
    navigation.setOptions({
      headerTitle: () => (
        <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 18,
              color: Colors.primary,
              textDecorationLine: "underline",
            }}
          >
            {fullName}
          </Text>
        </TouchableOpacity>
      ),
      headerTitleAlign: "center",
      headerRight: () => (
        <TouchableOpacity onPress={handlePress} style={{ paddingRight: 10 }}>
          <Image source={blockIcon} style={{ width: 30, height: 25 }} />
        </TouchableOpacity>
      ),
      headerLeft: () => (
        <TouchableOpacity
          onPress={handleBackPress}
          style={{ marginLeft: 10, width: 80 }}
        >
          <Text
            style={{
              color: Colors.primary,
              fontSize: 16,
              // textDecorationLine: "underline",
            }}
          >
            {t.back}
          </Text>
        </TouchableOpacity>
      ),
    });
  }, [navigation, fullName, blockIcon, handlePress, otherUserId]);

  useEffect(() => {
    // Inicializa el socket y almacena la referencia en chatSocketRef
    const chatSocket = SocketIOClient(serverAddress, {
      path: "/chat-socket",
      query: { token },
      reconnection: true, // Activa la reconexión automática
      reconnectionAttempts: 10, // Intentos de reconexión (puedes ajustar)
      reconnectionDelay: 2000, // Retraso entre intentos
    });
    chatSocketRef.current = chatSocket; // Almacena la referencia del socket

    // Cargar mensajes históricos
    cargarMensajesHistoricos();

    // Unirse a la sala de chat
    const joinChat = () => {
      chatSocket.emit("join chat", { userId: miID, otherUserId });
      //console.log("Cliente unido a la sala de chat");
    };

    // Configurar listeners de conexión y reconexión
    chatSocket.on("connect", joinChat);
    chatSocket.on("reconnect", joinChat);

    // Listener para el mensaje entrante
    const handleChatMessage = (message) => {
      //console.log("Cliente recibió mensaje:", message);

      if (!message._id) {
        //console.error("El mensaje recibido no tiene _id:", message);
        return;
      }

      setMessages((previousMessages) => {
        // Emparejar el mensaje por _id
        const index = previousMessages.findIndex(
          (msg) => String(msg._id) === String(message._id)
        );

        if (index !== -1) {
          //console.log("Mensaje emparejado con _id:", message._id);

          // Cancelar temporizadores
          const messageId = previousMessages[index]._id;
          if (messageTimers.current[messageId]) {
            clearTimeout(messageTimers.current[messageId].waitingTimer);
            clearTimeout(messageTimers.current[messageId].failTimer);
            delete messageTimers.current[messageId];
          }

          // Actualizar el mensaje existente
          const updatedMessages = [...previousMessages];
          updatedMessages[index] = {
            ...updatedMessages[index],
            ...message,
            status: "sent",
            situacioMissatge: message.situacioMissatge || "E", // Actualizamos el estado del mensaje
          };

          return updatedMessages;
        } else {
          // console.log(
          //   "No se encontró mensaje para emparejar con _id:",
          //   message._id
          // );
        }

        // Si no se encontró el mensaje, agregarlo a la lista
        return GiftedChat.append(previousMessages, {
          ...message,
          status: "sent",
          situacioMissatge: message.situacioMissatge || "E",
        });
      });
    };

    // Suscribir al evento de mensaje
    chatSocket.on("chat message", handleChatMessage);

    // Función de limpieza
    return () => {
      // Limpiar temporizadores
      Object.values(messageTimers.current).forEach(
        ({ waitingTimer, failTimer }) => {
          clearTimeout(waitingTimer);
          clearTimeout(failTimer);
        }
      );
      messageTimers.current = {};

      if (chatSocketRef.current) {
        chatSocketRef.current.off("chat message", handleChatMessage);
        chatSocketRef.current.off("connect", joinChat);
        chatSocketRef.current.off("reconnect", joinChat);
        chatSocketRef.current.emit("leave chat", { userId: miID, otherUserId });
        chatSocketRef.current.disconnect();
        chatSocketRef.current = null;
      }
    };
  }, [
    miID,
    otherUserId,
    serverAddress,
    token,
    navigation,
    route.params,
    language,
    reload,
  ]);

  const onSend = useCallback(
    (messages = []) => {
      setSendButtonDisabled(true);
      setText("");

      messages.forEach((message) => {
        const tempId = `${Date.now()}-${Math.floor(Math.random() * 1000)}`;

        const messageWithId = {
          ...message,
          _id: tempId, // Usamos tempId como _id temporal
          status: "pending", // Estado inicial 'pending'
          situacioMissatge: "E", // Estado inicial del mensaje (Enviado)
        };

        //console.log("Enviando mensaje con _id:", tempId);

        setMessages((previousMessages) =>
          GiftedChat.append(previousMessages, messageWithId)
        );

        if (chatSocketRef.current && chatSocketRef.current.connected) {
          chatSocketRef.current.emit("send message", {
            userId,
            otherUserId: route.params.otherUserId,
            text: message.text,
            createdAt: message.createdAt.toISOString(), // Aseguramos que sea un string ISO
            _id: tempId, // Usamos tempId aquí
          });
          //console.log("Mensaje emitido al servidor con _id:", tempId);
        }

        // Iniciar temporizador para cambiar a 'waiting' después de 1 segundo
        const waitingTimer = setTimeout(() => {
          updateMessageStatus(tempId, "waiting");
        }, 1000); // 1 segundo

        // Iniciar temporizador para cambiar a 'failed' si no hay confirmación
        const failTimer = setTimeout(() => {
          updateMessageStatus(tempId, "failed");
        }, 5000); // 5 segundos

        messageTimers.current[tempId] = { waitingTimer, failTimer };
      });
    },
    [userId, route.params.otherUserId]
  );

  const updateMessageStatus = (messageId, status) => {
    // console.log(
    //   "Actualizando estado del mensaje _id:",
    //   messageId,
    //   "a:",
    //   status
    // );

    setMessages((previousMessages) =>
      previousMessages.map((msg) =>
        String(msg._id) === String(messageId) ? { ...msg, status } : msg
      )
    );
  };

  // Función para renderizar el icono de estado del mensaje
  const renderMessageStatusIcon = (messageStatus) => {
    switch (messageStatus) {
      case "E":
        return "✓"; // Enviado
      case "R":
        return "✓✓"; // Recibido
      case "L":
        return "✓✓"; // Leído
      default:
        return "";
    }
  };

  // Función para manejar el cambio del input
  const handleInputChange = (input) => {
    setText(input);
    setSendButtonDisabled(input.trim() === "");
  };

  const renderSendButton = (props) => (
    <TouchableOpacity
      style={[
        styles.sendButton,
        { backgroundColor: sendButtonDisabled ? "#d3d3d3" : Colors.primary },
      ]}
      onPress={async () => {
        if (!sendButtonDisabled) {
          try {
            if (Platform.OS !== "web") {
              await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
            }
          } catch (error) {
            // console.log(
            //   "Haptic feedback no está disponible o ha fallado",
            //   error
            // );
          }
          onSend([{ text, user: { _id: userId }, createdAt: new Date() }]);
        }
      }}
      disabled={sendButtonDisabled}
    >
      <Image
        source={require("../img/icons/share3.png")}
        style={styles.sendIcon}
      />
    </TouchableOpacity>
  );

  const renderBubble = (props) => {
    const { currentMessage } = props;
    const isMyMessage = currentMessage.user._id === userId;

    let bubbleStyle = {};
    let tickIcon = null;

    if (currentMessage.status === "sent") {
      tickIcon = renderMessageStatusIcon(currentMessage.situacioMissatge);
    } else if (currentMessage.status === "waiting") {
      bubbleStyle = { opacity: 0.5 };
      tickIcon = "⌛";
    } else if (currentMessage.status === "failed") {
      bubbleStyle = { opacity: 0.5 };
      tickIcon = "⌛";
    }

    return (
      <Bubble
        {...props}
        wrapperStyle={{
          right: {
            ...bubbleStyle,
            backgroundColor: "#007aff",
            marginBottom: 4,
            marginRight: 4,
          },
          left: {
            backgroundColor: Colors.white,
            marginBottom: 4,
            marginLeft: 4,
          },
        }}
        renderTicks={() => {
          if (isMyMessage) {
            const iconColor =
              currentMessage.situacioMissatge === "L" ? "#00FF00" : "white";
            return (
              <Text style={{ fontSize: 10, color: iconColor }}>
                {tickIcon}
              </Text>
            );
          }
          return null;
        }}
      />
    );
  };

  const handleResendMessage = (message) => {
    // Evitar reintentos si el mensaje ya está en 'sent' o 'pending'
    if (message.status === "sent" || message.status === "pending") return;

    //console.log("Reintentando enviar mensaje con _id:", message._id);

    // Actualizar el estado a 'pending' antes de reintentar
    updateMessageStatus(message._id, "pending");

    if (chatSocketRef.current && chatSocketRef.current.connected) {
      chatSocketRef.current.emit("send message", {
        userId,
        otherUserId: route.params.otherUserId,
        text: message.text,
        createdAt: message.createdAt.toISOString(), // Aseguramos que sea un string ISO
        _id: message._id, // Enviamos el _id del mensaje
      });
      //console.log("Mensaje reemitido al servidor con _id:", message._id);
    }

    // Iniciar temporizador para cambiar a 'waiting' después de 1 segundo
    const waitingTimer = setTimeout(() => {
      updateMessageStatus(message._id, "waiting");
    }, 1000); // 1 segundo

    // Iniciar nuevo temporizador para cambiar a 'failed' si no hay confirmación
    const failTimer = setTimeout(() => {
      updateMessageStatus(message._id, "failed");
    }, 5000); // 5 segundos

    messageTimers.current[message._id] = { waitingTimer, failTimer };
  };

  // Nueva función renderMessageContent para manejar el contenido del mensaje
  const renderMessageContent = (messageProps) => {
    const { currentMessage } = messageProps;

    if (currentMessage.system) {
      return (
        <View style={{ alignItems: "center", marginVertical: 5 }}>
          <Text style={{ color: "grey" }}>{currentMessage.text}</Text>
        </View>
      );
    }

    if (currentMessage.user._id !== userId) {
      // Mensaje del otro usuario, mostrar avatar
      let imageSource;
      let additionalStyle = {};

      if (otherUserPhoto) {
        imageSource = { uri: `${serverAddress}/fotos/${otherUserPhoto}` };
      } else {
        imageSource =
          profileImages[otherUserId % 10] ||
          require("../img/profiles/default.png");
        additionalStyle = { backgroundColor: Colors.primary };
      }

      return (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
            <Image
              source={imageSource}
              style={{
                width: 40,
                height: 40,
                borderRadius: 20,
                marginRight: 8,
                ...additionalStyle,
              }}
            />
          </TouchableOpacity>
          {renderBubble(messageProps)}
        </View>
      );
    } else {
      // Mensaje propio, no mostrar avatar
      return renderBubble(messageProps);
    }
  };

  const renderMessage = (messageProps) => {
    const { currentMessage } = messageProps;

    const isFailed = currentMessage.status === "failed";

    return (
      <View>
        <TouchableOpacity
          onPress={() => {
            if (isFailed) {
              handleResendMessage(currentMessage);
            }
          }}
          activeOpacity={isFailed ? 0.6 : 1}
        >
          {renderMessageContent(messageProps)}
        </TouchableOpacity>
        {isFailed && (
          <TouchableOpacity
            onPress={() => handleResendMessage(currentMessage)}
            style={{ alignSelf: "flex-end", marginRight: 10, marginTop: 4 }}
          >
            <Text style={{ color: "red", fontSize: 12 }}>{t.notSent}</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const renderModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(false)}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          Keyboard.dismiss(); // Oculta el teclado
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{translations[language].message}</Text>
            {isBlocked && blockerId === miID ? (
              <>
                <Text style={styles.blockMessageText}>
                  {translations[language].block}
                </Text>
                <View style={styles.buttonContainer}>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={() => {
                      setModalVisible(false);
                      navigation.goBack();
                    }}
                  >
                    <Text style={styles.textStyle}>
                      {translations[language].atrás}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[styles.button, styles.unblockButton]}
                    onPress={handleUnblock}
                  >
                    <Text style={styles.textStyle}>
                      {translations[language].desbloquear}
                    </Text>
                  </TouchableOpacity>
                </View>
              </>
            ) : (
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={[styles.button, styles.singleButton]}
                  onPress={() => {
                    setModalVisible(false);
                    navigation.goBack();
                  }}
                >
                  <Text style={styles.textStyle}>
                    {translations[language].atrás}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );

  const renderInputToolbar = (props) => (
    <InputToolbar
      {...props}
      containerStyle={styles.inputToolbar}
      primaryStyle={styles.primaryInputContainer}
      renderComposer={(composerProps) => (
        <TextInput
          {...composerProps}
          placeholder={inputPlaceholder}
          placeholderTextColor="#999"
          multiline
          style={styles.textInput}
          value={text}
          onChangeText={handleInputChange}
          blurOnSubmit={false}
          maxHeight={140} // Altura máxima para 6 líneas aproximadamente
        />
      )}
      renderSend={renderSendButton}
    />
  );

  const screenStyle = Platform.OS === "ios" ? { marginBottom: 15 } : {};

  return (
    <View style={{ flex: 1, ...screenStyle }}>
      {renderModal()}

      {/* Encabezado personalizado para web */}
      {Platform.OS === "web" && (
        <View style={styles.webHeader}>
          <TouchableOpacity
            onPress={handleBackPress}
            style={{ marginLeft: 10 }}
          >
            <Text
              style={{
                color: Colors.primary,
                fontSize: 16,
                textDecorationLine: "underline",
                zIndex: 1000,
              }}
            >
              {t.back}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => handleUserPress(otherUserId)}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: 16,
                color: Colors.primary,
              }}
            >
              {fullName}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handlePress} style={{ marginRight: 10 }}>
            <Image source={blockIcon} style={{ width: 30, height: 25 }} />
          </TouchableOpacity>
        </View>
      )}

      <GiftedChat
        messages={messages}
        onSend={onSend}
        user={{ _id: userId }}
        renderMessage={renderMessage}
        renderInputToolbar={renderInputToolbar}
        placeholder={t.placeholder}
        keyboardShouldPersistTaps="handled"
        isKeyboardInternallyHandled={false}
        minInputToolbarHeight={80}
        alwaysShowSend
        scrollToBottom
        text={text}
        onInputTextChanged={handleInputChange}
        listViewProps={{
          style: {
            paddingBottom: 20,
          },
          contentContainerStyle: {
            paddingTop: Platform.select({
              ios: 4,
              android: 12,
              web: 20,
            }),
          },
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
    fontSize: 16,
    color: Colors.primary,
    fontFamily: Fonts.poppinsRegular,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    backgroundColor: Colors.primary,
    flex: 1,
    marginHorizontal: 5,
  },
  singleButton: {
    width: "80%",
  },
  textStyle: {
    color: "white",
    textAlign: "center",
    fontFamily: Fonts.poppinsRegular,
    fontSize: 16,
  },
  blockMessageText: {
    marginTop: 4,
    marginBottom: 24,
    fontSize: 14,
    color: "grey",
    textAlign: "center",
  },
  unblockButton: {
    backgroundColor: "green",
  },
  inputToolbar: {
    borderTopWidth: 0,
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: "#F7F7F7",
    minHeight: 60,
    marginBottom: 10,
  },
  textInput: {
    flex: 1,
    fontSize: 16,
    color: "#333",
    padding: 10,
    paddingTop: 10,
    paddingBottom: 10,
    minHeight: 40,
    maxHeight: 140, // Altura máxima para aproximadamente 6 líneas
    textAlignVertical: "top",
  },
  primaryInputContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    borderRadius: 25,
    paddingHorizontal: 10,
    marginHorizontal: 8,
    borderWidth: 1,
    borderColor: "#E0E0E0",
  },
  sendButton: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary,
    borderRadius: 20,
    width: 40,
    height: 40,
    marginHorizontal: -5,
  },
  sendIcon: {
    width: 24,
    height: 24,
    tintColor: "#FFF",
    resizeMode: "contain",
    marginLeft: -2,
  },
  webHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
});

export default ChatScreen;
