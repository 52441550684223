import React, { useEffect, useState, useContext } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Image,
  Text,
  Dimensions,
  Platform,
  TouchableOpacity,
} from "react-native";
import Svg, { Path } from "react-native-svg";
import trocaliaIcon from "../img/icons/trocalia.png";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import { useNavigation } from "@react-navigation/native";
const translate = {
  ESP: {
    back: "Volver atrás",
  },
  CAT: {
    back: "Tornar enrere",
  },
  ENG: {
    back: "Go back",
  },
};

export default function Privacy() {
  const [privacyData, setPrivacyData] = useState("");
  const { serverAddress, language } = useContext(AppContext);
  const { width } = Dimensions.get("window");
  const svgWidth = width;
  const svgHeight = 180;
  const navigation = useNavigation();

  useEffect(() => {
    const fetchPrivacyData = async () => {
      try {
        const response = await fetch(`${serverAddress}/privacy`);
        const data = await response.json();
        setPrivacyData(data); // Guarda directamente los datos del JSON
      } catch (error) {
        //console.error("Error fetching privacy data:", error);
      }
    };
    fetchPrivacyData();
  }, []);

  const renderPrivacy = () => {
    const privacyForLanguage = privacyData[language] || {};

    const renderPrivacyItem = (key, value) => {
      if (typeof value === "object" && value !== null) {
        return (
          <View key={key} style={styles.termSection}>
            <Text style={styles.termTitle}>{key}</Text>
            {Object.entries(value).map(([subKey, subValue]) => {
              // Aquí, asegúrate de que subValue es un string o número antes de renderizarlo
              if (typeof subValue === "string" || typeof subValue === "number") {
                return (
                  <Text key={subKey} style={styles.termContent}>
                    {subKey}: {subValue}
                  </Text>
                );
              }
              // Si subValue no es un string o número, podrías decidir cómo manejarlo
              // Por ejemplo, si es un array u otro objeto, podrías necesitar otra lógica aquí
              // Para este ejemplo, simplemente no renderizamos si subValue es complejo
              return null;
            })}
          </View>
        );
      } else {
        // Si 'value' es una cadena, renderizar normalmente.
        return (
          <View key={key} style={styles.termSection}>
            <Text style={styles.termTitle}>{key}</Text>
            <Text style={styles.termContent}>{value}</Text>
          </View>
        );
      }
    };

    return Object.entries(privacyForLanguage).map(([key, value]) =>
      renderPrivacyItem(key, value)
    );
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.blueHeader}>
        <Svg height={svgHeight} width={svgWidth}>
          <Path
            d={`M0,0 L${svgWidth},0 L${svgWidth},160 C${svgWidth},160 ${
              svgWidth / 2
            },200 0,160 Z`}
            fill={Colors.primary}
          />
        </Svg>
        <Image style={styles.logo} resizeMode="cover" source={trocaliaIcon} />
      </View>
      {Platform.OS === "web" && (
        <View style={styles.backButtonContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Text style={styles.backButtonText}>
              {translate[language].back}
            </Text>
          </TouchableOpacity>
        </View>
      )}
      <View style={styles.content}>
        {Object.keys(privacyData).length ? (
          renderPrivacy()
        ) : (
          <Text>Cargando...</Text>
        )}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
  },
  blueHeader: {
    width: "100%",
    height: 120,
    marginTop: -40,
    marginBottom: 60,
    backgroundColor: Colors.primary,
    zIndex: 0,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    alignItems: "center",
    ...Platform.select({
      web: {
        marginTop: 20,
        marginBottom: 20,
      },
    }),
  },
  trocaliaContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  logo: {
    width: 240,
    height: 50,
    top: -80,
    zIndex: 99,
    ...Platform.select({
      web: {
        top: -25,
      },
    }),
  },
  content: {
    padding: 20,
  },
  text: {
    fontSize: 16,
    color: "#000",
  },
  termSection: {
    marginBottom: 20,
  },
  termTitle: {
    ...Fonts.poppinsMedium14,
    color: Colors.grayDark,
  },
  termContent: {
    ...Fonts.poppinsRegular14,
    color: Colors.grayDark,
  },
  backButtonContainer: {
    width: "100%",
    padding: 0,
    alignItems: "flex-start",
    backgroundColor: "#FFFFFF",
    zIndex: 99,
  },
  backButtonText: {
    color: Colors.primary,
    textDecorationLine: "underline",
    fontSize: 16,
    zIndex: 99,
  },
});
