import React, { useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableOpacity,
} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import profileImages from "../img/profileimage.js";
import NeedRegister from "../selectables/NeedRegister.js";

const capitalizeFirstLetter = (str) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const capitalizeLastName = (lastName) => {
  if (!lastName) return "";
  return lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
};

const CardValoracion = ({ valoraciones }) => {
  const { serverAddress } = useContext(AppContext);
  const navigation = useNavigation();

  const navigateToProfile = (usuOrigen) => {
    navigation.navigate("ProfileOther", { userId: usuOrigen });
  };

  const getComentarioYValoracion = (valoracion) => {
    if (valoracion.comentari && valoracion.valoracio !== undefined) {
      return { comentari: valoracion.comentari, valoracio: valoracion.valoracio };
    } else if (Array.isArray(valoracion.comentaris) && valoracion.comentaris.length > 0) {
      const firstComentari = valoracion.comentaris[0];
      return {
        comentari: firstComentari.comentari || "Sin comentarios",
        valoracio: firstComentari.valoracio || null
      };
    }
    return { comentari: "Sin comentarios", valoracio: null };
  };

  return (
    <View>
      {(valoraciones || []).map((valoracion, index) => {
        const { comentari, valoracio } = getComentarioYValoracion(valoracion);
        // Determinar los estilos de borde y fondo según el tipo de usuario
        let cardBorderStyle;
        let imageBorderStyle;
        let backgroundStyle;

        switch (valoracion.tipusUsuari) {
          case "Premium":
            cardBorderStyle = styles.cardBorderPremium;
            imageBorderStyle = styles.imageBorderPremium;
            backgroundStyle = styles.cardBackgroundPremium;
            break;
          case "Colaborador":
            cardBorderStyle = styles.cardBorderCollaborator;
            imageBorderStyle = styles.imageBorderCollaborator;
            backgroundStyle = styles.cardBackgroundCollaborator;
            break;
          default:
            cardBorderStyle = styles.cardBorderStandard;
            imageBorderStyle = styles.imageBorderStandard;
            backgroundStyle = styles.cardBackgroundStandard;
            break;
        }

        return (
          <TouchableOpacity
            key={index}
            onPress={() => navigateToProfile(valoracion.usu_Origen)}
          >
            <View style={[styles.cardContainer, cardBorderStyle, backgroundStyle]}>
              <Image
                style={[
                  styles.profileImage,
                  imageBorderStyle,
                ]}
                resizeMode="cover"
                source={
                  valoracion.foto
                    ? { uri: `${serverAddress}/fotos/${valoracion.foto}` }
                    : profileImages[valoracion.usu_Origen % 10] ||
                      require("../img/profiles/default.png")
                }
              />

              {valoracion.tipusUsuari === "Premium" && (
                <Text style={styles.proText}>PRO</Text>
              )}

              <View style={styles.textContainer}>
                <View style={styles.headerContainer}>
                  <Text style={styles.name}>{`${valoracion.nomUsuari} ${valoracion.cognomUsuari}`}</Text>
                  {valoracio !== null && (
                    <View style={styles.ratingContainer}>
                      <Text style={styles.rating}>{valoracio}</Text>
                      <Image
                        style={styles.ratingIcon}
                        resizeMode="cover"
                        source={require("../img/icons/puntuation.png")}
                      />
                    </View>
                  )}
                </View>
                <Text style={styles.comment} numberOfLines={4}>{comentari}</Text>
              </View>
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: Colors.grayLighter,
    width: 340,
    marginBottom: 16,
    marginTop: 12,
    borderRadius: 16,
    padding: 16,
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1, // Grosor del borde del card
    borderColor: Colors.grayMedium, // Color del borde del card estándar
    height: 110,
  },
  cardBorderStandard: {
    borderColor: Colors.grayMedium, // Borde del card estándar
  },
  cardBorderPremium: {
    borderColor: Colors.secondary, // Borde Premium
  },
  cardBorderCollaborator: {
    borderColor: Colors.primary, // Borde Colaborador
  },
  cardBackgroundStandard: {
    backgroundColor: Colors.grayLighter,
  },
  cardBackgroundPremium: {
    backgroundColor: "rgba(255, 223, 158, 0.2)", // Fondo Premium
  },
  cardBackgroundCollaborator: {
    backgroundColor: "rgba(177, 217, 244, 0.2)", // Fondo Colaborador
  },
  profileImage: {
    borderRadius: 35.5,
    width: 71,
    height: 71,
    marginRight: 15,
    backgroundColor: Colors.primary,
    borderWidth: 2, // Grosor del borde de la foto
  },
  imageBorderStandard: {
    borderColor: Colors.grayMediumDark, // Borde estándar para la foto
  },
  imageBorderPremium: {
    borderColor: Colors.secondary, // Borde Premium para la foto
  },
  imageBorderCollaborator: {
    borderColor: Colors.primary, // Borde Colaborador para la foto
  },
  defaultImageBackground: {
    backgroundColor: Colors.primary,
  },
  proText: {
    position: "absolute",
    top: 4,
    left: 4,
    color: Colors.secondary,
    ...Fonts.poppinsSemiBold14,
  },
  textContainer: {
    flex: 1,
    justifyContent: "space-between",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  name: {
    ...Fonts.dmSerifDisplayRegular16,
  },
  comment: {
    ...Fonts.poppinsRegular14,
  },
  ratingContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  rating: {
    ...Fonts.poppinsRegular14,
    marginRight: 5,
    marginBottom: -3, // Bajamos el número 2px
  },
  ratingIcon: {
    width: 16,
    height: 14,
    marginLeft: 0,
  },
});

export default CardValoracion;
