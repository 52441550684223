import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  Modal,
  Platform,
  Animated,
  Image,
  Easing,
} from "react-native";
import VerticalList from "../selectables/VerticalList";
import { Colors, Fonts } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import SelectorRecomendadosProximidad from "../selectables/SelectorRecomendadosProximidad";
import { useNavigation } from "@react-navigation/native";
import SelectorOfreceNecesita from "../selectables/SelectorOfreceNecesita";
import CardSHorizontal from "../components/CardSHorizontal";
import Filters from "../components/Filters";
import { DataContext } from "../../DataContext";
import CardSVerticalSmall from "../components/CardSVerticalSmall";
import Loading from "../components/Loading";
import Nodata from "../components/Nodata.js";
import Publi from "../components/Publi.js";
import NeedRegister from "../selectables/NeedRegister";
import * as Haptic from "expo-haptics";

const translate = {
  ESP: {
    volver: "Volver atrás",
    seeMore: "Ver",
    closeMore: "Cerrar",
    filter: "Filtros",
    title: "Destacados cerca de ti",
    nodata:
      "No se han obtenido datos para esta búsqueda. Puedes añadir la demanda y te avisaremos cuando alguien lo ofrezca",
    resoults: "Resultados",
    addDemand: "Publica la demanda",
    addDemand1: "y te notificaremos cuando alguien ofrezca",
  },
  CAT: {
    volver: "Tornar enrere",
    seeMore: "Veure",
    closeMore: "Tancar",
    filter: "Filtres",
    title: "Destacats a la zona",
    nodata:
      "No s'han obtingut dades per a aquesta cerca. Pots afegir la demanda i t'avisarem quan algú n'ofereixi",
    resoults: "Resultats",
    addDemand: "Publica la demanda",
    addDemand1: "i et notificarem quan algú ofereixi",
  },
  ENG: {
    volver: "Go back",
    seeMore: "See more",
    closeMore: "Close",
    filter: "Filters",
    title: "Highlights near you",
    nodata:
      "No data has been obtained for this search. You can add a demand and we will notify you when someone offers",
    resoults: "Results",
    addDemand: "Add the demand",
    addDemand1: "we will notify you when someone offers",
  },
};

const CategoryDetailsScreen = ({ route }) => {
  const navigation = useNavigation();
  const isKeywordSearch = Array.isArray(route.params.category.nombre);

  const { serverAddress, token, userData, filterConfig, miTipo } =
    useContext(AppContext);

  const [orderBy, setOrderBy] = useState("D");
  const [isDemand, setIsDemand] = useState(false);
  const [panelOffset, setPanelOffset] = useState(0);
  const [showPanel, setShowPanel] = useState(true);
  const isUserPremium = miTipo === "Premium";
  const [showNodata, setShowNodata] = useState(false);

  const { language } = useContext(AppContext);
  const category = route.params?.category;
  const id_subcat = route.params.category;
  const [selectedSubcategory, setSelectedSubcategory] = useState({ id_subcat });
  const { data } = useContext(DataContext) || { tipo: [{ categorias: [] }] };
  const categoryInput = route.params?.category;

  const getCategoryTitle = (input, lang) => {
    if (input && input.nombre && Array.isArray(input.nombre)) {
      return input.nombre[0];
    } else if (!isNaN(parseInt(input))) {
      const id_subcat = parseInt(input);
      for (const tipo of data.tipo) {
        for (const categoria of tipo.categorias) {
          if (categoria.subcategorias) {
            const subcatData = categoria.subcategorias.find(
              (sub) => sub.id_subcat === id_subcat
            );
            if (subcatData) {
              return lang === "CAT"
                ? subcatData.nombre_CAT
                : lang === "ENG"
                ? subcatData.nombre_ENG
                : subcatData.nombre;
            }
          }
          if (categoria.id_subcat === id_subcat) {
            return lang === "CAT"
              ? categoria.nombre_CAT
              : lang === "ENG"
              ? categoria.nombre_ENG
              : categoria.nombre;
          }
        }
      }
    }
    return null;
  };

  useLayoutEffect(() => {
    if (data.tipo && data.tipo.length > 0) {
      const categoryTitle = getCategoryTitle(categoryInput, language);
      navigation.setOptions({
        title: categoryTitle || translate[language].resoults,
      });
    }
  }, [categoryInput, language, navigation, data.tipo]);
  const [showResults, setShowResults] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [currentOffset, setCurrentOffset] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const scaleAnimFab = useRef(new Animated.Value(1)).current; // Escala inicial
  const rotateAnimFab = useRef(new Animated.Value(0)).current; // Rotación inicial
  const toggleModal = async () => {
    // Añade una respuesta háptica si no es una plataforma web
    if (Platform.OS !== "web") {
      try {
        await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
      } catch (hapticError) {
        //console.log("Haptic feedback no disponible o ha fallado", hapticError);
      }
    }
    setModalVisible(!modalVisible);
  };

  const [isFetching, setIsFetching] = useState(false);
  const panelTitle = translate[language].title;

  const [showAnimatedBorder, setShowAnimatedBorder] = useState(true);
  const rotateInterpolate = rotateAnimFab.interpolate({
    inputRange: [-180, 0, 180],
    outputRange: ["-180deg", "0deg", "180deg"], // Rotación de -720 a 720 grados
  });

  const fetchingData = useRef(false);
  useEffect(() => {
    fetchData(orderBy);
  }, []);

  const generateParams = (orderByCode, offset = currentOffset) => {
    const admetTrocs = filterConfig ? (filterConfig.switchValue ? 1 : 0) : 0;
    const checkedItems = filterConfig ? filterConfig.checkedItems : {};
    const paraulesClau = isKeywordSearch ? route.params.category.nombre : [];

    let modoNegoci = "";
    if (checkedItems) {
      if (checkedItems.Empresa && checkedItems.Particulares) modoNegoci = "*";
      else {
        if (checkedItems.Empresa) modoNegoci = "E";
        if (checkedItems.Particulares) modoNegoci = "P";
      }
    }

    let tipoTransac = [];
    if (checkedItems.Alquiler) tipoTransac.push("L");
    if (checkedItems.Préstamo) tipoTransac.push("P");
    if (checkedItems.Regalo) tipoTransac.push("R");
    if (checkedItems.Venta) tipoTransac.push("V");
    if (tipoTransac.length === 4) tipoTransac = ["*"];

    let params = {};
    if (isKeywordSearch) {
      params = {
        paraulesClau: route.params.category.nombre,
        admetTrocs,
        modoNegoci,
        tipoTransac,
        offset: offset,
        orderby: orderByCode,
      };
    } else {
      params = {
        subcat: id_subcat,
        admetTrocs,
        paraulesClau: [],
        modoNegoci,
        tipoTransac,
        offset: offset,
        orderby: orderByCode,
      };
    }

    return params;
  };
  const animatedValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const startAnimation = () => {
      // Define la secuencia de animaciones para ampliar y reducir correctamente
      const animation = Animated.sequence([
        Animated.timing(animatedValue, {
          toValue: 1.1, // Ampliar el tamaño a 1.1
          duration: 1800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: false,
        }),
        Animated.timing(animatedValue, {
          toValue: 1, // Reducir de vuelta al tamaño original
          duration: 800,
          easing: Easing.out(Easing.ease),
          useNativeDriver: false,
        }),
      ]);

      // Inicia la animación y configúrala para que espere 30 segundos antes de repetirse
      animation.start(() => {
        // Mantener la animación en su estado final
        animatedValue.setValue(1);

        // Esperar 30 segundos antes de repetir la animación
        setTimeout(startAnimation, 30000);
      });
    };

    // Iniciar la animación por primera vez
    startAnimation();

    // Limpiar animación al desmontar
    return () => animatedValue.stopAnimation();
  }, [animatedValue]);

  // Interpolación para la escala
  const animatedScale = animatedValue.interpolate({
    inputRange: [1, 1.1],
    outputRange: [1, 1.1], // Escala solo entre el tamaño original y el ampliado
  });

  useLayoutEffect(() => {
    if (Platform.OS !== "web") {
      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity onPress={toggleModal}>
            <View style={{ position: "relative" }}>
              <Animated.Text
                style={{
                  transform: [
                    { scale: animatedScale },
                    // { rotate: animatedRotation },
                  ],
                  marginRight: 10,
                  color: "green",
                  ...Fonts.poppinsMedium16,
                  backgroundColor: Colors.white, // Fondo blanco
                  borderColor: "green", // Borde verde
                  borderWidth: 2, // Ancho del borde
                  borderRadius: 10, // Borde redondeado
                  paddingVertical: 2, // Espaciado vertical ajustado
                  paddingHorizontal: 8, // Espaciado horizontal ajustado
                  textAlign: "center",
                  lineHeight: 24,
                  // opacity: animatedOpacity,
                  alignItems: "center",
                }}
              >
                {translate[language].filter}
              </Animated.Text>
            </View>
          </TouchableOpacity>
        ),
      });
    }
  }, [
    navigation,
    // animatedRotation,
    animatedScale,
    // animatedOpacity,
    toggleModal,
    translate,
    language,
  ]);

  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const handleScroll = ({ nativeEvent }) => {
    if (isFetching || allDataLoaded) return;

    const threshold = 100;
    if (
      nativeEvent.layoutMeasurement.height + nativeEvent.contentOffset.y >=
      nativeEvent.contentSize.height - threshold
    ) {
      const newOffset = currentOffset + 10;
      setCurrentOffset(newOffset);
      fetchData(orderBy, newOffset);
    }
  };

  useEffect(() => {
    resetAndFetchData(orderBy);
  }, [filterConfig, isDemand, category.id_subcat, orderBy]);

  const onOrderBySelect = (newOrderBy) => {
    if (orderBy !== newOrderBy) {
      setOrderBy(newOrderBy);
      resetAndFetchData(newOrderBy);
    }
  };

  const resetAndFetchData = (newOrderBy) => {
    setApiData([]);
    setCurrentOffset(0);
    setAllDataLoaded(false);
    setIsFetching(true);
    fetchData(newOrderBy, 0);
  };

  useEffect(() => {
    setApiData([]);
    setCurrentOffset(0);
    setAllDataLoaded(false);
    fetchData(orderBy);
  }, [orderBy]);

  const fetchData = async (orderByCode, offset = 0) => {
    if (isFetching || fetchingData.current) return;

    fetchingData.current = true;
    setIsFetching(true);

    let params = generateParams(orderByCode, offset);
    if (!params) {
      setIsFetching(false);
      fetchingData.current = false;
      return;
    }

    const admetTrocs = filterConfig ? (filterConfig.switchValue ? 1 : 0) : 0;
    const checkedItems = filterConfig ? filterConfig.checkedItems : {};

    let modoNegoci = "";
    if (checkedItems) {
      if (checkedItems.Empresa && checkedItems.Particulares) modoNegoci = "*";
      else {
        if (checkedItems.Empresa) modoNegoci = "E";
        if (checkedItems.Particulares) modoNegoci = "P";
      }
    }

    let tipoTransac = [];
    if (checkedItems.Alquiler) tipoTransac.push("L");
    if (checkedItems.Préstamo) tipoTransac.push("P");
    if (checkedItems.Regalo) tipoTransac.push("R");
    if (checkedItems.Venta) tipoTransac.push("V");
    if (tipoTransac.length === 4) tipoTransac = ["*"];

    const endpoint = isDemand ? "demandes" : "ofertes";
    const fullURL = `${serverAddress}/api/v1/perfils/others/${endpoint}?orderby=${orderBy}&offset=${currentOffset}`;

    try {
      const response = await fetch(fullURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error("Error en la respuesta del servidor");
      }

      const jsonResponse = await response.json();
      if (jsonResponse && jsonResponse.status === "OK") {
        setApiData((prev) => [...prev, ...jsonResponse.data]);
        setAllDataLoaded(jsonResponse.data.length === 0);
      }
    } catch (error) {
      //console.error("Hubo un error en la solicitud:", error);
    } finally {
      setIsFetching(false);
      fetchingData.current = false;
    }
  };

  const renderItems = () => {
    let items = [];
    apiData.forEach((item, index) => {
      if (index === 1 || (index > 1 && (index - 1) % 5 === 0)) {
        const formatAnunci = ((index - 1) / 5) % 2 === 0 ? "H" : "Q";
        const props = {
          key: `publi-${index}`,
          formatAnunci: formatAnunci,
          externalContainerStyle: { marginTop: 4, marginBottom: 22 },
        };
        if (typeof selectedSubcategory.id_subcat === "number") {
          props.id_subcat = selectedSubcategory.id_subcat;
        }

        items.push(<Publi {...props} />);
      }
      items.push(
        <CardSHorizontal
          key={`card-${item.id || index}`}
          data={item}
          style={styles.marginTopSmall}
        />
      );
    });
    return items;
  };

  const handleSelectionChange = (selection) => {
    setIsDemand(selection === "necesita");
  };

  useEffect(() => {
    if (isKeywordSearch || selectedSubcategory) {
      fetchData();
    }
  }, [
    orderBy,
    selectedSubcategory,
    filterConfig,
    isDemand,
    currentOffset,
    isKeywordSearch,
  ]);

  useEffect(() => {
    if (orderBy) {
      setApiData([]);
      setCurrentOffset(0);
      setAllDataLoaded(false);
      setIsFetching(true);
      fetchData().then(() => setIsFetching(false));
    }
  }, [orderBy]);

  useEffect(() => {
    setApiData([]);
    setCurrentOffset(0);
    setAllDataLoaded(false);
    setIsFetching(true);
    fetchData().then(() => setIsFetching(false));
  }, [orderBy, filterConfig, isDemand, category.id_subcat]);

  useEffect(() => {
    if (!fetchingData.current) {
    }
  }, [currentOffset, filterConfig, orderBy]);

  useEffect(() => {
    fetchPanelData("panel1");
  }, [selectedSubcategory]);

  useEffect(() => {
    resetAndFetchData(orderBy);
  }, [orderBy]);

  useEffect(() => {
    const startPeriodicAnimations = () => {
      // Secuencia de animaciones para el FAB Inferior
      Animated.sequence([
        // 1. Escalar hacia arriba y rotar a la derecha (0.5 segundos)
        Animated.parallel([
          Animated.timing(scaleAnimFab, {
            toValue: 1.05, // Ampliar ligeramente
            duration: 1500, // 0.5 segundos
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
          Animated.timing(rotateAnimFab, {
            toValue: 360, // Dos vueltas a la derecha (720 grados)
            duration: 1500, // 0.5 segundos
            easing: Easing.linear,
            useNativeDriver: true,
          }),
        ]),
        // 2. Escalar hacia abajo y rotar a la izquierda (1 segundo)
        Animated.parallel([
          Animated.timing(scaleAnimFab, {
            toValue: 1, // Volver al tamaño original
            duration: 1500, // 1 segundo
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
          Animated.timing(rotateAnimFab, {
            toValue: -360, // Dos vueltas a la izquierda (-720 grados)
            duration: 1500, // 1 segundo
            easing: Easing.linear,
            useNativeDriver: true,
          }),
        ]),
        // 3. Resetear rotación a 0 para evitar acumulación de valores
        Animated.timing(rotateAnimFab, {
          toValue: 0, // Volver a la posición original
          duration: 0, // Inmediatamente
          useNativeDriver: true,
        }),
      ]).start();
    };

    // Iniciar las animaciones después de 5 segundos
    const initialTimeout = setTimeout(() => {
      startPeriodicAnimations();
      // Configurar un intervalo para repetir cada 10 segundos
      const interval = setInterval(() => {
        startPeriodicAnimations();
      }, 10000); // 10,000 ms = 10 segundos

      // Limpiar el intervalo al desmontar
      return () => clearInterval(interval);
    }, 5000); // 5000 ms = 5 segundos

    // Limpiar animaciones al desmontar
    return () => {
      clearTimeout(initialTimeout);
      scaleAnimFab.stopAnimation();
      rotateAnimFab.stopAnimation();
    };
  }, [scaleAnimFab, rotateAnimFab]);

  useEffect(() => {
    if (!isFetching && apiData.length === 0) {
      const timer = setTimeout(() => {
        setShowNodata(true);
      }, 200);

      return () => clearTimeout(timer);
    } else {
      setShowNodata(false);
    }
  }, [isFetching, apiData.length]);

  const togglePanelVisibility = () => {
    setShowPanel(!showPanel);
  };

  const generatePanel = (panelName, panelData) => {
    const panelTitle = getTitleForPanel(panelName, language);

    if (!showPanel) return null;
    return (
      <View key={panelName} style={styles.panelContainer}>
        <View style={styles.titleContainer}>
          <Text style={styles.titleText}>{panelTitle}</Text>
          <TouchableOpacity
            onPress={() => fetchPanelData(panelName)}
            style={styles.seeMoreButton}
          ></TouchableOpacity>
        </View>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.cardScrollContainer}
          onScroll={({ nativeEvent }) => {
            if (
              !isFetching &&
              nativeEvent.layoutMeasurement.width +
                nativeEvent.contentOffset.x >=
                nativeEvent.contentSize.width - 50
            ) {
              fetchPanelData(panelName);
            }
          }}
          scrollEventThrottle={16}
        >
          {panelData.length === 0 ? (
            <CardSVerticalSmall
              key="promo-only"
              data={{}}
              isPromotionCard={true}
            />
          ) : (
            <>
              {panelData.map((item, index) => (
                <CardSVerticalSmall
                  key={`item-${index}`}
                  data={item}
                  isPromotionCard={
                    index === 2 || (index > 2 && (index - 2) % 6 === 0)
                  }
                  style={styles.marginTopSmall}
                />
              ))}
              {panelData.length < 3 && (
                <CardSVerticalSmall
                  key="additional-promo"
                  data={{}}
                  isPromotionCard={true}
                />
              )}
            </>
          )}
        </ScrollView>
      </View>
    );
  };

  const getTitleForPanel = (panelName, language) => {
    let languageKey = language.toLowerCase();
    if (languageKey === "eng") {
      languageKey = "ing";
    }
    return panelsTitles[`${panelName}_${languageKey}`] || "";
  };

  const fetchPanelData = async (panelName) => {
    setIsFetching(true);

    const params = {
      ...generateParams(),
      orderby: "T",
      offset: panelOffset,
    };

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/others/ofertes?orderby=T&offset=${panelOffset}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(params),
        }
      );

      if (!response.ok) {
        throw new Error("Error en la respuesta del servidor");
      }

      const data = await response.json();
      if (data.status === "OK" && Array.isArray(data.data)) {
        setPanelsData((prevData) => ({
          ...prevData,
          [panelName]: [...(prevData[panelName] || []), ...data.data],
        }));
        setPanelOffset((prev) => prev + 10);
      }
    } catch (error) {
      //console.error(`Error al obtener datos de ${panelName}: ${error}`);
    } finally {
      setIsFetching(false);
    }
  };

  const [panelsData, setPanelsData] = useState({ panel1: [] });
  const [panelsTitles, setPanelsTitles] = useState({
    panel1_esp: "",
    panel1_cat: "",
    panel1_ing: "",
  });

  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const handlePress = () => {
    if (userData?.data[0]?.tipusUsuari === "Guest") {
      setIsNeedRegisterVisible(true);
    } else {
      const profileScreen = language === "ENG" ? "Profile" : "Perfil";
      navigation.navigate(profileScreen, {
        selectedOption: "necesita",
        openOfferModal: true,
      });
    }
  };

  return (
    <View style={styles.mainContainer}>
      {Platform.OS === "web" && (
        <View style={styles.backButtonContainer}>
          <Text
            onPress={() => navigation.goBack()}
            style={styles.backButtonText}
          >
            {translate[language].volver}
          </Text>
        </View>
      )}

      <ScrollView
        onScroll={handleScroll}
        scrollEventThrottle={150}
        contentContainerStyle={[
          styles.scrollContainer,
          styles.extraPaddingBottom,
        ]}
      >
        <>
          <SelectorOfreceNecesita
            onSelect={handleSelectionChange}
            style={styles.margin}
          />
          <View style={styles.panelBackground}>
            <View style={styles.titleContainer}>
              <Text style={styles.titleText}>{panelTitle}</Text>
              <TouchableOpacity
                onPress={() => setShowPanel(!showPanel)}
                style={styles.seeMoreButton}
              >
                <Text style={styles.subTitleText}>
                  {showPanel
                    ? translate[language].closeMore
                    : translate[language].seeMore}
                </Text>
              </TouchableOpacity>
            </View>
            {showPanel && generatePanel("panel1", panelsData.panel1)}
          </View>
          <SelectorRecomendadosProximidad
            onSelect={onOrderBySelect}
            style={styles.marginTopSmall}
          />
          {!isFetching && apiData.length === 0 && showNodata ? (
            <View style={styles.centeredContainer}>
              <View style={styles.nodataContainer}>
                <Text style={styles.nodataText}>
                  {translate[language].nodata}
                </Text>
                <TouchableOpacity
                  onPress={handlePress}
                  style={styles.addDemandButton}
                >
                  <Image
                    source={require("../img/icons/add2.png")}
                    style={styles.addDemandIcon}
                  />
                  <Text style={styles.addDemandText}>
                    {translate[language].addDemand}
                  </Text>
                  {/* <Text style={styles.addDemandTextSmall}>{translate[language].addDemand1}</Text> */}
                </TouchableOpacity>
              </View>
            </View>
          ) : (
            renderItems()
          )}
        </>
        {isFetching && <Loading />}
      </ScrollView>

      <TouchableOpacity onPress={toggleModal} style={styles.filterButton}>
        <Text style={styles.filterButtonText}>
          {translate[language].filter}
        </Text>
      </TouchableOpacity>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={toggleModal}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          onPress={toggleModal}
          activeOpacity={1}
        >
          <Filters closeModal={toggleModal} />
        </TouchableOpacity>
      </Modal>

      {translate && (
        <Animated.View
          style={{
            transform: [{ scale: scaleAnimFab }],
          }}
        >
          <TouchableOpacity style={styles.fab} onPress={handlePress}>
            <Animated.Image
              source={require("../img/icons/add2.png")}
              style={[
                styles.fabIcon,
                {
                  transform: [{ rotate: rotateInterpolate }],
                },
              ]}
            />
            <Animated.View style={styles.fabTextContainer}>
              <Animated.Text style={styles.fabTextBig}>
                {translate[language].addDemand}
              </Animated.Text>
              <Animated.Text style={styles.fabTextSmall}>
                {translate[language].addDemand1}
              </Animated.Text>
            </Animated.View>
          </TouchableOpacity>
        </Animated.View>
      )}

      {isNeedRegisterVisible && (
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  backButtonContainer: {
    width: "100%",
    padding: 10,
    alignItems: "flex-start",
    backgroundColor: Colors.white,
  },
  backButtonText: {
    color: Colors.primary,
    textDecorationLine: "underline",
    fontSize: 16,
  },
  scrollContainer: {
    padding: 0,
  },
  centeredComponent: {
    alignItems: "center",
    justifyContent: "center",
  },
  margin: {
    marginVertical: 8,
  },
  marginCard: {
    marginVertical: 18,
  },
  filterButton:
    Platform.OS === "web"
      ? {
          position: "absolute",
          top: 4,
          right: 10,
          padding: 10,
          borderRadius: 5,
          borderColor: Colors.primary,
          borderStyle: "solid",
          borderWidth: 1,
          zIndex: 10,
        }
      : {
          display: "none",
        },
  filterButtonText:
    Platform.OS === "web"
      ? {
          color: Colors.primary,
          fontSize: 16,
        }
      : {
          display: "none",
        },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: -14,
    marginTop: -2,
  },
  titleText: {
    fontSize: 20,
    ...Fonts.poppinsMedium16,
    color: Colors.grayDark,
    width: 280,
    marginLeft: 4,
    marginTop: 0,
    marginBottom: Platform.OS === "web" ? 10 : 4,
  },
  subTitleText: {
    fontSize: 16,
    marginTop: -2,
    marginBottom: 6,
    ...Fonts.poppinsRegular16,
    color: Colors.grayDark,
  },
  seeMoreButton: {
    paddingVertical: 2,
    marginRight: 10,
    marginTop: 2,
  },
  cardScrollContainer: {
    flexGrow: 1,
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 0,
  },
  panelBackground: {
    backgroundColor: Colors.white, // Fondo blanco
    paddingVertical: 0,
    marginTop: 4,
    // borderTopWidth: 2, // Borde superior de 2px
    // borderBottomWidth: 2, // Borde inferior de 2px
    // borderColor: Colors.secondary, // Color del borde en la parte superior e inferior
  },
  marginTopSmall: {
    marginTop: 4,
  },
  panelContainer: {
    // backgroundColor: Colors.fondo,
    paddingHorizontal: 0,
    paddingTop: 0,
    paddingBottom: -10,
    marginTop: 0,
  },
  centeredContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  extraPaddingBottom: {
    paddingBottom: 100,
  },
  nodataContainer: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.grayLight,
    borderRadius: 10,
    padding: 10,
    marginHorizontal: 20,
  },
  nodataText: {
    color: Colors.primary,
    ...Fonts.poppinsRegular14,
    textAlign: "center",
    marginBottom: 10,
  },
  addDemandButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.primary,
    borderRadius: 10,
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  addDemandIcon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  addDemandText: {
    color: "#fff",
    ...Fonts.poppinsRegular14,
  },
  addDemandTextSmall: {
    color: "#fff",
    ...Fonts.poppinsRegular10,
    marginLeft: 5,
  },
  fab: {
    position: "absolute",
    minWidth: 56,
    height: 56,
    alignItems: "center",
    justifyContent: "center",
    right: 20,
    bottom: 20,
    backgroundColor: Colors.primary,
    borderRadius: 28,
    elevation: 8,
    shadowColor: "#000",
    shadowRadius: 2,
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    flexDirection: "row",
    paddingHorizontal: 12, // Asegura suficiente espacio entre ícono y texto
  },
  fabIcon: {
    width: 24,
    height: 24,
    marginRight: 5,
  },
  fabTextContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  fabTextBig: {
    color: "#fff",
    ...Fonts.poppinsRegular14,
    textAlign: "center",
  },
  fabTextSmall: {
    color: "#fff",
    ...Fonts.poppinsRegular10,
    textAlign: "center",
  },
});

export default CategoryDetailsScreen;
