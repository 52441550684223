// profileImagesIndex.js
const profileImages = {
    0: require('../img/profiles/0.png'),
    1: require('../img/profiles/1.png'),
    2: require('../img/profiles/2.png'),
    3: require('../img/profiles/3.png'),
    4: require('../img/profiles/4.png'),
    5: require('../img/profiles/5.png'),
    6: require('../img/profiles/6.png'),
    7: require('../img/profiles/7.png'),
    8: require('../img/profiles/8.png'),
    9: require('../img/profiles/9.png'),
  };
  
  export default profileImages;