import React, { useContext, useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
  ScrollView,
  Alert,
  Linking,
  Animated,
  Easing
} from "react-native";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import imageMap from "../db/imageMap";
import ProfileImage from "./ProfileImage";
import { useRevenueCat } from "../../RevenueCatContext";
import * as Haptic from "expo-haptics";

const translations = {
  ESP: {
    trocaliaPro: "Trocalia COLAB",
    benefits: [
      "Conoce quién visita tu perfil",
      "Contribuye al mantenimiento de la comunidad",
      "Apoya la economía local y sostenible",
      "Forma parte de una red colaborativa",
      "Promueve el consumo responsable",
    ],
    bePro: "Hazte Mecenas",
    priceMonthlyPeriod: "/cada mes",
    close: "Cerrar",
    popupBlockerWarning:
      "Si el proceso de pago no se abre, asegúrate de permitir ventanas emergentes en Safari. Configuración > Safari > Bloquear ventanas emergentes.",
    errorcheckout:
      "Error al iniciar el proceso de pago: verifica tu conexión a internet y vuelve a intentarlo.",
    termsAndConditions: "Términos y Condiciones",
    termsAndConditionsEULA: "Términos de uso (EULA)",
    privacyPolicy: "Política de Privacidad",
    support: "Soporte",
    yasoypro:
      "Si previamente has adquirido Trocalia COLAB, puedes restaurar tus compras aquí.",
    restaurar: "Restaurar Compras",
    offeringsError: "No disponible",
    offeringsErrorMessage:
      "En este momento no se puede realizar esta acción, prueba más tarde o escríbenos a suport@trocalia.net.",
    visibilidad: "Tu ayuda mantiene la comunidad",
  },
  CAT: {
    trocaliaPro: "Trocalia COLAB",
    benefits: [
      "Descobreix qui visita el teu perfil",
      "Contribueix al manteniment de la comunitat",
      "Aposta per l'economia local i sostenible",
      "Forma part d'una xarxa col·laborativa",
      "Promou el consum responsable",
    ],
    bePro: "Fes-te Mecenes",
    priceMonthlyPeriod: "/cada mes",
    close: "Tancar",
    popupBlockerWarning:
      "Si el procés de pagament no s'obre, assegura't de permetre finestres emergents a Safari. Configuració > Safari > Bloquejar finestres emergents.",
    errorcheckout:
      "Error en iniciar el procés de pagament: verifica la teva connexió a internet i torna-ho a intentar.",
    termsAndConditions: "Termes i Condicions",
    termsAndConditionsEULA: "Termes d'ús (EULA)",
    privacyPolicy: "Política de Privacitat",
    support: "Suport",
    yasoypro:
      "Si prèviament has adquirit Trocalia COLAB, pots restaurar les teves compres aquí.",
    restaurar: "Restaurar Compres",
    offeringsError: "No disponible",
    offeringsErrorMessage:
      "En aquest moment no es pot realitzar aquesta acció, prova-ho més tard o escriu-nos a suport@trocalia.net.",
    visibilidad: "La teva ajuda manté la comunitat",
  },
  ENG: {
    trocaliaPro: "Trocalia COLAB",
    benefits: [
      "Know who visits your profile",
      "Contribute maintaining the community",
      "Support local and sustainable economy",
      "Be part of a collaborative network",
      "Promote responsible consumption",
    ],
    bePro: "Become a Patron",
    priceMonthlyPeriod: "/each month",
    close: "Close",
    popupBlockerWarning:
      "If the payment process doesn't open, make sure to allow pop-ups in Safari. Settings > Safari > Block Pop-ups.",
    errorcheckout:
      "Error starting the payment process: check your internet connection and try again.",
    termsAndConditions: "Terms and Conditions",
    termsAndConditionsEULA: "End User License Agreement (EULA)",
    privacyPolicy: "Privacy Policy",
    support: "Support",
    yasoypro:
      "If you have previously purchased Trocalia COLAB, you can restore your purchases here.",
    restaurar: "Restore Purchases",
    offeringsError: "Not Available",
    offeringsErrorMessage:
      "This action cannot be performed at the moment. Please try again later or contact us at suport@trocalia.net.",
    visibilidad: "Your help maintains the community",
  },
};

const iconsForBenefits = [82, 52, 310, 233, 179];

const Colab = ({ closeModal }) => {
  const { isReady, offerings, purchaseSubscription, restorePurchases } =
    useRevenueCat();
  const { language, serverAddress, token } = useContext(AppContext);
  const t = translations[language];
  const [showIOSMessage, setShowIOSMessage] = useState(false);
  const [isTrialMode] = useState(false);

  // Animaciones para los beneficios
  const benefitsAnimations = useRef(
    t.benefits.map(() => ({
      iconScale: new Animated.Value(0),
      textOpacity: new Animated.Value(0),
      iconOpacity: new Animated.Value(0),
    }))
  ).current;

  // Animación para el botón CTA
  const ctaAnimation = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    // Animación secuencial de los beneficios
    t.benefits.forEach((_, index) => {
      Animated.sequence([
        Animated.delay(index * 500), // Retraso entre beneficios
        Animated.parallel([
          Animated.timing(benefitsAnimations[index].iconScale, {
            toValue: 1,
            duration: 600,
            easing: Easing.out(Easing.ease),
            useNativeDriver: true,
          }),
          Animated.timing(benefitsAnimations[index].iconOpacity, {
            toValue: 1,
            duration: 600,
            useNativeDriver: true,
          }),
          Animated.timing(benefitsAnimations[index].textOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
          }),
        ]),
      ]).start();
    });

    // Animación del botón CTA
    const animateCTAButton = () => {
      Animated.sequence([
        Animated.delay(3000),
        Animated.timing(ctaAnimation, {
          toValue: 1.05,
          duration: 500,
          easing: Easing.out(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(ctaAnimation, {
          toValue: 1,
          duration: 500,
          easing: Easing.in(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.delay(5000),
      ]).start(() => {
        animateCTAButton(); // Repite la animación
      });
    };

    animateCTAButton(); // Inicia la animación de inmediato
  }, []);

  const detectOS = () => {
    if (typeof navigator !== "undefined") {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
        ? "iOS"
        : "other";
    }
    return "other";
  };

  const openLink = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      }
    });
  };

  const initiateCheckout = async () => {
    if (!offerings) {
      Alert.alert(t.offeringsError, t.offeringsErrorMessage);
      return;
    }
    
    const os = detectOS();
  
    // Lógica para web
    if (Platform.OS === "web") {
      if (os === "iOS") {
        setShowIOSMessage(true);
      } else {
        setShowIOSMessage(false);
      }
      try {
        const response = await fetch(
          `${serverAddress}/create-checkout-session`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ planType: "colab" }),
          }
        );
        const { url } = await response.json();
        Linking.openURL(url);
      } catch (error) {
        alert(t.errorcheckout);
      }
    } else if (isReady) {
      // Suponiendo que el ID del producto en RevenueCat sea 'colab_monthly'
      const packageToPurchase = offerings["colab_monthly"]; 
  
      if (packageToPurchase) {
        try {
          await purchaseSubscription(packageToPurchase);
          closeModal();
        } catch (error) {
          Alert.alert(
            "Error",
            "No se encontró el paquete de suscripción deseado."
          );
        }
      } else {
        Alert.alert(
          "Error",
          "No se encontró el paquete de suscripción deseado."
        );
      }
    }
  };
  

  return (
    <ScrollView style={styles.scrollViewStyle}>
      <View
        style={styles.container}
        onStartShouldSetResponder={() => true}
        onResponderTerminationRequest={() => false}
      >
        <View style={styles.header}>
          <Image
            source={require("../img/icons/trocalia2.png")}
            style={styles.trocaliaIcon}
          />
          <Text style={styles.colab}>COLAB</Text>
          <TouchableOpacity onPress={closeModal} style={styles.closeButton}>
            <Image
              style={styles.closeIcon}
              source={require("../img/icons/block.png")}
            />
          </TouchableOpacity>
        </View>

        <View style={styles.visibilityContainer}>
          <Text style={styles.visibilidad}>{t.visibilidad}</Text>
        </View>

        <ProfileImage style={styles.profileImageStyle} />

        <View style={styles.content}>
          {t.benefits.map((benefit, index) => (
            <View key={index} style={styles.benefitWrapper}>
              <Animated.View
                style={[
                  styles.benefitContainer,
                  {
                    opacity: benefitsAnimations[index].textOpacity,
                  },
                ]}
              >
                <Animated.Image
                  style={[
                    styles.benefitIcon,
                    {
                      opacity: benefitsAnimations[index].iconOpacity,
                      transform: [
                        {
                          scale: benefitsAnimations[index].iconScale.interpolate(
                            {
                              inputRange: [0, 0.5, 1],
                              outputRange: [0.8, 1.1, 1],
                            }
                          ),
                        },
                      ],
                    },
                  ]}
                  source={imageMap[iconsForBenefits[index]]}
                />
                <Text style={styles.textbenefit}>{benefit}</Text>
              </Animated.View>
            </View>
          ))}
        </View>

        {/* Contenedor del selector de precios */}
        <View style={styles.priceContainer}>
          <Text style={styles.priceValue}>1€</Text> 
          <Text style={styles.textPeriod}>{t.priceMonthlyPeriod}</Text>
        </View>

        <Animated.View
          style={{
            transform: [{ scale: ctaAnimation }],
          }}
        >
          <TouchableOpacity
            style={styles.proButton}
            onPress={async () => {
              if (Platform.OS === "ios" || Platform.OS === "android") {
                await Haptic.impactAsync(Haptic.ImpactFeedbackStyle.Heavy);
              }
              initiateCheckout();
            }}
            activeOpacity={0.8}
          >
            <Text style={styles.proButtonText}>{t.bePro}</Text>
          </TouchableOpacity>
        </Animated.View>

        <View style={styles.linksContainer}>
          <TouchableOpacity
            onPress={() =>
              openLink(
                Platform.OS === "ios"
                  ? "https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                  : "https://trocalia.net/termes"
              )
            }
          >
            <Text style={styles.linkText}>
              {Platform.OS === "ios"
                ? t.termsAndConditionsEULA
                : t.termsAndConditions}
            </Text>
          </TouchableOpacity>
          <Text style={styles.separator}></Text>
          <TouchableOpacity
            onPress={() => openLink("https://trocalia.net/privacitat")}
          >
            <Text style={styles.linkText}>{t.privacyPolicy}</Text>
          </TouchableOpacity>
        </View>
        {Platform.OS === "ios" && (
          <>
            <Text style={styles.restoreInfoText}>{t.yasoypro}</Text>
            <TouchableOpacity
              style={styles.restoreButton}
              onPress={restorePurchases}
            >
              <Text style={styles.restoreButtonText}>{t.restaurar}</Text>
            </TouchableOpacity>
          </>
        )}

        {showIOSMessage && (
          <Text style={styles.iosMessageStyle}>{t.popupBlockerWarning}</Text>
        )}
      </View>

      <View style={styles.bottomSpacer} />
    </ScrollView>
  );
};

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.white,
      justifyContent: "flex-start",
      alignItems: "center",
    },
    header: {
      alignItems: "center",
      paddingHorizontal: 20,
      paddingTop: Platform.OS === "web" ? 14 : Platform.OS === "ios" ? 70 : 30,
    },
    closeButton: {
      position: "absolute",
      right: -80,
      top: Platform.OS === "web" ? 22 : Platform.OS === "ios" ? 55 : 28,
      zIndex: 1,
    },
    closeIcon: {
      width: 30,
      height: 30,
    },
    content: {
      marginTop: 2,
      paddingHorizontal: 0,
      marginBottom: 22,
    },
    benefitWrapper: {
      overflow: "hidden",
    },
    benefitContainer: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 24,
      maxWidth: "94%",
      
    },
    benefitIcon: {
      width: 24,
      height: 24,
      marginRight: 8,
    },
    proButton: {
      backgroundColor: Colors.primary,
      paddingVertical: 14,
      paddingHorizontal: 30,
      borderRadius: 30,
      marginTop: 18,
      shadowColor: Colors.secondary,
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.65,
      shadowRadius: 6,
      elevation: 6,
      marginBottom: 18,
    },
    proButtonText: {
      ...Fonts.poppinsSemiBold16,
      color: Colors.white,
      textAlign: "center",
      textTransform: "uppercase",
    },
    priceContainer: {
      flexDirection: "column", // Mantener en columna
      justifyContent: "center",
      alignItems: "center",
      marginTop: 0,
      marginBottom: 26,
    },
    priceOption: {
      paddingVertical: 10,
      paddingHorizontal: 15,
      borderRadius: 12,
      borderWidth: 2,
      borderColor: Colors.primary,
      backgroundColor: Colors.white,
      shadowColor: Colors.primary,
      shadowOpacity: 0.3,
      shadowOffset: { width: 0, height: 2 },
      shadowRadius: 4,
      elevation: 4,
    },
    profileImageStyle: {
      borderWidth: 3,
      borderColor: Colors.primary,
      marginTop: 10,
      marginBottom: 20,
    },
    trocaliaIcon: {
      width: 150,
      height: 50,
      resizeMode: "contain",
    },
    colab: {
      ...Fonts.poppinsSemiBold16, // Reducir un poco el tamaño
      backgroundColor: Colors.primary,
      color: Colors.white,
      paddingHorizontal: 4,
      paddingVertical: 2,
      borderRadius: 10,
      textAlign: "center",
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: Platform.OS === "web" ? 40 : Platform.OS === "ios" ? 95 : 54,
      right: -48,
    },
    visibilidad: {
      color: Colors.primary,
      ...Fonts.poppinsMedium12,
      fontWeight: "600",
      textAlign: "center",
      marginTop: 0,
      marginBottom: 2,
      textTransform: "uppercase",
      letterSpacing: 2,
    },
    scrollViewStyle: {
      flex: 1,
      backgroundColor: Colors.white,
    },
    extraSpace: {
      height: 90,
    },
    textbenefit: {
      ...Fonts.poppinsRegular16,
      color: Colors.grayDark,
    },
    textPrice: {
      fontSize: 20, // Reducido a un tamaño moderado
      fontWeight: "normal", // Quitar negrita
      color: Colors.grayDark, // Color oscuro para el título
      textAlign: "center", // Centrar el texto
      marginBottom: 5, // Añadir espaciado inferior
    },
    priceValue: {
      fontSize: 48, // Hacerlo mucho más grande para destacar el "1€"
      fontWeight: "bold", // Negrita para el precio
      color: Colors.primary, // Color primario para hacerlo destacar
      textAlign: "center", // Centrado
    },
    textPeriod: {
      fontSize: 18, // Más pequeño para el "cada mes"
      color: Colors.secondary, // Color secundario
      fontWeight: "bold", // Sin negrita
      textAlign: "center", // Centrar el texto
      marginTop: -5, // Ajuste para acercarlo al precio
    },
    separator: {
      height: 40,
      width: 2,
      backgroundColor: Colors.grayMedium,
      marginHorizontal: 10,
    },
    iosMessageStyle: {
      marginTop: 10,
      color: "red",
      textAlign: "center",
      paddingHorizontal: 20,
    },
    linksContainer: {
      flexDirection: "row",
      justifyContent: "center",
      flexWrap: "wrap",
      marginTop: 20,
      marginBottom: 10,
    },
    linkText: {
      color: Colors.primary,
      textDecorationLine: "underline",
      marginHorizontal: 5,
      marginVertical: 10,
      fontSize: 16,
    },
    bottomSpacer: {
      height: 30,
    },
    restoreInfoText: {
      marginVertical: 10,
      textAlign: "center",
      color: Colors.grayDark,
      paddingHorizontal: 20,
    },
    restoreButton: {
      marginVertical: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 10,
      paddingHorizontal: 20,
      alignSelf: "center",
      borderWidth: 2,
      borderColor: Colors.primary,
    },
    restoreButtonText: {
      color: Colors.primary,
      textAlign: "center",
    },
  });
  

export default Colab;
