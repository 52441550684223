import React, { useState, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Modal,
  Image,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import * as Haptics from "expo-haptics";
import Pro from "../components/Pro.js";
import { AppContext } from "../../AppContext";
import NeedRegister from "../selectables/NeedRegister.js";

const translate = {
  ESP: {
    ofrece: "Busco\nquién ofrece",
    necesita: "Busco\nquién demanda",
  },
  CAT: {
    ofrece: "Busco qui\nofereix",
    necesita: "Busco qui demanda",
  },
  ENG: {
    ofrece: "Looking for\nofferers",
    necesita: "Looking for\nseekers",
  },
};

const SelectorOfreceNecesita = ({ onSelect }) => {
  const [selectedButton, setSelectedButton] = useState("ofrece");
  const [proModalVisible, setProModalVisible] = useState(false);
  const { language, userData } = useContext(AppContext);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const t = translate[language];

  const closeProModal = () => {
    setProModalVisible(false);
    if (selectedButton !== "ofrece") {
      setSelectedButton("ofrece");
      onSelect("ofrece");
    }
  };

  const renderNeedRegisterModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isNeedRegisterVisible}
        onRequestClose={() => setIsNeedRegisterVisible(false)}
      >
        <NeedRegister onClose={() => setIsNeedRegisterVisible(false)} />
      </Modal>
    );
  };

  const selectButton = async (button) => {
    setSelectedButton(button);
    if (Platform.OS !== "web") {
      await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    }

    if (button === "necesita" && userData?.data[0]?.tipusUsuari === "Guest") {
      setIsNeedRegisterVisible(true);
      return;
    }

    onSelect(button);
  };

  return (
    <View style={styles.groupParent}>
      {renderNeedRegisterModal()}
      <Modal
        animationType="slide"
        transparent={true}
        visible={proModalVisible}
        onRequestClose={closeProModal}
      >
        <TouchableOpacity
          style={styles.modalOverlay}
          onPress={closeProModal}
          activeOpacity={1}
        >
          <Pro closeModal={closeProModal} />
        </TouchableOpacity>
      </Modal>

      {/* Botón Ofrece */}
      <TouchableOpacity
        onPress={() => selectButton("ofrece")}
        style={[
          styles.button,
          selectedButton === "ofrece" ? styles.selected : styles.unselected,
        ]}
      >
        <Text
          style={[
            selectedButton === "ofrece"
              ? styles.selectedButtonText
              : styles.unselectedButtonText,
          ]}
        >
          {t.ofrece}
        </Text>
        <Image
          style={styles.icon}
          source={require("../img/icons/ofrece.png")}
        />
      </TouchableOpacity>

      {/* Botón Necesita */}
      <TouchableOpacity
        onPress={() => selectButton("necesita")}
        style={[
          styles.button,
          selectedButton === "necesita" ? styles.selected : styles.unselected,
        ]}
      >
        <Text
          style={[
            selectedButton === "necesita"
              ? styles.selectedButtonText
              : styles.unselectedButtonText,
          ]}
        >
          {t.necesita}
        </Text>
        <Image
          style={styles.icon}
          source={require("../img/icons/necesita.png")}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  groupParent: {
    flexDirection: "row",
    width: "100%",
    height: 90, // Altura para garantizar espacio adecuado
    marginTop: 16,
    marginBottom: 14,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 18,
    borderRadius: 32,
    backgroundColor: Colors.primary,
    width: 160, // Asegura que los botones tengan un tamaño adecuado
    height: 75,
    margin: 10,
    ...Platform.select({
      ios: {
        shadowOffset: { width: 0, height: 3 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
      },
      android: {
        elevation: 6,
      },
      web: {
        boxShadow: "0px 4px 8px rgba(0,0,0,0.15)",
      },
    }),
  },
  selectedButtonText: {
    fontSize: 16,
    fontWeight: "600", // Semibold para botón seleccionado
    color: Colors.white,
    lineHeight: 22,
    textAlign: "center",
  },
  unselectedButtonText: {
    fontSize: 16,
    fontWeight: "400", // Regular para botón no seleccionado
    color: Colors.grayMediumDark, // Gris más oscuro para mejor visibilidad
    lineHeight: 22,
    textAlign: "center",
  },
  unselected: {
    backgroundColor: Colors.white,
    borderColor: Colors.grayMedium,
    borderWidth: 2,
  },
  selected: {
    backgroundColor: Colors.primary,
    borderColor: Colors.primary,
    borderWidth: 2,
  },
  icon: {
    width: 28,
    height: 28,
    marginLeft: 12,
    resizeMode: "contain",
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
});

export default SelectorOfreceNecesita;
