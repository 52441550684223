import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, Dimensions, Platform } from 'react-native';
import LottieView from 'lottie-react-native';

const Loading = () => {
  const animation1Ref = useRef(null);
  const animation2Ref = useRef(null);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowAnimation(true);
      if (animation1Ref.current) {
        animation1Ref.current.reset();
        animation1Ref.current.play();
      }
      if (animation2Ref.current) {
        animation2Ref.current.reset();
        animation2Ref.current.play();
      }
    }, 1500); // Temporizador de 3 segundos

    return () => clearTimeout(timer);
  }, []);

  if (!showAnimation) {
    return <View style={styles.container} />;
  }

  return (
    <View style={styles.container}>
      <LottieView 
        ref={animation1Ref}
        source={require('../animations/loading1.json')} 
        autoPlay={false} // No reproducir automáticamente
        loop 
        style={styles.animation}
      />
      <LottieView 
        ref={animation2Ref}
        source={require('../animations/loading2.json')} 
        autoPlay={false} // No reproducir automáticamente
        loop 
        style={[styles.animation, styles.secondAnimation]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
  },
  animation: {
    width: Dimensions.get('window').width * 0.5,
    height: Dimensions.get('window').width * 0.5,
  },
  secondAnimation: {
    marginTop: 20, // Ajuste de margen para la segunda animación
  },
});

export default Loading;
