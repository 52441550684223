import React, { useContext, useState, useEffect } from "react";
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Modal,
  Platform,
  Alert
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext.js";
import { useNavigation } from "@react-navigation/native";
import Pro from "../components/Pro.js";
import NeedRegister from "../selectables/NeedRegister.js";

// Objeto de traducción simplificado
const translate = {
  ESP: {
    chooseLocation: "Ubicación",
    uploadPhoto: "Foto",
    addSkill: "Oferta",
    addNeed: "Demanda",
    goPro: "Pro",
    perfil: "Perfil",
    enhorabuena: "Gracias por tu apoyo",
    proMessage: "¡Ya formas parte de Trocalia PRO!",
  },
  CAT: {
    chooseLocation: "Ubicació",
    uploadPhoto: "Foto",
    addSkill: "Oferta",
    addNeed: "Demanda",
    goPro: "Pro",
    perfil: "Perfil",
    enhorabuena: "Gràcies pel teu recolzament",
    proMessage: "Ja formes part de Trocalia PRO!",
  },
  ENG: {
    chooseLocation: "Location",
    uploadPhoto: "Photo",
    addSkill: "Offer",
    addNeed: "Need",
    goPro: "Pro",
    perfil: "Profile",
    enhorabuena: "Thanks for your support",
    proMessage: "You are now part of Trocalia PRO!",
  },
};

const Gamification = () => {
  const { language, userData, updateTrigger } = useContext(AppContext);
  const [progress, setProgress] = useState(0);
  const [proModalVisible, setProModalVisible] = useState(false);
  const [isNeedRegisterVisible, setIsNeedRegisterVisible] = useState(false);

  const toggleProModal = () => {
    if (userData.data[0].tipusUsuari === "Premium") {
      Alert.alert(translate[language].enhorabuena, translate[language].proMessage);
    } else {
      setProModalVisible(!proModalVisible);
    }
  };
  

  const handlePress = (action) => {
    if (userData?.data[0]?.tipusUsuari === "Guest") {
      setIsNeedRegisterVisible(true);
    } else {
      action();
    }
  };

  const renderNeedRegisterModal = () => {
    return (
      <Modal
        animationType="slide"
        transparent={true}
        visible={isNeedRegisterVisible}
        onRequestClose={() => setIsNeedRegisterVisible(false)}
      >
        <NeedRegister
          isVisible={isNeedRegisterVisible}
          onClose={() => setIsNeedRegisterVisible(false)}
        />
      </Modal>
    );
  };

  useEffect(() => {
    if (!userData || !userData.data || userData.data.length === 0) {
      return; // No hay datos para procesar
    }
    const newProgress = calculateProgress(userData);
    setProgress(newProgress);
  }, [userData, updateTrigger]);

  if (!userData || !userData.data || userData.data.length === 0) {
    return (
      <View style={styles.container}>
        <Text>Loading...</Text>
      </View>
    );
  }

  const progressImages = {
    20: require("../img/gamification/1.png"),
    40: require("../img/gamification/2.png"),
    60: require("../img/gamification/3.png"),
    80: require("../img/gamification/4.png"),
    100: require("../img/gamification/5.png"),
  };

  const getProgressImage = (progress) => {
    const roundedProgress = Math.ceil(progress / 20) * 20;
    return progressImages[roundedProgress];
  };

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        {/* 
        <Text style={styles.text}>
          {`${progress}% ${translate[language].profileCompleted}`} 
        </Text>
        */}
        {/* Comentado para eliminar el texto de progreso */}
        <Modal
          animationType="slide"
          transparent={true}
          visible={proModalVisible}
          onRequestClose={toggleProModal}
        >
          <TouchableOpacity
            style={{ flex: 1 }}
            onPress={toggleProModal}
            activeOpacity={1}
          >
            <Pro closeModal={toggleProModal} />
          </TouchableOpacity>
        </Modal>
        <View style={styles.imageContainer}>
          <Image style={styles.image} source={getProgressImage(progress)} />
        </View>
        {renderNeedRegisterModal()}
        <Component1
          language={language}
          handlePress={handlePress}
          toggleProModal={toggleProModal}
        />
      </View>
    </View>
  );
};

const calculateProgress = (userData) => {
  let progress = 20;

  if (userData.data[0].foto && userData.data[0].presentacio) {
    progress = 40;
  }

  if (userData.data[0].numOfertes && userData.data[0].numOfertes !== "null") {
    progress = 60;
  }

  if (userData.data[0].numDemandes && userData.data[0].numDemandes !== "null") {
    progress = 80;
  }

  if (userData.data[0].tipusUsuari === "Premium") {
    progress = 100;
  }
  return progress;
};

const Component1 = ({ language, handlePress, toggleProModal }) => {
  const navigation = useNavigation();

  const openUbicationModal = () => {
    handlePress(() => {
      const routeName = translate[language].perfil || "Perfil";
      navigation.navigate(routeName, { openLocationModal: true });
    });
  };

  const navigateToProfileScreen = () => {
    handlePress(() => {
      const routeName = translate[language].perfil || "Perfil";
      navigation.navigate(routeName);
    });
  };

  return (
    <View style={styles.component1}>
      <TouchableOpacity
        style={[styles.buttonStyle, { left: "-3%" }]}
        onPress={openUbicationModal}
        hitSlop={{ top: 25, bottom: 0, left: 0, right: 0 }}
      >
        <Text style={[styles.textStyle, { left: -1 }]}>
          {translate[language].chooseLocation}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.buttonStyle, { left: "19%" }]}
        onPress={navigateToProfileScreen}
        hitSlop={{ top: 25, bottom: 0, left: 0, right: 0 }}
      >
        <Text style={[styles.textStyle, { left: -2 }]}>
          {translate[language].uploadPhoto}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.buttonStyle, { left: "40%" }]}
        onPress={navigateToProfileScreen}
        hitSlop={{ top: 25, bottom: 0, left: 0, right: 0 }}
      >
        <Text style={styles.textStyle}>{translate[language].addSkill}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.buttonStyle, { left: "62%" }]}
        onPress={navigateToProfileScreen}
        hitSlop={{ top: 25, bottom: 0, left: 0, right: 0 }}
      >
        <Text style={styles.textStyle}>{translate[language].addNeed}</Text>
      </TouchableOpacity>
      <TouchableOpacity
        style={[styles.buttonStyle, { left: "87%" }]}
        onPress={() => handlePress(toggleProModal)}
        hitSlop={{ top: 25, bottom: 0, left: 0, right: 0 }}
      >
        <Text style={[styles.textStyle, { left: -2 }]}>
          {translate[language].goPro}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    marginBottom: -18,
  },
  contentContainer: {
    alignItems: "flex-start",
    width: 324,
  },
  // text: {
  //   ...Fonts.poppinsRegular14,
  //   color: Colors.black,
  //   marginBottom: -4,
  // }, 
  // Comentado para eliminar el estilo del texto de progreso
  imageContainer: {
    width: 324,
    height: 30,
    marginBottom: -3, // Reducir el espacio entre las imágenes y los textos
  },
  image: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
    zIndex: 1,
  },
  component1: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: 324,
    height: 28,
  },
  textStyle: {
    position: "absolute",
    width: "21%",
    textAlign: "center",
    color: Colors.grayDark,
    ...Fonts.poppinsRegular10,
    zIndex: 99,
  },
  buttonStyle: {
    position: "absolute",
    width: "100%",
    zIndex: 99,
    ...(Platform.OS === "web"
      ? {
          paddingTop: 25,
          marginTop: -25,
        }
      : {}),
  },
});

export default Gamification;
