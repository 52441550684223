import React, { useContext, useState, useEffect } from "react";
import {
  Modal,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  TouchableWithoutFeedback,
  Switch,
  Platform,
  Alert,
  Linking,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";

const NotificationSelector = ({ isVisible, onClose }) => {
  const { token, language, serverAddress, userData } = useContext(AppContext);
  const translations = {
    ESP: {
      title: "Selecciona tus preferencias de notificación",
      email: "Notificaciones por email",
      push: "Notificaciones push",
      description: "¿No quieres perderte nada? Mantente conectado y recibe los mensajes al momento. ¡Es recomendable!",
      allowPush: "PERMITIR NOTIFICACIONES\nen tiempo real",
      webPushMessage: "Para activar notificaciones push debes descargar la App.",
      pushios: "Para activar las Notificaciones descarga nuestra aplicación desde la App Store",
      pushandroid: "Para activar las Notificaciones descarga nuestra aplicación desde Google Play",
      pushPermissionTitle: "Falta un paso",
      pushPermissionDenied: "Debes habilitar los permisos de notificación del dispositivo, ¡te llevamos allí!",
      pushTokenError: "Error al activar las notificaciones push.",
      successMessage: "Ya tienes las notificaciones perfectamente configuradas.",
      apiError: "Error al enviar la información al servidor.",
      requestError: "Error al enviar la solicitud a la API:",
      exito: "Éxito",
    },
    CAT: {
      title: "Selecciona les teves preferències de notificació",
      email: "Notificacions per email",
      push: "Notificacions push",
      description: "No vols perdre't res? Mantén-te connectat i rep els missatges al moment. ¡És recomanable!",
      allowPush: "PERMETRE NOTIFICACIONS\nen temps real",
      webPushMessage: "Per activar notificacions push has de descarregar l'App.",
      pushios: "Per activar les Notificacions descarrega la nostra aplicació des de l'App Store",
      pushandroid: "Per activar les Notificacions descarrega la nostra aplicació des de Google Play",
      pushPermissionTitle: "Falta un pas",
      pushPermissionDenied: "Has d'habilitar els permisos de notificació del dispositiu, t'hi portem!",
      pushTokenError: "Error al activar les notificacions push.",
      successMessage: "Ja tens les notificacions perfectament configurades.",
      apiError: "Error al enviar la informació al servidor.",
      requestError: "Error al enviar la sol·licitud a l'API:",
      exito: "Èxit",
    },
    ENG: {
      title: "Select your notification preferences",
      email: "Email notifications",
      push: "Push notifications",
      description: "Don't want to miss anything? Stay connected and receive messages instantly. Highly recommended!",
      allowPush: "ALLOW NOTIFICATIONS\nin real-time",
      webPushMessage: "To enable push notifications you must download the App.",
      pushios: "To enable Notifications download our app from the App Store",
      pushandroid: "To enable Notifications download our app from Google Play",
      pushPermissionTitle: "One more step",
      pushPermissionDenied: "You need to enable the device's notification permissions, let us take you there!",
      pushTokenError: "Error enabling push notifications.",
      successMessage: "You have successfully configured notifications.",
      apiError: "Error sending information to the server.",
      requestError: "Error sending the request to the API:",
      exito: "Success",
    },
  };

  const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(false);
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState(false);

  useEffect(() => {
    if (userData && userData.data && userData.data.length > 0) {
      const { notif_email, notif_push } = userData.data[0];
      setEmailNotificationsEnabled(notif_email === 1);
      setPushNotificationsEnabled(notif_push === 1);
    } else {
      setEmailNotificationsEnabled(false);
      setPushNotificationsEnabled(false);
    }
  }, [userData]);

  const updateNotificationSetting = async (settingType, newValue) => {
    const actuacioValue = newValue ? 1 : 0;
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "GN",
            notif_email: settingType === "email" ? actuacioValue : emailNotificationsEnabled ? 1 : 0,
            notif_push: settingType === "push" ? actuacioValue : pushNotificationsEnabled ? 1 : 0,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(translations[language].apiError);
      }
    } catch (error) {
      Alert.alert("Error", translations[language].requestError + " " + error.message);
    }
  };

  const detectOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    } else if (/android/i.test(userAgent)) {
      return "Android";
    } else {
      return "desktop";
    }
  };

  const openSettings = () => {
    if (Platform.OS === 'ios') {
      Linking.openURL('app-settings:');
    } else {
      Linking.openSettings();
    }
  };

  const registerForPushNotificationsAsync = async () => {
    if (!Device.isDevice) {
      alert(translations[language].webPushMessage);
      return;
    }

    if (Platform.OS === "web") {
      const os = detectOS();
      if (os === "iOS") {
        alert(translations[language].pushios);
        window.open("https://apps.apple.com/es/app/trocalia/id6478570314");
      } else if (os === "Android") {
        alert(translations[language].pushandroid);
        window.open("https://play.google.com/store/apps/details?id=com.trocalia");
      } else {
        alert(translations[language].webPushMessage);
      }
      return;
    }

    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    } else {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      Alert.alert(translations[language].pushPermissionTitle, translations[language].pushPermissionDenied, [
        { text: 'OK', onPress: () => openSettings() },
      ]);
      return;
    }

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (await Notifications.getExpoPushTokenAsync({ projectId })).data;
      sendPushTokenToServer(pushToken);
    } catch (error) {
      //console.log("Error al obtener las notificaciones push:", error);
      Alert.alert("Error", translations[language].pushTokenError + " " + error.message);
    }
  };

  const sendPushTokenToServer = async (pushToken) => {
    if (!token) {
      //Alert.alert("Error", "Información no disponible, no se enviarán las notificaciones al servidor.");
      return;
    }

    const url = `${serverAddress}/api/v1/perfils/actuacions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });

      if (response.ok) {
        Alert.alert(translations[language].exito, translations[language].successMessage);
      } else {
        throw new Error(translations[language].apiError);
      }
    } catch (error) {
      //console.log("Error al enviar la información al servidor:", error);
      Alert.alert("Error", translations[language].apiError + " " + error.message);
    }
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.centeredView}>
          <TouchableWithoutFeedback>
            <View style={styles.modalView}>
              <Text style={styles.modalText}>
                {translations[language].title}
              </Text>

              <View style={styles.optionContainer}>
                <Text>{translations[language].email}</Text>
                <Switch
                  trackColor={{ false: "#767577", true: Colors.primary }}
                  thumbColor={
                    emailNotificationsEnabled ? Colors.secondary : "#f4f3f4"
                  }
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={(newValue) => {
                    setEmailNotificationsEnabled(newValue);
                    updateNotificationSetting("email", newValue ? 1 : 0);
                  }}
                  value={emailNotificationsEnabled}
                  style={styles.switch}
                />
              </View>
              <View style={styles.optionContainer}>
                <Text>{translations[language].push}</Text>
                <Switch
                  trackColor={{ false: "#767577", true: Colors.primary }}
                  thumbColor={
                    pushNotificationsEnabled ? Colors.secondary : "#f4f3f4"
                  }
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={(newValue) => {
                    setPushNotificationsEnabled(newValue);
                    updateNotificationSetting("push", newValue ? 1 : 0);
                    if (newValue) {
                      registerForPushNotificationsAsync();
                    }
                  }}
                  value={pushNotificationsEnabled}
                  style={styles.switch}
                />
              </View>
              <Text style={styles.description}>
                {translations[language].description.split('¡')[0]}
                <Text style={styles.semiBoldText}>
                  ¡{translations[language].description.split('¡')[1]}
                </Text>
              </Text>
              <TouchableOpacity
                style={styles.allowButton}
                onPress={registerForPushNotificationsAsync}
              >
                <Text style={styles.allowButtonText}>
                  {translations[language].allowPush.split('\n')[0]}
                </Text>
                <Text style={styles.allowButtonSubText}>
                  {translations[language].allowPush.split('\n')[1]}
                </Text>
              </TouchableOpacity>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    fontSize: 19,
    fontWeight: "bold",
    marginBottom: 24,
    color: Colors.primary,
  },
  optionContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: 28,
  },
  switch: {},
  description: {
    marginBottom: 20,
    textAlign: "center",
    color: Colors.black,
    fontSize: 14,
  },
  semiBoldText: {
    fontWeight: "600",
  },
  allowButton: {
    backgroundColor: Colors.primary,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignItems: 'center',
  },
  allowButtonText: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
  },
  allowButtonSubText: {
    color: "white",
    textAlign: "center",
    textTransform: "none",
  },
});

export default NotificationSelector;
