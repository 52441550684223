import React, { useEffect, useState, useContext } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  Image,
  Text,
  Dimensions,
  Platform,
  TouchableOpacity,
} from "react-native";
import Svg, { Path } from "react-native-svg";
import trocaliaIcon from "../img/icons/trocalia.png";
import { AppContext } from "../../AppContext";
import { Colors, Fonts } from "../styles/styles";
import { useNavigation } from "@react-navigation/native";
const translate = {
  ESP: {
    back: "Volver atrás",
  },
  CAT: {
    back: "Tornar enrere",
  },
  ENG: {
    back: "Go back",
  },
};

export default function Faq() {
  const [faqData, setFaqData] = useState("");
  const { serverAddress, language } = useContext(AppContext);
  const { width } = Dimensions.get("window");
  const svgWidth = width;
  const svgHeight = 180;
  const navigation = useNavigation();

  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        const response = await fetch(`${serverAddress}/faq`);
        const data = await response.json();
        setFaqData(data); // Guarda directamente los datos del JSON
      } catch (error) {
        //console.error("Error fetching FAQ data:", error);
      }
    };
    fetchFaqData();
  }, []);

  const renderFaq = () => {
    const faqForLanguage = faqData[language] || {};
    return Object.entries(faqForLanguage).map(([key, value], index) => {
      return (
        <View key={index} style={styles.termSection}>
          <Text style={styles.termTitle}>{key}</Text>
          <Text style={styles.termContent}>{value}</Text>
        </View>
      );
    });
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <View style={styles.blueHeader}>
        <Svg height={svgHeight} width={svgWidth}>
          <Path
            d={`M0,0 L${svgWidth},0 L${svgWidth},160 C${svgWidth},160 ${
              svgWidth / 2
            },200 0,160 Z`}
            fill={Colors.primary}
          />
        </Svg>
        <Image style={styles.logo} resizeMode="cover" source={trocaliaIcon} />
      </View>
      {Platform.OS === "web" && (
        <View style={styles.backButtonContainer}>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Text style={styles.backButtonText}>
              {translate[language].back}
            </Text>
          </TouchableOpacity>
        </View>
      )}
      <View style={styles.content}>
        {Object.keys(faqData).length ? renderFaq() : <Text>Cargando...</Text>}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: "#FFFFFF",
  },
  blueHeader: {
    width: "100%",
    height: 120,
    marginTop: -40,
    marginBottom: 60,
    backgroundColor: Colors.primary,
    zIndex: 0,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    alignItems: "center",
    ...Platform.select({
      web: {
        marginTop: 20,
        marginBottom: 20,
      },
    }),
  },
  trocaliaContainer: {
    marginTop: 20,
    alignItems: "center",
  },
  logo: {
    width: 240,
    height: 50,
    top: -80,
    zIndex: 99,
    ...Platform.select({
      web: {
        top: -25,
      },
    }),
  },
  content: {
    padding: 20,
  },
  text: {
    fontSize: 16,
    color: "#000",
  },
  termSection: {
    marginBottom: 20,
  },
  termTitle: {
    ...Fonts.poppinsMedium14,
    color: Colors.grayDark,
  },
  termContent: {
    ...Fonts.poppinsRegular14,
    color: Colors.grayDark,
  },
  backButtonContainer: {
    width: "100%",
    padding: 0,
    alignItems: "flex-start",
    backgroundColor: "#FFFFFF",
    zIndex: 99,
  },
  backButtonText: {
    color: Colors.primary,
    textDecorationLine: "underline",
    fontSize: 16,
    zIndex: 99,
  },
});
