import React, { useState, useContext } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import * as Haptics from "expo-haptics";
import { AppContext } from "../../AppContext";

const translate = {
  ESP: {
    servicios: "Servicios",
    objetos: "Objetos",
  },
  CAT: {
    servicios: "Serveis",
    objetos: "Objectes",
  },
  ENG: {
    servicios: "Services",
    objetos: "Objects",
  },
};

const SelectorServiciosObjetos = ({ onSelect }) => {
  const [selectedOption, setSelectedOption] = useState("servicios");
  const { language } = useContext(AppContext);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    if (onSelect) {
      onSelect(option);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.selector}>
        <TouchableOpacity
          style={[
            selectedOption === "objetos"
              ? styles.optionContainerSelected
              : styles.optionContainer,
            
          ]}
          onPress={() => handleOptionSelect("objetos")}
        >
          <Text
            style={
              selectedOption === "objetos"
                ? styles.optionSelected
                : styles.optionInactive
            }
          >
            {translate[language].objetos}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            selectedOption === "servicios"
              ? styles.optionContainerSelected
              : styles.optionContainer,
              styles.marginLeft,
          ]}
          onPress={() => handleOptionSelect("servicios")}
        >
          <Text
            style={
              selectedOption === "servicios"
                ? styles.optionSelected
                : styles.optionInactive
            }
          >
            {translate[language].servicios}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
  },
  selector: {
    flexDirection: "row",
    marginBottom: 6,
  },
  optionInactive: {
    ...Fonts.poppinsRegular16,
    color: Colors.inactive,
    paddingVertical: 8,
  },
  optionSelected: {
    ...Fonts.poppinsRegular16,
    color: Colors.primary,
    paddingVertical: 8,
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderColor: "transparent",
    paddingBottom: 1,
  },
  optionContainerSelected: {
    borderBottomWidth: 2,
    borderColor: Colors.primary,
    paddingBottom: 0,
  },
  marginLeft: {
    marginLeft: 30,
  },
});

export default SelectorServiciosObjetos;
