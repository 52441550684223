import React, { useContext } from "react";
import { View, Text, StyleSheet, Platform } from 'react-native';
import { Colors, Fonts } from '../styles/styles';
import { AppContext } from '../../AppContext';

const messages = {
  ESP: {
    mensajes: "¡Vaya! Parece que aún no tienes ningún mensaje.",
    notificaciones: "Sin notificaciones nuevas por el momento.",
    favoritos: "¡Aún no has agregado nada a tus favoritos!",
    ofertas: "Parece que este usuario aún no ha publicado ofertas.",
    necesidades: "Parece que este usuario aún no ha publicado demandas.",
    valoraciones: "Este usuario aún no tiene valoraciones."
  },
  CAT: {
    mensajes: "Oh, sembla que encara no tens cap missatge.",
    notificaciones: "Sense notificacions noves per ara.",
    favoritos: "Encara no has afegit res als teus preferits.",
    ofertas: "Sembla que aquest usuari encara no ha publicat ofertes.",
    necesidades: "Sembla que aquest usuari encara no ha publicat demandes.",
    valoraciones: "Aquest usuari encara no té valoracions."
  },
  ENG: {
    mensajes: "Oops! It looks like you don't have any messages yet.",
    notificaciones: "No new notifications at the moment.",
    favoritos: "You haven't added anything to your favorites yet.",
    ofertas: "It looks like this user hasn't posted any offers yet.",
    necesidades: "It looks like this user hasn't posted any needs yet.",
    valoraciones: "This user doesn't have any reviews yet."
  }
};

const EmptyContent = ({ contentType }) => {
  const { language } = useContext(AppContext);

  const getMessage = () => {
    return messages[language][contentType] || "";
  };

  return (
    <View style={styles.frameScreen}>
      <View style={styles.groupWrapper}>
        <View style={styles.group}>
          <View style={styles.frame}>
            <Text style={styles.text}>{getMessage()}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  frameScreen: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: 14,
  },
  groupWrapper: {
    height: 'auto', 
    width: '100%',
    paddingHorizontal: 20, // Ajustar este valor si es necesario
  },
  group: {
    height: 'auto', 
    alignItems: 'center', // Centrar el contenido horizontalmente
  },
  frame: {
    flexDirection: 'column',
    backgroundColor: Colors.grayLight,
    width: 340,
    borderRadius: 16,
    borderColor: Colors.grayLighter,
    borderWidth: 1,
    marginBottom: 16,
    padding: 16,
    /*
    ...Platform.select({
        ios: {
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            shadowOffset: {width: 1, height: 1},
            shadowOpacity: 1,
            shadowRadius: 6,
        },
        android: {
            elevation: 6,
        },
        default: { 
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 6,
        shadowOpacity: 1,
        },
    }),
    */
  },
  text: {
    ...Fonts.poppinsRegular16,
    color: Colors.black,
    alignSelf: 'flex-start',
  },
});

export default EmptyContent;
