import React, { useState, useContext, useEffect } from "react";
import { View, Text, Image, TouchableOpacity, StyleSheet } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Colors, Fonts } from "../styles/styles.js";
import imageMap from "../db/imageMap";
import { DataContext } from "../../DataContext";
import { AppContext } from "../../AppContext";

const VerticalList = ({ option, idCat }) => {
  const navigation = useNavigation();
  const { language } = useContext(AppContext);
  const { data } = useContext(DataContext);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");

  useEffect(() => {
    setSelectedCategory(null);
    setSelectedCategoryName("");
  }, [option]);

  const handleItemPress = (item) => {
    if (option === "servicios" && item.subcategorias && !idCat) {
      setSelectedCategory(item.subcategorias);
      setSelectedCategoryName(getItemName(item));
    } else {
      navigation.navigate("CategoryDetails", { category: item.id_subcat });
    }
  };

  const handleBackToCategories = () => {
    setSelectedCategory(null);
  };

  const getItemName = (item) => {
    return language === "CAT"
      ? item.nombre_CAT
      : language === "ENG"
      ? item.nombre_ENG
      : item.nombre;
  };

  let itemsToDisplay = selectedCategory || [];

  if (!selectedCategory) {
    if (idCat) {
      const categoriaServicio = data.tipo
        .find((t) => t.tipo === "Servicios")
        .categorias.find((cat) => cat.id_cat === idCat);
      itemsToDisplay = categoriaServicio ? categoriaServicio.subcategorias : [];
    } else {
      itemsToDisplay =
        option === "servicios"
          ? data.tipo.find((t) => t.tipo === "Servicios").categorias
          : data.tipo.find((t) => t.tipo === "Objetos").categorias;
    }
  }

  if (!itemsToDisplay || itemsToDisplay.length === 0) {
    return <Text>No hay subcategorías disponibles</Text>;
  }

  return (
    <View style={styles.container}>
      {selectedCategory && (
        <TouchableOpacity onPress={handleBackToCategories} style={styles.header}>
          <Image style={styles.backIcon} source={require("../img/icons/back2.png")} />
          <Text style={styles.categoryName}>{selectedCategoryName}</Text>
        </TouchableOpacity>
      )}
      {itemsToDisplay.map((item, index) => (
        <TouchableOpacity key={index} onPress={() => handleItemPress(item)}>
          <View style={styles.itemContainer}>
            <Image style={styles.itemIcon} source={imageMap[item.icono]} />
            <Text style={styles.itemText}>{getItemName(item)}</Text>
          </View>
          {index !== itemsToDisplay.length - 1 && <View style={styles.separator} />}
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(177, 217, 244, 0.2)",
    borderRadius: 16,
    padding: 16,
    marginBottom:8,
    marginTop:8,
    borderColor: Colors.grayLighter,
    borderWidth: 1,
  },
  itemIcon: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  itemText: {
    ...Fonts.poppinsMedium18,
    color: Colors.grayDark,
    flex: 1,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.grayMedium,
    marginVertical: 10,
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  backIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  categoryName: {
    ...Fonts.poppinsMedium14,
    color: Colors.primary,
  },
});

export default VerticalList;
