import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  Animated,
  StyleSheet,
  Text,
  View,
  Platform,
  Image,
} from "react-native";
import NetInfo from "@react-native-community/netinfo";
import { Colors, Fonts } from "./src/styles/styles";

const ToastContext = createContext();

const ToastProvider = ({ children }) => {
  const [isToastVisible, setToastVisible] = useState(false);
  const [isConnectionError, setConnectionError] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(""); // Almacenar el mensaje de notificación
  // const fadeAnim = useRef(new Animated.Value(0)).current;
  const translateY = useRef(new Animated.Value(-100)).current;

  const showMessageNotification = useCallback((message) => {
    setNotificationMessage(message); // Configura el mensaje de la notificación
    setConnectionError(false); // Asegura que no se interprete como error de conexión
    setToastVisible(true); // Muestra la tostada
  }, []);

  const showConnectionError = useCallback(() => {
    setConnectionError(true);
    setToastVisible(true);
  }, []);

  const showActionError = useCallback(() => {
    setConnectionError(false);
    setToastVisible(true);
  }, []);

  const hideToast = useCallback(() => {
    setToastVisible(false);
  }, []);

  useEffect(() => {
    if (isToastVisible) {
      // Anima el toast para que se deslice hacia abajo
      Animated.timing(translateY, {
        toValue: Platform.OS === "web" ? 20 : 20, // Posición final del toast
        duration: 500,
        useNativeDriver: true,
      }).start();

      const timer = setTimeout(() => {
        // Anima el toast para que se deslice hacia arriba y desaparezca
        Animated.timing(translateY, {
          toValue: -100, // Mueve el toast fuera de pantalla
          duration: 500,
          useNativeDriver: true,
        }).start(hideToast);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isToastVisible, translateY, hideToast]);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener((state) => {
      if (!state.isConnected) {
        showConnectionError();
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <ToastContext.Provider
      value={{ showConnectionError, showActionError, showMessageNotification }}
    >
      {children}
      {isToastVisible && (
        <Animated.View
          style={[
            styles.toast,
            { transform: [{ translateY }] }, // Aplica el desplazamiento vertical
          ]}
        >
          <Text style={styles.toastText}>
            {isConnectionError
              ? "No hay conexión a internet."
              : notificationMessage}
          </Text>
          <Image source={require("./src/img/logo.png")} style={styles.logo} />
        </Animated.View>
      )}
    </ToastContext.Provider>
  );
};

const styles = StyleSheet.create({
  toast: {
    position: "absolute",
    top: Platform.OS === "web" ? 20 : 20,
    left: 0,
    right: 0,
    backgroundColor: Colors.white,
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: Colors.white,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 9999,
  },
  toastText: {
    color: Colors.primary,
    flex: 1, // Permite que el texto no ocupe todo el ancho si se añade un logo
    ...Fonts.poppinsMedium14,
  },
  // Asumiendo que quieres añadir un logo
  logo: {
    width: 40,
    height: 40,
    marginLeft: 10,
  },
});

export { ToastContext, ToastProvider };
