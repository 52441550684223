import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Dimensions, Image, Platform, TouchableOpacity, Text } from 'react-native';
import { Fonts, Colors } from '../styles/styles.js'; // 
import addImage from '../img/icons/add2.png';

const Nodata = ({ message, onNavigate, styleType }) => {
    const styles = styleType === 'card' ? cardStyles : defaultStyles;
  
    return (
      <TouchableOpacity onPress={onNavigate} style={styles.container}>
        <Text style={styles.text}>{message}</Text>
        <Image source={addImage} style={styles.image} />
     
      </TouchableOpacity>

  );
};

const defaultStyles = StyleSheet.create({
  container: {
    width: 340,
    height: 67,
    marginBottom: 20,
    marginTop: 10,
    borderRadius: 16,
    backgroundColor: Colors.primary,
    shadowColor: Colors.primary,
    shadowOffset: {
      width: 3,
      height: 4,
    },
    shadowOpacity: 0.5,
    shadowRadius: 6,
    alignItems: 'center',
    ...Platform.select({
      android: {
        elevation: 12,
      },
    }),
  },
  text: {
    ...Fonts.poppinsMedium16,
    color: Colors.white,
    lineHeight: 16 * 1.35,
    position: 'absolute',
    top: 9,
    left: 14,
    width: 272,
  },
  image: {
    height: 34,
    width: 34,
    position: 'absolute',
    top: 15,
    left: 295,
  },
  button: {
    // Estilos para el botón (ajustar según demanda)
  },
  buttonText: {
    // Estilos para el texto del botón (ajustar según demanda)
  },
});

const cardStyles = StyleSheet.create({
  container: {
    width: 143,
    height: 227,
    marginRight: 16,
    marginBottom: 15,
    marginTop: 8,
    // otros estilos específicos para el estilo 'card'
  },
  text: {
    // Estilos del texto para el estilo 'card'
  },
  image: {
    // Estilos de la imagen para el estilo 'card'
  },
  button: {
    // Estilos del botón para el estilo 'card'
  },
  buttonText: {
    // Estilos del texto del botón para el estilo 'card'
  },
});

export default Nodata;
