import React, { createContext, useState, useEffect } from "react";

export const DataContext = createContext();

export const DataJsonProvider = ({ children, serverAddress }) => {
  const [data, setData] = useState(null);
  const [restrictedWords, setRestrictedWords] = useState([]);

  useEffect(() => {
    // Función para obtener data.json
    const fetchDataJs = async () => {
      try {
        const response = await fetch(`${serverAddress}/data`);
        if (!response.ok) {
          throw new Error("Error al obtener los datos");
        }
        const jsonData = await response.json();
        setData(jsonData); // Asigna los datos recibidos al estado data
      } catch (error) {
        //console.error("Error al obtener los datos de data.json:", error);
      }
    };

    // Función para obtener restrictedWords.json
    const fetchRestrictedWords = async () => {
      try {
        const response = await fetch(`${serverAddress}/restricted-words`);
        if (!response.ok) {
          throw new Error("Error al obtener las palabras restringidas");
        }
        const jsonWords = await response.json();
        setRestrictedWords(jsonWords.words); // Asegúrate de que la estructura del JSON coincide
      } catch (error) {
        //console.error("Error al obtener las palabras restringidas:", error);
      }
    };

    fetchDataJs(); // Llama a la función para cargar los datos
    fetchRestrictedWords(); // Llama a la función para cargar las palabras restringidas
  }, [serverAddress]); // Dependencia a serverAddress para reaccionar a su cambio

  return (
    <DataContext.Provider value={{ data, restrictedWords }}>
      {children}
    </DataContext.Provider>
  );
};
