import React, { useState, useContext } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  ScrollView,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import { useNavigation } from "@react-navigation/native";
import * as Haptics from "expo-haptics";
import { AppContext } from "../../AppContext";
import { DataContext } from "../../DataContext";
import imageMap from "../db/imageMap";

const translate = {
  ESP: {
    servicios: "Servicios",
    objetos: "Objetos",
  },
  CAT: {
    servicios: "Serveis",
    objetos: "Objectes",
  },
  ENG: {
    servicios: "Services",
    objetos: "Objects",
  },
};

const ServiciosObjetos = () => {
  const [selectedTipo, setSelectedTipo] = useState("Servicios");
  const navigation = useNavigation();
  const { data } = useContext(DataContext);
  const { language } = useContext(AppContext);

  const handleTipoSelect = (tipo) => {
    setSelectedTipo(tipo);
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  };

  const handleCategoryPress = (categoria) => {
    const nombreCategoria = getNombreCategoria(categoria);
    if (categoria.subcategorias) {
      // Pasar tanto el id_cat como el nombre de la categoría
      navigation.navigate("SearchScreen", { id_cat: categoria.id_cat, categoryName: nombreCategoria });
    } else {
      // Para objetos, navegar a CategoryDetails
      navigation.navigate("CategoryDetails", { category: categoria.id_subcat });
    }
  };

  const getNombreCategoria = (categoria) => {
    return language === "CAT"
      ? categoria.nombre_CAT
      : language === "ENG"
      ? categoria.nombre_ENG
      : categoria.nombre;
  };

  const renderComponent = () => {
    if (!data) return null;

    const currentCategorias =
      data.tipo.find((t) => t.tipo === selectedTipo)?.categorias || [];

    return (
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.contentContainer}
      >
        {currentCategorias.map((categoria, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => handleCategoryPress(categoria)}
            >
              <View style={styles.item}>
                <Image
                  source={imageMap[categoria.icono]}
                  style={styles.icon}
                  resizeMode="cover"
                />
                <Text style={styles.itemText}>
                  {getNombreCategoria(categoria)}
                </Text>
              </View>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.selector}>
        {["Servicios", "Objetos"].map((tipo) => (
          <View
            key={tipo}
            style={[
              selectedTipo === tipo
                ? styles.optionContainerSelected
                : styles.optionContainer,
              tipo === "Objetos" && styles.marginLeft,
            ]}
          >
            <Text
              style={
                selectedTipo === tipo
                  ? styles.optionSelected
                  : styles.optionInactive
              }
              onPress={() => handleTipoSelect(tipo)}
            >
              {translate[language][tipo.toLowerCase()]}
            </Text>
          </View>
        ))}
      </View>
      {renderComponent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 20,
    marginBottom: 0,
  },
  selector: {
    flexDirection: "row",
    marginBottom: 8,
    paddingLeft: 10,
  },
  optionInactive: {
    ...Fonts.poppinsRegular16,
    color: Colors.inactive,
    paddingVertical: 5, // Reducido el padding vertical
  },
  optionSelected: {
    ...Fonts.poppinsRegular16,
    color: Colors.primary,
    paddingVertical: 5, // Reducido el padding vertical
    marginBottom: -2, // Añadido margen negativo
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderColor: "transparent",
    paddingBottom: 0,
  },
  optionContainerSelected: {
    borderBottomWidth: 2,
    borderColor: Colors.primary,
    paddingBottom: 0,
  },
  contentContainer: {
    paddingVertical: 10,
    paddingLeft: 10,
    marginLeft: 0,
  },
  item: {
    alignItems: "center",
    marginRight: 0,
  },
  icon: {
    width: 26,
    height: 26,
  },
  itemText: {
    ...Fonts.poppinsRegular12,
    fontSize: 12,
    color: Colors.black,
    marginTop: 6,
    textAlign: "center",
    width: 83,
  },
  marginLeft: {
    marginLeft: 30,
  },
});

export default ServiciosObjetos;

