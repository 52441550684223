import React, { useContext } from "react";
import { Modal, View, Text, TouchableOpacity, StyleSheet, TouchableWithoutFeedback } from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";

const LanguageSelector = ({ isVisible, onClose }) => {
  const { language, updateUserLanguage } = useContext(AppContext);

  const translations = {
    ESP: "Selecciona tu idioma",
    CAT: "Selecciona el teu idioma",
    ENG: "Select your language",
  };

  const languageOptions = {
    CAT: "CATALÀ",
    ESP: "ESPAÑOL",
    ENG: "ENGLISH",
  };

  const handleLanguageChange = async (language) => {
    await updateUserLanguage(language);
    onClose();
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{translations[language]}</Text>
            {Object.entries(languageOptions).map(([key, label]) => (
              <TouchableOpacity
                key={key}
                style={styles.button}
                onPress={() => handleLanguageChange(key)}
              >
                <Text style={styles.textStyle}>{label}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  modalView: {
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },
  modalText: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 24,
    color: Colors.primary,
  },
  button: {
    borderRadius: 20,
    paddingVertical: 10,
    paddingHorizontal: 20,
    elevation: 2,
    backgroundColor: Colors.primary,
    marginHorizontal: 5,
    marginBottom: 18,
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
    ...Fonts.poppinsRegular16,
  },
});

export default LanguageSelector;
