import React, { useState, useContext } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import * as Haptics from "expo-haptics";
import { AppContext } from "../../AppContext"; 
const translate = {
  ESP: {
    ofrece: "Ofreces",
    necesita: "Demandas",
    valoraciones: "Valoraciones",
  },
  CAT: {
    ofrece: "Ofereixes",
    necesita: "Demandes",
    valoraciones: "Valoracions",
  },
  ENG: {
    ofrece: "Offering",
    necesita: "Seeking",
    valoraciones: "Reviews",
  },
};

const SelectorPresentation = ({ onSelect, selectedOption }) => {
  // const [selectedOption, setSelectedOption] = useState("ofrece");

  const { language } = useContext(AppContext); 

  const handleOptionSelect = (option) => {
    if (onSelect) {
      onSelect(option);
    }

    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.selector}>
        {Object.keys(translate[language]).map((option, index) => (
          <TouchableOpacity
            key={option}
            style={[
              selectedOption === option
                ? styles.optionContainerSelected
                : styles.optionContainer,
              index !== 0 && styles.marginLeft,
            ]}
            onPress={() => handleOptionSelect(option)}
          >
            <Text
              style={
                selectedOption === option
                  ? styles.optionSelected
                  : styles.optionInactive
              }
            >
              {translate[language][option]}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
  },
  selector: {
    flexDirection: "row",
    marginBottom: 6,
  },
  optionInactive: {
    ...Fonts.poppinsRegular16,
    color: Colors.inactive,
    paddingVertical: 8,
  },
  optionSelected: {
    ...Fonts.poppinsRegular16,
    color: Colors.primary,
    paddingVertical: 8,
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderColor: "transparent",
    paddingBottom: 1,
  },
  optionContainerSelected: {
    borderBottomWidth: 2,
    borderColor: Colors.primary,
    paddingBottom: 0,
  },
  marginLeft: {
    marginLeft: 40,
  },
});

export default SelectorPresentation;
