import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  KeyboardAvoidingView,
  Platform,
  TextInput,
  Modal,
} from "react-native";
import { AppContext } from "../../AppContext";
import * as Haptics from "expo-haptics";
import { useNavigation } from "@react-navigation/native";
import { TouchableWithoutFeedback, Keyboard } from "react-native";

// import { navigationRef } from "../../App"
import { Colors, Fonts } from "../styles/styles";

const translations = {
  ESP: {
    postalCodeError: "El código postal debe ser de 5 dígitos numéricos.",
    postalCodeNotFoundError: "Código postal no encontrado.",
    postalCodeValidationError: "Error al validar el código postal.",
    changeZone: "Cambiar de zona",
    updatePostalCode: "Modificarla",
    yourCurrentZone: "Tu zona actual",
    postalCodeOk: "¡Código postal encontrado!",
    postalCode: "Código postal",
  },
  CAT: {
    postalCodeError: "El codi postal ha de tenir 5 dígits numèrics.",
    postalCodeNotFoundError: "Codi postal no trobat.",
    postalCodeValidationError: "Error en validar el codi postal.",
    changeZone: "Canviar de zona",
    updatePostalCode: "Modificar",
    yourCurrentZone: "La teva zona actual",
    postalCodeOk: "Codi postal trobat!",
    postalCode: "Codi postal",
  },
  ENG: {
    postalCodeError: "Postal code must be 5 numeric digits.",
    postalCodeNotFoundError: "Postal code not found.",
    postalCodeValidationError: "Error validating postal code.",
    changeZone: "Change zone",
    updatePostalCode: "Update",
    yourCurrentZone: "Your current zone",
    postalCodeOk: "Postal code found!",
    postalCode: "Postal code",
  },
};

const Ubication = ({ hideModal }) => {
  const [postalCode, setPostalCode] = useState("");
  const { token } = useContext(AppContext);
  const [postalCodeError, setPostalCodeError] = useState("");
  const [postalCodeOk, setPostalCodeOk] = useState("");
  const { serverAddress, userData, language } = useContext(AppContext);
  const [postalCodeTimer, setPostalCodeTimer] = useState(null);
  const { updateToken } = useContext(AppContext);
  const navigation = useNavigation();

  const t = translations[language];

  const validatePostalCode = async (code) => {
    if (postalCodeTimer) {
      clearTimeout(postalCodeTimer);
    }

    if (code.length === 5) {
      // Validación inmediata para 5 dígitos
      validateCode(code);
    } else {
      // Temporizador para menos de 5 dígitos
      const newTimer = setTimeout(() => {
        setPostalCodeError(t.postalCodeError);
        setPostalCodeOk(""); 
      }, 3500);

      setPostalCodeTimer(newTimer);
    }
  };

  const validateCode = async (code) => {
    try {
      const response = await fetch(
        `${serverAddress}/api/v1/auxiliars/zones/${code}`
      );

      if (!response.ok) {
        throw new Error("Respuesta no exitosa del servidor");
      }

      const contentType = response.headers.get("content-type");
      if (!response.ok) {
        throw new Error("serverResponseError");
      }

      const data = await response.json();
      if (data.status === "OK") {
        setPostalCodeError("");
        setPostalCodeOk(t.postalCodeOk);
      } else {
        setPostalCodeError(t.postalCodeNotFoundError); 
        setPostalCodeOk("");
      }
    } catch (error) {
      //console.error("Error al validar el código postal:", error);
      if (error.message === "serverResponseError") {
        setPostalCodeError(t.postalCodeValidationError); 
      } else {
        setPostalCodeError("Error al validar el código postal.");
      }
      setPostalCodeOk("");
    }
  };

  const handleUpdatePostalCode = async () => {
    if (postalCode.length !== 5 || postalCodeError) {
      alert("Por favor, introduce un código postal válido.");
      return;
    }

    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }

    try {
      const response = await fetch(
        `${serverAddress}/api/v1/perfils/actuacions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            actuacio: "CZ",
            novaZona: postalCode.toString(),
          }),
        }
      );

      const data = await response.json();
      if (response.ok) {
        const newToken = data.data;
        //console.log("Token antes de actualizar:", token);
        updateToken(newToken);
        //console.log("Nuevo token recibido y actualizado:", newToken);
        hideModal();
        navigation.navigate("Home");
      } else {
        //console.error("Error al cambiar la zona", data);
      }
    } catch (error) {
      //console.error("Error de red o del servidor", error);
    }
  };

  const isWeb = Platform.OS === "web";

  const dismissKeyboard = () => {
    if (!isWeb) {
      Keyboard.dismiss();
    }
  };

  return (
    <TouchableWithoutFeedback onPress={dismissKeyboard} accessible={!isWeb}>
      <View style={styles.centerContainer}>
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "position" : "height"}
          keyboardVerticalOffset={Platform.OS === "ios" ? 60 : 0}
          style={styles.rectangleParent}
        >
          <View style={styles.frameChild}>
            <TouchableOpacity
              onPress={hideModal}
              style={styles.blockIconContainer}
            >
              <Image
                style={styles.blockIcon}
                source={require("../img/icons/block.png")}
              />
            </TouchableOpacity>
            <Text style={[Fonts.poppinsMedium18, styles.tuDireccinO]}>
              {t.yourCurrentZone}
            </Text>
            <Text style={[Fonts.poppinsRegular16, styles.santAndreu]}>
              {userData?.data[0]?.zona}
            </Text>
            <Image
              style={styles.pinIcon}
              source={require("../img/icons/location.png")}
            />
            <View style={styles.buttons}>
              <TouchableOpacity onPress={handleUpdatePostalCode}>
                <Text style={Fonts.poppinsSemiBold16}>
                  {t.updatePostalCode}
                </Text>
              </TouchableOpacity>
            </View>
            <Text style={[Fonts.poppinsRegular16, styles.buscaZona]}>
              {t.changeZone}
            </Text>
            <View style={styles.autocompleteContainer}>
              <TextInput
                style={[styles.inputBox, styles.inputBorder]}
                value={postalCode}
                onChangeText={(text) => {
                  setPostalCode(text);
                  validatePostalCode(text);
                }}
                placeholder={t.postalCode}
                keyboardType="numeric"
                maxLength={5}
              />
              {postalCodeError ? (
                <Text style={{ color: "red" }}>{postalCodeError}</Text>
              ) : null}
              {postalCodeOk ? (
                <Text style={{ color: "green" }}>{postalCodeOk}</Text>
              ) : null}
            </View>
          </View>
        </KeyboardAvoidingView>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  centerContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  rectangleParent: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: 517,
    backgroundColor: "transparent",
  },
  frameChild: {
    borderTopLeftRadius: 44,
    borderTopRightRadius: 44,
    shadowColor: Colors.grayDark,
    shadowOffset: { width: 1, height: 1 },
    shadowRadius: 6,
    elevation: 6,
    shadowOpacity: 1,
    backgroundColor: Colors.white,
    height: 517,
  },
  blockIcon: {
    top: 20,
    right: 20,
    width: 30,
    height: 30,
    position: "absolute",
  },
  tuDireccinO: {
    top: 60,
    left: 45,
    color: Colors.black,
    position: "absolute",
  },
  santAndreu: {
    top: 107,
    left: 77,
    color: Colors.black,
    position: "absolute",
  },
  pinIcon: {
    top: 105,
    width: 27,
    height: 27,
    left: 45,
    position: "absolute",
  },
  buttons: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 2,
    paddingHorizontal: 5,
    flexDirection: "row",
    height: 45,
    borderWidth: 1,
    borderColor: Colors.primary,
    borderRadius: 14,
    backgroundColor: Colors.white,
    alignSelf: "center",
    marginVertical: 10,
    width: 194,
    top: 405,
  },
  buscaZona: {
    top: 113,
    alignSelf: "center",
    color: Colors.black,
  },
  autocompleteContainer: {
    alignSelf: "center",
    position: "relative",
    maxWidth: 300,
    width: "70%",
    marginTop: 20,
    marginBottom: 20,
    top: 110,
  },
  inputBox: {
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    padding: 10,
    width: "100%",
    height: 40,
  },
  listItemContainer: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayMedium,
  },
});

export default Ubication;
