import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Colors, Fonts } from '../styles/styles'; // Asegúrate de importarlo desde la ubicación correcta

export const Anadir = () => {
  return (
    <TouchableOpacity style={estilos.boton}>
      <View style={estilos.textoContenedor}>
        <Text style={estilos.texto}>Afegir</Text>
      </View>
    </TouchableOpacity>
  );
};

const estilos = StyleSheet.create({
  boton: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderColor: Colors.primary,
    borderRadius: 20,
    borderWidth: 1,
    flexDirection: 'row',
    height: 35,
    justifyContent: 'center',
    paddingHorizontal: 16,
    width: 194,
  },
  textoContenedor: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  texto: {
    ...Fonts.poppinsSemiBold16,
    color: Colors.primary,
  },
});

export default Anadir;
