import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
  Image,
  Alert,
  Linking,
} from "react-native";
import CardTrocVertical from "../components/CardTrocVertical";
import { Colors, Fonts } from "../styles/styles";
import { AppContext } from "../../AppContext";
import InfoTrocs from "../components/InfoTrocs";
import Loading from "../components/Loading";
import NoDataTrocVertical from "../components/NoDataTrocVertical";
import { useFocusEffect } from "@react-navigation/native";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";

const translations = {
  ESP: {
    ofertas: "Según tus ofertas",
    demandas: "Según tus demandas",
    explanation:
      "Esta pantalla te muestra a los vecinos con los que haces **Troc** (Match) cuando sois **complementarios** con lo que **ofreces** y/o **demandas**.\n\nLos trocs dobles son aquellos donde hay coincidencias en ambos sentidos: ofreces lo que necesitan y demandas lo que ofrecen.\n\nSi no hay coincidencias, sigue publicando ofertas y demandas, y pronto **serás notificado**.",
    close: "Cerrar",
    enableNotifications: "Activar notificaciones",
    pushPermissionTitle: "Falta un paso",
    pushPermissionDenied:
      "Debes habilitar los permisos de notificación del dispositivo, ¡te llevamos allí!",
    pushTokenError: "Error al activar las notificaciones push.",
    webPushMessage: "Para activar notificaciones push debes descargar la App.",
    pushios:
      "Para activar las Notificaciones descarga nuestra aplicación desde la App Store",
    pushandroid:
      "Para activar las Notificaciones descarga nuestra aplicación desde Google Play",
    exito: "Éxito",
    successMessage: "Ya tienes las notificaciones perfectamente configuradas.",
    coincidencias: " Coincidentes",
  },
  CAT: {
    ofertas: "Segons les teves ofertes",
    demandas: "Segons les teves demandes",
    explanation:
     "Aquesta pantalla us mostra els veïns amb qui fas **Troc** (Match) quan sou **complementaris** amb el que **oferiu** i/o **demaneu**.\n\nEls trocs dobles són aquells on hi ha coincidències en ambdós sentits: oferiu el que necessiten i demaneu el que ofereixen.\n\nSi no hi ha coincidències, continua publicant ofertes i demandes i aviat **seràs notificat**.",
    close: "Tancar",
    enableNotifications: "Activar notificacions",
    pushPermissionTitle: "Falta un pas",
    pushPermissionDenied:
      "Has d'habilitar els permisos de notificació del dispositiu, t'hi portem!",
    pushTokenError: "Error al activar les notificacions push.",
    webPushMessage: "Per activar notificacions push has de descarregar l'App.",
    pushios:
      "Per activar les Notificacions descarrega la nostra aplicació des de l'App Store",
    pushandroid:
      "Per activar les Notificacions descarrega la nostra aplicació des de Google Play",
    exito: "Èxit",
    successMessage: "Ja tens les notificacions perfectament configurades.",
    coincidencias: " Coincidents",
  },
  ENG: {
    ofertas: "According to your offers",
    demandas: "According to your demands",
    explanation:
      "This screen shows you the neighbors with whom you make a **Troc** (Match) when you are **complementary** with what you **offer** and/or **demand**.\n\nDouble trocs are those where there are matches in both directions: you offer what they need and demand what they offer.\n\nIf there are no matches, keep posting offers and demands, and soon you will be **notified**.",
    close: "Close",
    enableNotifications: "Enable notifications",
    pushPermissionTitle: "One more step",
    pushPermissionDenied:
      "You need to enable the device's notification permissions, let us take you there!",
    pushTokenError: "Error enabling push notifications.",
    webPushMessage: "To enable push notifications you must download the App.",
    pushios: "To enable Notifications download our app from the App Store",
    pushandroid: "To enable Notifications download our app from Google Play",
    exito: "Success",
    successMessage: "You have successfully configured notifications.",
    coincidencias: " Matching",
  },
};

const TrocScreen = () => {
  const { serverAddress, token, language } = useContext(AppContext);
  const [ofertes, setOfertes] = useState([]);
  const [demandes, setDemandes] = useState([]);
  const [supertrocs, setSupertrocs] = useState([]);
  const [trocs, setTrocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [showEnableNotificationsButton, setShowEnableNotificationsButton] =
    useState(false);

  const showNoDataForOfertas = ofertes.length === 0;
  const showNoDataForDemandes = demandes.length === 0;

  const fetchData = async () => {
    if (!token) {
      setIsLoading(false);
      return;
    }

    try {
      const headers = {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      };

      const resOfertes = await fetch(
        `${serverAddress}/api/v1/perfils/meustrocs/ofertes`,
        headers
      );
      if (!resOfertes.ok) throw new Error("Error en la respuesta de la API");
      const dataOfertes = await resOfertes.json();

      const resDemandes = await fetch(
        `${serverAddress}/api/v1/perfils/meustrocs/demandes`,
        headers
      );
      if (!resDemandes.ok) throw new Error("Error en la respuesta de la API");
      const dataDemandes = await resDemandes.json();

      const resSupertrocs = await fetch(
        `${serverAddress}/api/v1/perfils/meustrocs/supertrocs?offset=0`,
        headers
      );
      if (!resSupertrocs.ok)
        throw new Error("Error en la respuesta de la API para Supertrocs");
      const dataSupertrocs = await resSupertrocs.json();

      if (
        dataSupertrocs.status === "OK" &&
        Array.isArray(dataSupertrocs.data)
      ) {
        setSupertrocs(dataSupertrocs.data);
      } else {
        setSupertrocs([]); // Si no es un array, establece supertrocs como un array vacío
      }

      if (dataOfertes.status === "OK" && Array.isArray(dataOfertes.data)) {
        setOfertes(dataOfertes.data);
      } else {
        setOfertes([]); // Si no es un array, establece ofertes como un array vacío
      }

      if (dataDemandes.status === "OK" && Array.isArray(dataDemandes.data)) {
        setDemandes(dataDemandes.data);
      } else {
        setDemandes([]); // Si no es un array, establece demandes como un array vacío
      }
    } catch (error) {
      //console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkPushNotificationPermissions = async () => {
    if (!Device.isDevice) {
      setShowEnableNotificationsButton(true);
      return;
    }

    const { status } = await Notifications.getPermissionsAsync();
    if (status !== "granted") {
      setShowEnableNotificationsButton(true);
    } else {
      setShowEnableNotificationsButton(false);
    }
  };

  const registerForPushNotificationsAsync = async () => {
    if (!Device.isDevice) {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].webPushMessage
      );
      return;
    }

    if (Platform.OS === "web") {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].webPushMessage
      );
      return;
    }

    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].pushPermissionDenied,
        [{ text: "OK", onPress: () => openSettings() }]
      );
      return;
    }

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (
        await Notifications.getExpoPushTokenAsync({ projectId })
      ).data;
      sendPushTokenToServer(pushToken);
    } catch (error) {
      Alert.alert(
        "Error",
        translations[language].pushTokenError + " " + error.message
      );
    }
  };

  const sendPushTokenToServer = async (pushToken) => {
    if (!token) {
      return;
    }

    const url = `${serverAddress}/api/v1/perfils/actuacions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });

      if (response.ok) {
        Alert.alert(
          translations[language].exito,
          translations[language].successMessage
        );
      } else {
        throw new Error(translations[language].apiError);
      }
    } catch (error) {
      Alert.alert(
        "Error",
        translations[language].apiError + " " + error.message
      );
    }
  };

  const openSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      Linking.openSettings();
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchData();
      checkPushNotificationPermissions();
    }, [])
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ScrollView style={styles.scrollContainer}>
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            <Text style={styles.trocText}>Trocs</Text>
            <Text style={styles.superText}> 
              {translations[language].coincidencias}
            </Text>
          </Text>
          <TouchableOpacity
            style={styles.iconTouchableArea}
            onPress={() => setModalVisible(true)}
          >
            <Image
              source={require("../img/icons/info.png")}
              style={styles.infoIcon}
            />
          </TouchableOpacity>
        </View>

        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
            <View style={styles.modalOverlay}>
              <View style={styles.modalContainer}>
                <View style={styles.modalView}>
                  <Text style={styles.modalText}>
                    {translations[language].explanation
                      .split("**")
                      .map((part, index) =>
                        index % 2 === 1 ? (
                          <Text key={index} style={styles.boldText}>
                            {part}
                          </Text>
                        ) : (
                          part
                        )
                      )}
                  </Text>
                  {showEnableNotificationsButton ? (
                    <View style={styles.buttonContainer}>
                      <TouchableOpacity
                        style={[styles.button, styles.buttonClose]}
                        onPress={() => setModalVisible(!modalVisible)}
                      >
                        <Text style={[styles.textStyle, styles.textClose]}>
                          {translations[language].close}
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[styles.button, styles.buttonEnable]}
                        onPress={registerForPushNotificationsAsync}
                      >
                        <Text style={[styles.textStyle, styles.textEnable]}>
                          {translations[language].enableNotifications}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : (
                    <View style={styles.singleButtonContainer}>
                      <TouchableOpacity
                        style={[styles.singleButton, styles.buttonClose]}
                        onPress={() => setModalVisible(!modalVisible)}
                      >
                        <Text style={[styles.textStyle, styles.textClose]}>
                          {translations[language].close}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  )}
                </View>
              </View>
            </View>
          </TouchableWithoutFeedback>
        </Modal>

        <InfoTrocs supertrocs={supertrocs} trocs={trocs} language={language} />

        <Text style={styles.sectionTitle}>
          {translations[language].ofertas}
        </Text>
        {showNoDataForOfertas ? (
          
            <NoDataTrocVertical />
        
        ) : (
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {Array.isArray(ofertes) &&
              ofertes.map((oferta, index) => (
                <CardTrocVertical
                  key={index}
                  data={oferta}
                  esOferta={true}
                  language={language}
                />
              ))}
          </ScrollView>
        )}

        <Text style={styles.sectionTitle}>
          {translations[language].demandas}
        </Text>
        {showNoDataForDemandes ? (
         
            <NoDataTrocVertical tipo="demanda" />
       
        ) : (
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {Array.isArray(demandes) &&
              demandes.map((demanda, index) => (
                <CardTrocVertical
                  key={index}
                  data={demanda}
                  esOferta={false}
                  language={language}
                />
              ))}
          </ScrollView>
        )}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    alignItems: "stretch",
    paddingHorizontal: 0,
    paddingTop: Platform.OS === "android" ? -6 : 28,
    color: Colors.primary,
  },
  scrollContainer: {
    flex: 1,
    paddingBottom: 100,
    backgroundColor: Colors.white,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 16,
    marginTop: 40,
    marginBottom: 6,
  },
  title: {
    ...Fonts.poppinsSemiBold24,
  },

  trocText: {
    color: Colors.primary,
  },
  superText: {
    color: Colors.grayDark,
  },
  sectionTitle: {
    ...Fonts.poppinsMedium18,
    marginTop: 0,
    marginBottom: 10,
    marginLeft: 16,
    color: Colors.grayDark,
  },
  iconTouchableArea: {
    marginLeft: 9, // 14 - 5 px para acercarlo más a SuperTroc
    padding: 10,
  },
  infoIcon: {
    width: 19.2,
    height: 19.2,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Oscurecer el fondo del modal
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    borderColor: "green", // Borde de color verde
    borderWidth: 3, // Incrementado a 3 px
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
  },
  singleButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    flex: 1,
    marginHorizontal: 5,
    borderWidth: 2, // Añadido borde
    borderColor: "green", // Borde verde
    backgroundColor: "white", // Fondo blanco
  },
  singleButton: {
    minWidth: "60%",
  },
  buttonClose: {
    borderColor: "green",
  },
  buttonEnable: {
    backgroundColor: "green", // Cambio de color a verde
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  textClose: {
    marginVertical: 5, // Ajuste para centrar el texto verticalmente
    color: "green", // Color del texto verde
  },
  textEnable: {
    color: "white", // Color del texto blanco
  },
  modalText: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
  centeredView: {
    alignItems: "center",
  },
});

export default TrocScreen;
