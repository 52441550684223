import React, { useContext } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Alert,
  Share,
  Platform,
} from "react-native";
import { Fonts, Colors } from "../styles/styles.js";
import { AppContext } from "../../AppContext";
import { SHARE_BASE_URL } from "../docs/config.js";
// Importar las imágenes
import shareImage from "../img/icons/share2.png";

const translate = {
  ESP: {
    message: "Comparte tu perfil para pedir que te valoren!",
    shareMessage:
      "Te paso mi perfil en Trocalia, me iría genial que me dejases una valoración.",
  },
  CAT: {
    message: "Comparteix el teu perfil per demanar que et valorin!",
    shareMessage:
      "Et passo el meu perfil a Trocalia, m'aniria genial que em deixessis una valoració.",
  },
  ENG: {
    message: "Share your profile to ask for a rating!",
    shareMessage:
      "I'm sharing my profile on Trocalia, it would be great if you could rate me.",
  },
};

const NotRating = () => {
  const { userData } = useContext(AppContext);
  const userId = userData?.data[0]?.id_usuari || "-";
  const { language } = useContext(AppContext);

  const handleShare = async () => {
    const urlToShare = `${SHARE_BASE_URL}/perfil/${userId}`;
    const shareMessage = `${translate[language].shareMessage} ${urlToShare}`;

    if (Platform.OS === "web") {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Compartir URL del perfil",
            text: shareMessage,
            url: urlToShare,
          });
        } catch (error) {
          Alert.alert("Error", "No se pudo compartir. Inténtalo de nuevo.");
        }
      } else {
        Alert.alert(
          "Error",
          "La funcionalidad de compartir no está disponible en este navegador."
        );
      }
    } else {
      try {
        await Share.share({
          message: shareMessage,
          title: "Compartir URL del perfil",
        });
      } catch (error) {
        Alert.alert("Error", "No se pudo compartir. Inténtalo de nuevo.");
      }
    }
  };

  return (
    <TouchableOpacity onPress={handleShare}>
      <View style={styles.frame}>
        <View style={styles.div}>
          <Text style={styles.textWrapper}>{translate[language].message}</Text>
          <Image source={shareImage} style={styles.add} />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  frame: {
    width: 340,
    height: 67,
    marginBottom: 20,
    marginTop: 10,
  },
  div: {
    backgroundColor: Colors.primary,
    borderRadius: 16,
    height: 67,
    width: 340,
    position: "relative",
    alignItems: "center",
    ...Platform.select({
      ios: {
        shadowColor: Colors.primary,
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.4,
        shadowRadius: 6,
      },
      android: {
        elevation: 6,
        shadowColor: Colors.primary, // Añadido el color primario a la sombra para Android
      },
      default: {
        shadowColor: "rgba(0, 0, 0, 0.25)",
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 6,
        shadowOpacity: 1,
      },
    }),
  },
  textWrapper: {
    color: Colors.white,
    ...Fonts.poppinsMedium16,
    lineHeight: 16 * 1.35,
    position: "absolute",
    top: 9,
    left: 14,
    width: 272,
  },
  add: {
    height: 30,
    width: 30,
    position: "absolute",
    top: 18,
    left: 290,
  },
});

export default NotRating;
