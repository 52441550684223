import React, { useState, useContext } from "react";
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  Platform,
} from "react-native";
import { Colors, Fonts } from "../styles/styles";
import * as Haptics from "expo-haptics";
import { AppContext } from "../../AppContext";

const translate = {
  ESP: {
    proximidad: "Proximidad",
    valoracion: "Valoracion",
    precio: "Precio",
    nuevosUsuarios: "Nuevos Usuarios",
  },
  CAT: {
    proximidad: "Proximitat",
    valoracion: "Valoració",
    precio: "Preu",
    nuevosUsuarios: "Nous Usuaris",
  },
  ENG: {
    proximidad: "Proximity",
    valoracion: "Rating",
    precio: "Price",
    nuevosUsuarios: "New Users",
  },
};

const SelectorRecomendadosProximidad = ({ onSelect }) => {
  const [selectedOption, setSelectedOption] = useState("proximidad");
  const { language } = useContext(AppContext);

  const optionToCodeMap = {
    proximidad: "D",
    valoracion: "V",
    precio: "P",
    nuevosUsuarios: "N",
  };

  const handleOptionSelect = (option) => {
    const orderByCode = optionToCodeMap[option];
    if (onSelect && orderByCode) {
      onSelect(orderByCode);
    }
    setSelectedOption(option);
    if (Platform.OS !== "web") {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  };

  return (
    <View style={styles.outerContainer}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={styles.selector}>
          {Object.keys(translate[language]).map((option, index) => (
            <TouchableOpacity
              key={option}
              style={[
                selectedOption === option
                  ? styles.optionContainerSelected
                  : styles.optionContainer,
                index !== 0 && styles.marginLeft,
              ]}
              onPress={() => handleOptionSelect(option)}
            >
              <Text
                style={
                  selectedOption === option
                    ? styles.optionSelected
                    : styles.optionInactive
                }
              >
                {translate[language][option]}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    alignItems: "center",
    marginBottom: 22,
    marginTop: 14,
  },
  container: {
    marginTop: 20,
  },
  selector: {
    flexDirection: "row",
    marginBottom: 6,
  },
  optionInactive: {
    ...Fonts.poppinsRegular16,
    color: Colors.inactive,
    paddingVertical: 8,
  },
  optionSelected: {
    ...Fonts.poppinsRegular16,
    color: Colors.primary,
    paddingVertical: 8,
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderColor: "transparent",
    paddingBottom: 1,
  },
  optionContainerSelected: {
    borderBottomWidth: 2,
    borderColor: Colors.primary,
    paddingBottom: 0,
  },
  marginLeft: {
    marginLeft: 20,
  },
});

export default SelectorRecomendadosProximidad;
