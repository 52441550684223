import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Modal,
  Image,
  Alert,
  Linking,
} from "react-native";
import { Colors, Fonts } from "../styles/styles.js";
import SelectorMensajesNotificacionesFavoritos from "../selectables/SelectorMensajesNotificacionesFavoritos";
import { AppContext } from "../../AppContext";
import * as Notifications from "expo-notifications";
import * as Device from "expo-device";
import { ExpoPushContext } from "../../ExpoPushContext";

const translations = {
  ESP: {
    title: "Mensajes",
    explanation:
      "En esta pantalla puedes mantener **chat en tiempo real** con otros usuarios de la comunidad, ver las **notificaciones** que vas recibiendo y acceder los **perfiles favoritos** que has ido guardando.",
    close: "Cerrar",
    enableNotifications: "Activar notificaciones",
    pushPermissionTitle: "Falta un paso",
    pushPermissionDenied:
      "Debes habilitar los permisos de notificación del dispositivo, ¡te llevamos allí!",
    pushTokenError: "Error al activar las notificaciones push.",
    webPushMessage: "Para activar notificaciones push debes descargar la App.",
    pushios:
      "Para activar las Notificaciones descarga nuestra aplicación desde la App Store",
    pushandroid:
      "Para activar las Notificaciones descarga nuestra aplicación desde Google Play",
    exito: "Éxito",
    successMessage: "Ya tienes las notificaciones perfectamente configuradas.",
  },
  CAT: {
    title: "Missatges",
    explanation:
      "En aquesta pantalla pots mantenir **xat en temps real** amb altres usuaris de la comunitat, veure les **notificacions** que vas rebent i accedir als **perfils favorits** que has anat guardant.",
    close: "Tancar",
    enableNotifications: "Activar notificacions",
    pushPermissionTitle: "Falta un pas",
    pushPermissionDenied:
      "Has d'habilitar els permisos de notificació del dispositiu, t'hi portem!",
    pushTokenError: "Error al activar les notificacions push.",
    webPushMessage: "Per activar notificacions push has de descarregar l'App.",
    pushios:
      "Per activar les Notificacions descarrega la nostra aplicació des de l'App Store",
    pushandroid:
      "Per activar les Notificacions descarrega la nostra aplicació des de Google Play",
    exito: "Èxit",
    successMessage: "Ja tens les notificacions perfectament configurades.",
  },
  ENG: {
    title: "Messages",
    explanation:
      "On this screen you can maintain **real-time chat** with other community members, see the **notifications** you receive, and access the **favorite profiles** you have saved.",
    close: "Close",
    enableNotifications: "Enable notifications",
    pushPermissionTitle: "One more step",
    pushPermissionDenied:
      "You need to enable the device's notification permissions, let us take you there!",
    pushTokenError: "Error enabling push notifications.",
    webPushMessage: "To enable push notifications you must download the App.",
    pushios: "To enable Notifications download our app from the App Store",
    pushandroid: "To enable Notifications download our app from Google Play",
    exito: "Success",
    successMessage: "You have successfully configured notifications.",
  },
};

const MessagesScreen = () => {
  const { serverAddress, token, language } = useContext(AppContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [showEnableNotificationsButton, setShowEnableNotificationsButton] =
    useState(false);

  const registerForPushNotificationsAsync = async () => {
    if (!Device.isDevice) {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].webPushMessage
      );
      return;
    }

    if (Platform.OS === "web") {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].webPushMessage
      );
      return;
    }

    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;

    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }

    if (finalStatus !== "granted") {
      Alert.alert(
        translations[language].pushPermissionTitle,
        translations[language].pushPermissionDenied,
        [{ text: "OK", onPress: () => openSettings() }]
      );
      return;
    }

    try {
      const projectId = "097c2eab-348a-4f63-bcf5-de3978115ad3";
      const pushToken = (
        await Notifications.getExpoPushTokenAsync({ projectId })
      ).data;
      sendPushTokenToServer(pushToken);
    } catch (error) {
      Alert.alert(
        "Error",
        translations[language].pushTokenError + " " + error.message
      );
    }
  };

  const sendPushTokenToServer = async (pushToken) => {
    if (!token) {
      return;
    }

    const url = `${serverAddress}/api/v1/perfils/actuacions`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          actuacio: "GTP",
          expoPushToken: pushToken,
        }),
      });

      if (response.ok) {
        Alert.alert(
          translations[language].exito,
          translations[language].successMessage
        );
      } else {
        throw new Error(translations[language].apiError);
      }
    } catch (error) {
      Alert.alert(
        "Error",
        translations[language].apiError + " " + error.message
      );
    }
  };

  const openSettings = () => {
    if (Platform.OS === "ios") {
      Linking.openURL("app-settings:");
    } else {
      Linking.openSettings();
    }
  };

  const checkPushNotificationPermissions = async () => {
    if (!Device.isDevice) {
      setShowEnableNotificationsButton(true);
      return;
    }

    const { status } = await Notifications.getPermissionsAsync();
    if (status !== "granted") {
      setShowEnableNotificationsButton(true);
    } else {
      setShowEnableNotificationsButton(false);
    }
  };

  useEffect(() => {
    checkPushNotificationPermissions();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          <Text style={styles.superText}>{translations[language].title}</Text>
        </Text>
        <TouchableOpacity
          style={styles.iconTouchableArea}
          onPress={() => setModalVisible(true)}
        >
          <Image
            source={require("../img/icons/info.png")}
            style={styles.infoIcon}
          />
        </TouchableOpacity>
      </View>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
          <View style={styles.modalOverlay}>
            <View style={styles.modalContainer}>
              <View style={styles.modalView}>
                <Text style={styles.modalText}>
                  {translations[language].explanation
                    .split("**")
                    .map((part, index) =>
                      index % 2 === 1 ? (
                        <Text key={index} style={styles.boldText}>
                          {part}
                        </Text>
                      ) : (
                        part
                      )
                    )}
                </Text>
                {showEnableNotificationsButton ? (
                  <View style={styles.buttonContainer}>
                    <TouchableOpacity
                      style={[styles.button, styles.buttonClose]}
                      onPress={() => setModalVisible(!modalVisible)}
                    >
                      <Text style={[styles.textStyle, styles.textClose]}>
                        {translations[language].close}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={[styles.button, styles.buttonEnable]}
                      onPress={registerForPushNotificationsAsync}
                    >
                      <Text style={[styles.textStyle, styles.textEnable]}>
                        {translations[language].enableNotifications}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View style={styles.singleButtonContainer}>
                    <TouchableOpacity
                      style={[styles.singleButton, styles.buttonClose]}
                      onPress={() => setModalVisible(!modalVisible)}
                    >
                      <Text style={[styles.textStyle, styles.textClose]}>
                        {translations[language].close}
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>

      <SelectorMensajesNotificacionesFavoritos />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  titleContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 16,
    marginTop: Platform.OS === "android" ? 21 : 70,
    marginBottom: 8,
  },
  title: {
    ...Fonts.poppinsSemiBold24,
  },
  superText: {
    color: Colors.black,
  },
  iconTouchableArea: {
    marginLeft: 9, // 14 - 5 px para acercarlo más al título
    padding: 10,
  },
  infoIcon: {
    width: 19.2,
    height: 19.2,
  },
  modalOverlay: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Oscurecer el fondo del modal
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    borderColor: "green", // Borde de color verde
    borderWidth: 3, // Incrementado a 3 px
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 20,
  },
  singleButtonContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 20,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
    flex: 1,
    marginHorizontal: 5,
    borderWidth: 2, // Añadido borde
    borderColor: "green", // Borde verde
    backgroundColor: "white", // Fondo blanco
  },
  singleButton: {
    minWidth: "60%",
  },
  buttonClose: {
    borderColor: "green",
  },
  buttonEnable: {
    backgroundColor: "green", // Cambio de color a verde
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  textClose: {
    marginVertical: 5, // Ajuste para centrar el texto verticalmente
    color: "green", // Color del texto verde
  },
  textEnable: {
    color: "white", // Color del texto blanco
  },
  modalText: {
    fontSize: 18,
    marginBottom: 15,
    textAlign: "center",
  },
  boldText: {
    fontWeight: "bold",
  },
});

export default MessagesScreen;
